import Contact from "./Contact";
import Sponsor from "./Sponsor";
import "./style.css";

export default function RightHome({ user, postss, loading, loadingg }) {
  return (
    <div className="right_home">
      <div className="heading">Paginas Sugeridas</div>
      <div className="sponsor_list">
        <Sponsor loading={loading} loadingg={loadingg} />
      </div>
      <div className="splitter1"></div>
      <div className="contacts_wrap">
        <div className="contacts_header">
          <div className="contacts_header_left">Agregar y seguir</div>
          <div className="contacts_header_right">
            {/* <div className="contact_circle hover1">
              <TextsmsIcon color="action" />
            </div>
            <div>
              <Search color={color} />
            </div>
            <div>
              <Dots color={color} />
            </div> */}
          </div>
        </div>
        <div className="contacts_list">
          <Contact user={user} postss />
        </div>
      </div>
    </div>
  );
}
