import { Link, useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import FeedbackIcon from "@mui/icons-material/Feedback";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LogoutIcon from "@mui/icons-material/Logout";
import SupportIcon from "@mui/icons-material/Support";
import { useState } from "react";
import SettingsPrivacy from "./SettingsPrivacy";
import HelpSupport from "./HelpSupport";
import DisplayAccesability from "./DisplayAccesability";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import miproducts from "../../../styles/marketplace_icons/empresy-my-products (1).svg";
import LanguageIcon from "@mui/icons-material/Language";

export default function UserMenu({
  user,
  setShowUserMenu,
  handleMyProducts,
  handleChange,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(0);
  const { t } = useTranslation();

  const logout = () => {
    Cookies.set("user", "");
    dispatch({
      type: "LOGOUT",
    });
    navigate("/login");
  };

  return (
    <div className="mmenu">
      {visible === 0 && (
        <div>
          <Link
            to={`/profile/${user.username}`}
            className="mmenu_header hover3"
            onClick={() => setShowUserMenu(false)}
          >
            <img src={user?.picture} alt="profile" />
            <div className="mmenu_col">
              <span>{user?.company_Name}</span>
              <span>{t("go-to-profile")}</span>
            </div>
          </Link>
          {/* <div className="mmenu_splitter"></div>
          <Link
            to="https://docs.google.com/forms/d/e/1FAIpQLSdUA1DywT77fc3Ono6AWWCTfkG3CoV9e7rg9FcNijl0ilo32A/viewform?usp=sf_link"
            className="hover1"
            style={{ margin: "0 0 0 0" }}
            target="_blank"
          >
            <div className="mmenu_main hover3">
              <div className="small_circle">
                <FeedbackIcon />
              </div>

              <div className="mmenu_col">
                <div className="mmenu_span1">Danos tu opinion</div>
                <div className="mmenu_span2">Ayudanos a mejorar empresy</div>
              </div>
            </div>
          </Link> */}
          <div className="mmenu_splitter"></div>
          {/* <div
            className="mmenu_item hover3"
            onClick={() => {
              setVisible(1);
            }}
          >
            <div className="small_circle">
              <SettingsIcon />
            </div>
            <span>Configuracion y Privacidad</span>
            <div className="rArrow">
              <ChevronRightIcon fontSize="large" />
            </div>
          </div> */}
          <div
            className="mmenu_item hover3"
            onClick={() => {
              {
                handleMyProducts("myproducts");
                setShowUserMenu(false);
              }
            }}
          >
            <div className="small_circle">
              <img src={miproducts} alt="" />
            </div>
            <span>{t("My-Products")}</span>
            <div className="rArrow">
              <ChevronRightIcon fontSize="large" />
            </div>
          </div>
          <div
            className="mmenu_item hover3"
            onClick={() => {
              setVisible(1);
            }}
          >
            <div className="small_circle">
              <LanguageIcon />
            </div>
            <span>{t("select-language")}</span>
            <div className="rArrow">
              <ChevronRightIcon fontSize="large" />
            </div>
          </div>
          <div
            className="mmenu_item hover3"
            onClick={() => {
              setVisible(2);
            }}
          >
            <div className="small_circle">
              <SupportIcon />
            </div>
            <span>{t("support")}</span>
            <div className="rArrow">
              <ChevronRightIcon fontSize="large" />
            </div>
          </div>
          {/* <div
            className="mmenu_item hover3"
            onClick={() => {
              setVisible(3);
            }}
          >
            <div className="small_circle">
              <DarkModeIcon />
            </div>
            <span>Pantalla</span>
            <div className="rArrow">
              <ChevronRightIcon fontSize="large" />
            </div>
          </div> */}
          <div
            className="mmenu_item hover3"
            onClick={() => {
              logout();
            }}
          >
            <div className="small_circle">
              <LogoutIcon />
            </div>
            <span>{t("logout")}</span>
          </div>
        </div>
      )}
      {visible === 1 && (
        <SettingsPrivacy
          setVisible={setVisible}
          t={t}
          handleChange={handleChange}
        />
      )}
      {visible === 2 && <HelpSupport setVisible={setVisible} />}
      {visible === 3 && <DisplayAccesability setVisible={setVisible} />}
    </div>
  );
}
