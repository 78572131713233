import SEO from "../../components/SEO/SEO";
import AndroidPopUp from "../../components/androidPopUp";
import Footer from "../../components/login/Fotter";
import LandingPage from "../../components/login/LandingPage";
import Politics from "../../components/login/Politics";
import RegisterForm from "../../components/login/RegisterForm";
import Loginform from "../../components/login/loginform";
import Bottom from "./Bottom";
import Middle from "./middle";
import "./style.css";
import { useEffect, useState } from "react";
import { isAndroid as isAndroidDevice } from "react-device-detect";
import { useTranslation } from "react-i18next";

export default function Login() {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);

  useEffect(() => {
    // Check if the user is using an Android device
    const checkIfAndroid = () => {
      const isAndroidDeviceValue = isAndroidDevice; // Use the renamed import
      setIsAndroid(isAndroidDeviceValue);
    };

    checkIfAndroid();

    // Clean up event listeners on component unmount
  }, []);

  const headerTags = {
    title: t("welcome-home-page"),
    keywords:
      "marketplace, b2b, bc2, proveedor, provider, products,products, servicios, services, local, empresa, bussines",
    titleTemplate: "%s",
    description: t("Connect-with-Customers-Suppliers-Products-Services-V2"),
    type: "application",
    image:
      "https://www.google.com/url?sa=i&url=https%3A%2F%2Fco.linkedin.com%2Fcompany%2Fempresy&psig=AOvVaw17mHLWDMA8aCBDlIumQrre&ust=1720299117684000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCLCKgeDjkIcDFQAAAAAdAAAAABAE",
    url: `https://www.empresy.com/login`,
  };

  return (
    <div className="login">
      {/* <SEO headerTags={headerTags} /> */}
      <div className="login_wrapper">
        {/* <Loginform setVisible={setVisible} /> */}
        <LandingPage setVisible={setVisible} setPolicy={setPolicy} />
        {visible && (
          <RegisterForm setVisible={setVisible} setPolicy={setPolicy} />
        )}
        {policy && <Politics setPolicy={setPolicy} />}
        {/* {isAndroid ? <AndroidPopUp setIsAndroid={setIsAndroid} /> : null} */}
      </div>
    </div>
  );
}
