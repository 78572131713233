import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SettingsIcon from "@mui/icons-material/Settings";
import LanguageIcon from "@mui/icons-material/Language";

export default function SettingsPrivacy({ setVisible, t, handleChange }) {
  return (
    <div className="absolute_wrap">
      <div className="absolute_wrap_header">
        <div
          className="circle hover1"
          onClick={() => {
            setVisible(0);
          }}
        >
          <ChevronLeftIcon fontSize="large" />
        </div>
        {t("select-language")}
      </div>
      <div
        className="mmenu_item hover3"
        onClick={() => {
          handleChange("es");
          setVisible(0);
        }}
      >
        <div className="small_circle ">ES</div>
        <span>Español</span>
      </div>

      <div
        className="mmenu_item hover3"
        onClick={() => {
          handleChange("en");
          setVisible(0);
        }}
      >
        <div className="small_circle">EN</div>
        <span>English</span>
      </div>
    </div>
  );
}
