import React from "react";
import Header from "../../components/header";
import "./style.css";

export default function Premium({ user }) {
  return (
    <>
      <Header user={user} />
      <div className="empresy">
        <section className="premium-section">
          <div className="row">
            <div className="col_1" style={{}}>
              <h1>Empresy Premium</h1>
              <button className="btn-primary">
                ¡Tu empresa más visible que otras!
              </button>
              <p>
                Empresy Premium es un servicio de suscripción premium ofrecido
                por Empresy que te brinda una visibilidad excepcional y
                beneficios exclusivos para tu empresa en la plataforma de
                Empresy. Aquí te presentamos los beneficios.
              </p>
              <h2>Beneficios Premium para tu Empresa:</h2>
              <ul>
                <li>
                  <strong>
                    Prioridad en las clasificaciones en búsquedas:
                  </strong>{" "}
                  Las publicaciones de tu empresa recibirán un impulso en su
                  clasificación, aparecen primero que las publicaciones de
                  usuarios que no sean premium. Cuando alguien busque
                  información relacionada con tu empresa, tus publicaciones
                  serán primeras en mostrar resultados que las publicaciones de
                  empresas que no son premium.
                </li>
                <li>
                  <strong>Publicaciones con más fotos y videos:</strong> Los
                  usuarios premium tienen la ventaja de poder publicar videos y
                  hasta 5 imágenes en sus posts. Los no premium solo pueden
                  publicar una imagen por publicación. ¡Deja que tus productos y
                  servicios hablen por sí mismos!
                </li>
                <li>
                  <strong>Sello de verificación:</strong> Tu foto de perfil en
                  todas las publicaciones estará acompañada por el distintivo
                  sello de verificación. ¡Muestra confianza y autenticidad en
                  cada post! Para más información sobre el sello de verificación
                  da <a href="URL_del_enlace">click aquí</a>.
                </li>
                <li>
                  <strong>Agregar Catálogo:</strong> Agrega tu catálogo de
                  productos y servicios directamente en tu perfil empresarial.
                  Esto permite que las empresas y personas que visiten tu perfil
                  puedan explorar fácilmente todos tus productos y servicios en
                  un solo lugar.
                </li>
                <li>
                  <strong>Atención Premium:</strong> Como suscriptor Premium,
                  tendrás acceso a un canal de atención especializado para
                  resolver todas tus dudas y consultas relacionadas con el
                  servicio Premium.
                </li>
              </ul>
            </div>
          </div>
        </section>

        <div className="spacer"></div>

        <div className="plans">
          <div className="col-lg-6">
            <div className="text-card style-1 text-center">
              <div className="overlay"></div>
              <h3>Basic</h3>
              <h4>Empresy Premium</h4>
              <p>Perfecto para empezar.</p>
              <h3>$29.900/mes</h3>
              <p>
                Todas las características de Empresy Premium Facturado
                mensualmente
              </p>
              <button className="btn-primary">Comprar Ahora</button>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="text-card style-2 text-center">
              <div className="overlay"></div>
              <h3>Business</h3>
              <h4>Empresy Premium</h4>
              <p className="highlight">AHORRA 50%</p>
              <h3>$19.900/mes</h3>
              <p>
                Todas las características de Empresy Premium Facturado
                anualmente
              </p>
              <button className="btn-primary">Comprar Ahora</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
