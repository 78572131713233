import axios from "axios";

export const getAllProductsVisitor = async (items, page, visitorLocation) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/getAllProductsVisitor/${items}/${page}`,
      { visitorLocation },

      {
        headers: {},
      }
    );
    return { status: "ok", data };
  } catch (error) {
    return error.response.data.message;
  }
};

// fetch all the products of a sector
export const getSectorVisitor = async (sector, items, page) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/getSectorVisitor/${sector}/${items}/${page}`,

      {
        headers: {},
      }
    );
    return { status: "ok", data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const getProductByIdVisitor = async (id) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/getProductByIdVisitor/${id}`,

      {
        headers: {},
      }
    );
    return { status: "ok", data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const getProductsbyTypeVisitor = async (type) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/getProductsbyTypeVisitor/${type}`,

      {
        headers: {},
      }
    );
    return { status: "ok", data };
  } catch (error) {
    return error.response.data.message;
  }
};
export const getCompanyVisitor = async (economic, items, page) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/getCompanyVisitor/${economic}/${items}/${page}`,

      {
        headers: {},
      }
    );
    return { status: "ok", data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const getSectorProductsVisitor = async (
  economic,
  category,
  items,
  page
) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/getSectorProductsVisitor/${economic}/${category}/${items}/${page}`,

      {
        headers: {},
      }
    );
    return { status: "ok", data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const getCategoryProductsVisitor = async (category, items, page) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/getCategoryProductsVisitor/${category}/${items}/${page}`,

      {
        headers: {},
      }
    );
    return { status: "ok", data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const getFranchiseProductsVisitor = async (category, items, page) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/getCategoryProductsVisitor/${category}/${items}/${page}`,

      {
        headers: {},
      }
    );
    return { status: "ok", data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const getSectorOfferVisitor = async (
  economic,
  category,
  items,
  page
) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/getSectorOfferVisitor/${economic}/${category}/${items}/${page}`,

      {
        headers: {},
      }
    );
    return { status: "ok", data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const getCompanyProductsVisitor = async (id, items, page) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/getCompanyProductsVisitor/${id}/${items}/${page}`,

      {
        headers: {},
      }
    );
    return { status: "ok", data };
  } catch (error) {
    return error.response.data.message;
  }
};
