import { useRef, useState } from "react";
import useClickOutside from "../../../helpers/clickOutside";
import Description from "./Description";
import "./style.css";
import CurrentCity from "./CurrentCity";
import CloseIcon from "@mui/icons-material/Close";
import AutoComplete from "../../../components/google_autocomplete/AutoComplete";

export default function EditProfile({
  setVisible,
  infos,
  updateAbout,
  details,
  setInfos,
  userLocation,
  setUserLocation,
}) {
  const [max, setMax] = useState(
    details?.bio ? 200 - details?.bio?.length : 200
  );
  const modal = useRef(null);
  useClickOutside(modal, () => setVisible(false));

  const handleChange = (selectedOption) => {
    const [name] = Object.keys(selectedOption); // Extracts the first (and in this case, the only) key
    const value = selectedOption[name]; // Retrieves the corresponding value
    // Process accordingly, for instance, joining city labels into a string
    setInfos({ ...infos, [name]: value });
    if (name === "bio") {
      setMax(200 - value.length);
    }
  };

  const handleChangeUserLocation = (selectedOption) => {
    const [name] = Object.keys(selectedOption); // Extracts the first (and in this case, the only) key
    const value = selectedOption[name]; // Retrieves the corresponding value
    // Process accordingly, for instance, joining city labels into a string
    setUserLocation({
      city: selectedOption?.city,
      region: selectedOption?.region,
      country: selectedOption?.country,
    });
  };

  return (
    <div className="blurr">
      <div className="postBox InfosBox" ref={modal}>
        <div className="box_header">
          <div
            className="small_circle"
            onClick={() => setVisible((prev) => !prev)}
          >
            <CloseIcon />
          </div>
          <span>Editar Perfil</span>
        </div>
        <div className="details_wrapper scrollbar">
          <div className="details_col"></div>
          <div className="details_header">Descripción</div>
          <Description
            name="bio"
            infos={infos}
            placeholder="Agrega una descrpción"
            max={max}
            handleChange={handleChange}
            updateAbout={updateAbout}
          />
          <div className="details_header">
            Ubicación (Pais, Departamento, Ciudad)
          </div>

          <AutoComplete
            placeholder="Ingresa tu ubicación"
            userLocation={userLocation}
            handleChangeUserLocation={handleChangeUserLocation}
            name="location"
          />

          <div className="details_header">Pagina Web</div>
          <Description
            name="webpage"
            infos={infos}
            placeholder="ejemplo www.empresy.com"
            max={max}
            handleChange={handleChange}
            updateAbout={updateAbout}
            value={details?.webpage}
            detail
          />
          <button
            className="grey_btn"
            style={{
              width: "fit-content",
              margin: "0 auto",
              marginTop: "10px",
              marginBottom: "10px",
            }}
            onClick={() => {
              updateAbout();
              setVisible(false);
            }}
          >
            Guardar Todo
          </button>
        </div>
      </div>
    </div>
  );
}
