import React, { useRef, useState } from "react";
import useClickOutside from "../../helpers/clickOutside";
import dataURIToBlob from "../../helpers/DataUrlToBlob";
import { uploadImages } from "../../functions/uploadImages";
import { createPost } from "../../functions/post";
import PostError from "../../components/createPostPopup/PostError";
import CloseIcon from "@mui/icons-material/Close";
import ImagePrevew from "../../components/createPostPopup/imagePrevew";
import PulseLoader from "react-spinners/PulseLoader";
import { createRequirement } from "../../functions/requirement";

export default function RequirePost({
  setVisibleRequire,
  user,
  data,
  dispatch,
}) {
  const [text, setText] = useState("");
  const [showPrev, setShowPrev] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [images, setImages] = useState([]);
  const popup = useRef(null);
  useClickOutside(popup, () => {
    setVisibleRequire(false);
  });

  const postSubmit = async () => {
    const trimmedText = text.trim();
    if (!trimmedText && (!images || images.length === 0)) {
      // Both text and images are empty or contain only whitespace
      console.error("Cannot create an empty post");
      return;
    }
    if (images && images.length) {
      setLoading(true);
      const postImages = images.map((img) => {
        return dataURIToBlob(img);
      });

      const path = `${user.username}/require`;
      let formData = new FormData();
      formData.append("path", path);
      postImages.forEach((image) => {
        formData.append("file", image);
      });

      const response = await uploadImages(formData, path, user.token);

      const res = await createRequirement(
        null,
        text,
        response,
        user.id,
        user.token
      );
      setLoading(false);

      if (res.status === "ok") {
        dispatch({
          type: "REQUIREMENT_SUCCESS",
          payload: [res.data, ...data],
        });
        setText("");
        setImages("");
        setVisibleRequire(false);
      } else {
        setError(res);
      }
    } else if (text) {
      setLoading(true);
      const res = await createRequirement(
        null,
        text,
        null,
        user.id,
        user.token
      );
      setLoading(false);
      if (res.status === "ok") {
        dispatch({
          type: "REQUIREMENT_SUCCESS",
          payload: [res.data, ...data],
        });
        setText("");
        setVisibleRequire(false);
      } else {
        setError(res);
      }
    } else {
    }
  };
  return (
    <div className="blurr">
      <div className="postBox">
        {error && <PostError error={error} setError={setError} />}
        <div className="box_header">
          <div
            className="small_circle"
            onClick={() => {
              setVisibleRequire(false);
            }}
          >
            <span></span>
            <i>
              <CloseIcon />
            </i>
          </div>
          <span>Estoy Requeriendo</span>
        </div>
        <div className="box_profile">
          <img src={user?.picture} alt="" />
          <div className="box_col">
            <div className="box_profile_name">{user?.company_Name}</div>
            {/* <div className="box_privacy">
          <PublicIcon sx={{ fontSize: 15 }} />
          <span>Public</span>
          <i className="arrowDown_icon"></i>
        </div> */}
          </div>
        </div>
        {/* {!showPrev ? (
      <>
        <EmojiBackground text={text} setText={setText} user={user} />
      </>
    ) : ( */}
        <ImagePrevew
          text={text}
          setText={setText}
          user={user}
          images={images}
          setImages={setImages}
          setShowPrev={setShowPrev}
          setError={setError}
        />
        {/* )} */}

        <button
          className="post_submit"
          onClick={() => {
            postSubmit();
          }}
          disabled={loading}
        >
          {loading ? <PulseLoader color="white" size={5} /> : "Publicar"}
        </button>
      </div>
    </div>
  );
}
