import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

export default function NotificationReact({
  picture,
  name,
  username,
  created,
}) {
  return (
    <>
      <Link to={`/profile/${username}`} className="notification hover3">
        <img src={picture} alt="empresy-notification" />
        <div className="notification_flex">
          <div className="notification_desc">
            <span>
              A <strong>{name}</strong> le gustó tu publicación
            </span>
          </div>

          <div className="notification_date">
            <Moment fromNow interval={30}>
              {created}
            </Moment>
          </div>
        </div>
      </Link>
    </>
  );
}
