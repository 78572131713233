import { ArrowRight, Plus } from "../../../svg";
import "./styles.css";
import { stories } from "../../../data/home";
import Story from "./Story";
import { useMediaQuery } from "react-responsive";

export default function Stories({ user, posts }) {
  const query1600px = useMediaQuery({
    query: "(max-width:1600px)",
  });
  const query1030px = useMediaQuery({
    query: "(max-width:1030px)",
  });
  const query960px = useMediaQuery({
    query: "(max-width:960px)",
  });
  const query885px = useMediaQuery({
    query: "(max-width:885px)",
  });
  const query630px = useMediaQuery({
    query: "(max-width:630px)",
  });
  const query620px = useMediaQuery({
    query: "(max-width:620px)",
  });
  const query1601px = useMediaQuery({
    query: "(min-width:1600px)",
  });

  const max = query620px
    ? 3
    : query630px
    ? 3
    : query885px
    ? 3
    : query960px
    ? 3
    : query1030px
    ? 3
    : query1600px
    ? 3
    : query1601px
    ? 3
    : stories.length;

  return (
    <div className="stories">
      {/* <div className="create_story_card">
        <img src={user?.picture} className="create_story_img" alt="" />
        <div className="plus_story">
          <Plus color="#fff" />
        </div>
        <div className="story_create_text">Create Story</div>
      </div> */}

      {posts &&
        posts
          ?.slice(0, max)
          .map((post) => <Story post={post} key={post._id} user={user} />)}
      {/* <div className="white_circle">
        <ArrowRight color="#65676b" />
      </div> */}
    </div>
  );
}
