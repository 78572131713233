export const left = [
  {
    text: "Conecta",
    img: "connect",
    description: "Busca proveedores y conecta con otras empresas.",
    to: "/connect",
  },
  {
    text: "¡Empresas Buscan!",
    img: "buscan-empresas-empresy",
    description: "Lista de necesidades que las empresas estan buscando cubrir.",
    to: "/searching",
  },
  // {
  //   text: "Marketplace",
  //   img: "empresy-marketplace",
  //   description: "Encuentra todo para potenciar tu empresa.",
  //   to: "/marketplace",
  // },
  {
    text: "Guardados",
    img: "save",
    description: "Encuentra Post que hayas guardado.",
    to: "/savedposts",
  },
  {
    text: "Premium",
    img: "premium",
    description: "Nuestro servicio premium",
    to: "/premium",
  },
  // {
  //   text: "Groups",
  //   img: "groups",
  //   notification: "5 new",
  // },
  // {
  //   text: "Marketplace",
  //   img: "shop",
  // },
  // {
  //   text: "Watch",
  //   img: "watch",
  //   notification: "9 new videos",
  // },

  // {
  //   text: "Pages",
  //   img: "pages",
  // },
  // {
  //   text: "Events",
  //   img: "events",
  // },
  // {
  //   text: "Anuncia",
  //   img: "ads",
  //   description:
  //     "Crea campañas publicitarias para tu empresa dentro de empresy.",
  //   to: "/empresyads",
  // },
  // {
  //   text: "Ads Manager",
  //   img: "chart",
  //   description: "Manage anounses that you create with optimized functions",
  // },
  // {
  //   text: "Campus",
  //   img: "campus",
  // },
  // {
  //   text: "Climate science center",
  //   img: "climate",
  // },
  // {
  //   text: "Community Help",
  //   img: "community",
  // },
  // {
  //   text: "COVID-19 Information Centrer",
  //   img: "covid",
  // },
  // {
  //   text: "Emotional health",
  //   img: "emotional",
  // },
  // {
  //   text: " Pay",
  //   img: "pay",
  // },
  // {
  //   text: "Favorites",
  //   img: "fav",
  // },
  // {
  //   text: "Fundraisers",
  //   img: "fund",
  // },
  // {
  //   text: "Gaming Video",
  //   img: "gaming",
  // },
  // {
  //   text: "Jobs",
  //   img: "jobs",
  // },
  // {
  //   text: "Live videos",
  //   img: "live",
  // },
  {
    text: "Mensajes",
    img: "messages",
    description: "Chatea instantaneamente con otras empresas.",
    to: "/messages",
  },
  // {
  //   text: "Messenger Kids",
  //   img: "messkids",
  // },
  // {
  //   text: "Most recent",
  //   img: "recent",
  // },
  // {
  //   text: "Play Games",
  //   img: "play",
  // },
  // {
  //   text: "Recent ad activity",
  //   img: "recentad",
  // },
  // {
  //   text: "Weather",
  //   img: "weather",
  // },
];
export const stories = [
  {
    profile_picture: "../../stories/cocacola-logo.png",
    profile_name: "Coca Cola",
    image: "../../stories/cocacola-products.png",
  },
  {
    profile_picture: "../../stories/LogoBavaria.png",
    profile_name: "Bavaria",
    image: "../../stories/bavaria-products.jpeg",
  },
  {
    profile_picture: "../../stories/bancolombia-logo.png",
    profile_name: "Bancolombia",
    image: "../../stories/bancolombia-empresa.jfif",
  },
  {
    profile_picture: "../../stories/alpina logo.png",
    profile_name: "Alpina",
    image: "../../stories/alpina-products.png",
  },
  {
    profile_picture: "../../stories/claro-logo-empresas.jpg",
    profile_name: "Claro Empresas",
    image: "../../stories/claro-productos.jpg",
  },
  {
    profile_picture: "../../stories/claro-logo-empresas.jpg",
    profile_name: "Claro Empresas",
    image: "../../stories/claro-productos.jpg",
  },
];
