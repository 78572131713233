import { useDispatch, useSelector } from "react-redux";
import { getOpenConversation } from "../../../functions/conversations";
import SocketContext from "../../../context/SocketContext";
import { useEffect } from "react";

function Contact({ contact, setSearchResults, setShowMessages, socket }) {
  const { user } = useSelector((state) => ({ ...state }));

  const receiver_id = contact._id;
  const dispatch = useDispatch();

  const openConversation = async () => {
    dispatch({ type: "CONVERSATIONS_LOADING" });
    const data = await getOpenConversation(receiver_id, user.token);
    if (data) {
      dispatch({ type: "GETACTIVECONVERSATION", payload: data });
      setSearchResults([]);
      socket.emit("join conversation", data._id);
    } else {
      dispatch({ type: "CONVERSATIONS_REJECTED", payload: data.data });
    }
  };

  return (
    <li
      onClick={() => {
        openConversation();
        setShowMessages(true);
      }}
      className="list-none h-[72px] hover:dark:bg-dark_bg_2 cursos-pointer dark:text-dark_text_1 px-[10px]"
    >
      {/*container*/}
      <div className="flex items-center gap-x-3 py-[10px]">
        {/*contact info*/}
        <div className="left_conversation">
          {/*user picture*/}
          <div className="picture_container">
            <img src={contact?.picture} alt="profile-empresy" />
          </div>
          {/*conversation  name & message*/}
          <div className="convo_container">
            {/*conversation name*/}
            <div className="conversation_name">
              {!contact?.name && contact.company_Name
                ? contact.company_Name
                : contact.name}
            </div>
            {/*message*/}
            <div>
              <div className="status_container">
                <div className="status">
                  <p>{contact?.Economic_Sector}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ml-16 border-b dark:border-b-dark_border_1"></div>
    </li>
  );
}

const ContactWithContext = (props) => (
  <SocketContext.Consumer>
    {(socket) => <Contact {...props} socket={socket} />}
  </SocketContext.Consumer>
);
export default ContactWithContext;
