import { useState } from "react";
import MenuItem from "./MenuItem";
import { deletePost, savePost } from "../../functions/post";
import { saveAs } from "file-saver";

export default function PostMenu({
  postUserId,
  userId,
  imagesLength,
  token,
  postId,
  setCheckSaved,
  checkSaved,
  images,
  postR,
}) {
  const [test, setTest] = useState(postUserId === userId ? true : false); // means my post if not is not
  const saveHandler = async () => {
    savePost(postId, token);
    if (checkSaved) {
      setCheckSaved(false);
    } else {
      setCheckSaved(true);
    }
  };
  const downloadImages = async () => {
    images.map((img) => {
      saveAs(img.url, "image.jpg");
    });
  };
  const deleteHandler = async () => {
    const res = await deletePost(postId, token);
    if (res.status === "ok") {
      postR.current.style.display = "none";
    } else {
      return;
    }
  };

  return (
    <ul className="post_menu">
      {test && <MenuItem icon="pin_icon" title="Pin Post" subtitle />}
      <div onClick={() => saveHandler()}>
        {checkSaved ? (
          <MenuItem
            icon="save_icon"
            title="Remover Post"
            subtitle="Remover de tus elementos guardados"
          />
        ) : (
          <MenuItem icon="save_icon" title="Guardar Post" />
        )}
      </div>

      {imagesLength && (
        <div onClick={() => downloadImages()}>
          <MenuItem icon="download_icon" title="Descargar imagen" subtitle />
        </div>
      )}
      {/* {imagesLength && (
        <MenuItem icon="fullscreen_icon" title="Pantalla completa" subtitle />
      )} */}
      {test && <MenuItem icon="edit_icon" title="Editar" subtitle />}
      {test && (
        <div onClick={() => deleteHandler()}>
          <MenuItem icon="trash_icon" title="Eliminar Post" subtitle />
        </div>
      )}
      {!test && (
        <MenuItem
          img="../../../icons/report.png"
          title="Reportar post"
          subtitle
        />
      )}
    </ul>
  );
}
