import React, { useEffect, useRef, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import FacebookIcon from "@mui/icons-material/Facebook";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function Share({ setShare, user }) {
  const [copied, setCopied] = useState(false);
  const location = useLocation();
  const url = `https://www.empresy.com/profile/${user?.username}`;
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const copyToClipboard = () => {
    if (inputRef.current) {
      navigator.clipboard.writeText(inputRef.current.value);
      setCopied(true);
    }
  };

  useEffect(() => {
    // Load Facebook SDK asynchronously
    const loadFacebookSDK = () => {
      const script = document.createElement("script");
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.async = true;
      script.defer = true;
      script.onload = initFacebookSDK;
      document.body.appendChild(script);
    };

    const initFacebookSDK = () => {
      window.FB.init({
        appId: "your-facebook-app-id",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v12.0",
      });
    };

    loadFacebookSDK();

    return () => {
      // Cleanup function
    };
  }, []);

  const handleFacebookShare = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`
    );
  };

  const handleTwitterShare = () => {
    window.open(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`
    );
  };

  const handleTelegramShare = () => {
    window.open(`https://t.me/share/url?url=${encodeURIComponent(url)}`);
  };

  const handleWhatsAppShare = () => {
    window.open(`whatsapp://send?text=${encodeURIComponent(url)}`);
  };

  const sendByEmail = () => {
    const subject = "Check this out!";
    const body = `Hey there!
    I found this awesome e-commerce on Empresy. You should definitely check it out!

${url}`;
    const mailToLink = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailToLink;
  };

  return (
    <div className="blur_share">
      {/* <Helmet>
        <meta property="og:title" content={`${user.username}'s Profile`} />
        <meta
          property="og:description"
          content={`Check out ${user.username}'s e-commerce on Empresy.`}
        />
        <meta
          property="og:image"
          content="https://www.example.com/path/to/image.jpg"
        />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="profile" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${user.username}'s Profile`} />
        <meta
          name="twitter:description"
          content={`Check out ${user.username}'s e-commerce on Empresy.`}
        />
        <meta
          name="twitter:image"
          content="https://www.example.com/path/to/image.jpg"
        />
        <meta name="twitter:url" content={url} />
        <title>{`${user.company_Name}'s Profile`}</title>
      </Helmet> */}

      <div className="postBox_share">
        <div className="shares_header">
          <span>{t("share-modal")}:</span>
          <div
            onClick={() => setShare((prev) => !prev)}
            className="cancel_icon"
          >
            <CancelIcon />
          </div>
        </div>
        <div className="social_icons">
          <div className="icon" onClick={handleFacebookShare}>
            <FacebookIcon />
          </div>
          <div className="icon" onClick={handleTwitterShare}>
            <TwitterIcon />
          </div>
          <div className="icon" onClick={handleTelegramShare}>
            <TelegramIcon />
          </div>
          <div className="icon" onClick={handleWhatsAppShare}>
            <WhatsAppIcon />
          </div>

          <div className="icon" onClick={sendByEmail}>
            <MailOutlineIcon />
          </div>
        </div>
        <div className="copy_link">
          <span>{t("copy-link")}:</span>
          <div className="copy_input">
            <InsertLinkIcon />
            <input
              ref={inputRef}
              type="text"
              value={url}
              readOnly
              className="input_share"
            />
            <div
              className={copied ? "button_copied" : "button_copy"}
              onClick={copyToClipboard}
            >
              {copied ? "Copied" : "Copy"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
