import LeftLink from "./LeftLink";
import "./style.css";
import { left } from "../../../data/home";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import Shortcut from "./Shortcut";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function LeftHome({
  user,
  postss,
  loading,
  loadingg,
  setVisitorModal,
}) {
  const [visible, setVisitble] = useState(false);
  return (
    <div
      className="left_home scrollbar"
      style={{ marginTop: `${postss && "90px"}` }}
    >
      {loading || loadingg ? (
        <>
          <div className="left_link">
            <Skeleton
              height="35px"
              width="35px"
              borderRadius="50px"
              containerClassName="avatar-skeleton"
              style={{
                transform: "translateY(1.5px)",
                transform: "translatex(1.5px)",
              }}
            />
            <Skeleton
              height="20px"
              width="100px"
              borderRadius="10px"
              containerClassName="avatar-skeleton"
              style={{
                transform: "translateY(1.5px)",
                transform: "translatex(1.5px)",
              }}
            />
          </div>
          <div className="left_link">
            <Skeleton
              height="35px"
              width="35px"
              borderRadius="50px"
              containerClassName="avatar-skeleton"
              style={{
                transform: "translateY(1.5px)",
                transform: "translatex(1.5px)",
              }}
            />
            <Skeleton
              height="20px"
              width="100px"
              borderRadius="10px"
              containerClassName="avatar-skeleton"
              style={{
                transform: "translateY(1.5px)",
                transform: "translatex(1.5px)",
              }}
            />
          </div>
          <div className="left_link">
            <Skeleton
              height="35px"
              width="35px"
              borderRadius="50px"
              containerClassName="avatar-skeleton"
              style={{
                transform: "translateY(1.5px)",
                transform: "translatex(1.5px)",
              }}
            />
            <Skeleton
              height="20px"
              width="100px"
              borderRadius="10px"
              containerClassName="avatar-skeleton"
              style={{
                transform: "translateY(1.5px)",
                transform: "translatex(1.5px)",
              }}
            />
          </div>
          <div className="left_link">
            <Skeleton
              height="35px"
              width="35px"
              borderRadius="50px"
              containerClassName="avatar-skeleton"
              style={{
                transform: "translateY(1.5px)",
                transform: "translatex(1.5px)",
              }}
            />
            <Skeleton
              height="20px"
              width="100px"
              borderRadius="10px"
              containerClassName="avatar-skeleton"
              style={{
                transform: "translateY(1.5px)",
                transform: "translatex(1.5px)",
              }}
            />
          </div>
          <div className="left_link">
            <Skeleton
              height="35px"
              width="35px"
              borderRadius="50px"
              containerClassName="avatar-skeleton"
              style={{
                transform: "translateY(1.5px)",
                transform: "translatex(1.5px)",
              }}
            />
            <Skeleton
              height="20px"
              width="100px"
              borderRadius="10px"
              containerClassName="avatar-skeleton"
              style={{
                transform: "translateY(1.5px)",
                transform: "translatex(1.5px)",
              }}
            />
          </div>
          <div className="left_link">
            <Skeleton
              height="35px"
              width="35px"
              borderRadius="50px"
              containerClassName="avatar-skeleton"
              style={{
                transform: "translateY(1.5px)",
                transform: "translatex(1.5px)",
              }}
            />
            <Skeleton
              height="20px"
              width="100px"
              borderRadius="10px"
              containerClassName="avatar-skeleton"
              style={{
                transform: "translateY(1.5px)",
                transform: "translatex(1.5px)",
              }}
            />
          </div>
        </>
      ) : (
        <>
          {postss ? (
            <div></div>
          ) : (
            <Link to="/profile" className="left_link hover1">
              <img src={user?.picture} alt="empresy user" />
              {/* se trae user y se pregunta si existe, en caso positivo se toma la
        imagen y con la interrogacion se evitan errores*/}
              <span>{user?.company_Name}</span>
            </Link>
          )}
          {postss
            ? left
                .slice(0, 8)
                .map((link, i) => (
                  <LeftLink
                    to={link.to}
                    key={i}
                    img={link.img}
                    text={link.text}
                    notification={link.notification}
                    setVisitorModal={setVisitorModal}
                    postss
                  />
                ))
            : left
                .slice(0, 8)
                .map((link, i) => (
                  <LeftLink
                    to={link.to}
                    key={i}
                    img={link.img}
                    text={link.text}
                    notification={link.notification}
                  />
                ))}
          {/* {!visible && (
        <div className="left_link hover1">
          <div
            className="small_circle"
            onClick={() => {
              setVisitble(true);
            }}
          >
            <ExpandMoreIcon />
          </div>
          <span>More options</span>
        </div>
      )} */}
          {visible && (
            <div className="more_left">
              {left.slice(8, left.length).map((link, i) => (
                <LeftLink
                  key={i}
                  img={link.img}
                  text={link.text}
                  notification={link.notification}
                  postss
                />
              ))}
              <div
                className="left_link hover1"
                onClick={() => {
                  setVisitble(false);
                }}
              >
                <div className="small_circle rotate360">
                  <ExpandMoreIcon />
                </div>
                <span>Less options</span>
              </div>
            </div>
          )}
          {/* <div className="splitter"></div>
      <div className="shortcut">
        <div className="heading">Your Shortcuts</div>
        <div className="edit_shortcut">Edit</div>
      </div>
      <div className="shortcut_list">
        <Shortcut
          link="https://www.youtube.com/"
          img="../../images/ytb.png"
          name="My Youtube Channel"
        />
        <Shortcut
          link="https://www.instagram.com//"
          img="../../images/insta.png"
          name="My Instagram Channel"
        />
      </div> */}
        </>
      )}
      <div className={`fb_copyright ${visible && "relative_fb_copyright"}`}>
        <Link to="/">Privacy </Link>
        <span>. </span>
        <Link to="/">Terms </Link>
        <span>. </span>
        <Link to="/">Advertising </Link>
        <span>. </span>
        <Link to="/">
          Ad Choices <i className="ad_choices_icon"></i>
        </Link>
        <span>. </span>
        <Link to="/">Cookies </Link>
        <span>. </span>
        <Link to="/">More </Link>
        <span>. </span>
        <br />
        Empresy © 2023
      </div>
    </div>
  );
}
