import React, { useState } from "react";
import { Formik, Form } from "formik";
import LoginInput from "../../components/inputs/logininput";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";

export default function ChangePassword({
  password,
  setPassword,
  conf_Password,
  setConf_Password,
  error,
  loading,
  setLoading,
  userInfos,
  email,
  setError,
}) {
  const [succesChangePassword, setSuccesChangePassword] = useState(false);
  const navigate = useNavigate();
  const changePassword = async () => {
    try {
      setLoading(true);
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/changePassword`, {
        email,
        password,
      });
      setError("");
      setLoading(false);
      setSuccesChangePassword(true);
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
    }
  };
  const validatePassword = Yup.object({
    password: Yup.string()
      .required("Create a new password")
      .min(6, "Password must be at least six characters")
      .max(20, "Password can't be more than 20 characters"),

    conf_Password: Yup.string()
      .required("Confirm your password")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });
  return (
    <>
      {!succesChangePassword ? (
        <div className="reset_form" style={{ height: "320px" }}>
          <div className="reset_form_header">Escoje una nueva contraseña</div>
          <div className="reset_form_text">
            Porfavor crea una nueva contraseña.
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              password,
              conf_Password,
            }}
            validationSchema={
              validatePassword
            } /*funcion que validara si el password es el adecuado*/
            onSubmit={() => {
              changePassword();
            }}
          >
            {(formik) => (
              <Form>
                <LoginInput
                  type="password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Nueva contraseña"
                />
                <LoginInput
                  type="password"
                  name="conf_Password"
                  onChange={(e) => setConf_Password(e.target.value)}
                  placeholder="Confirmar nueva contraseña"
                />
                {error && <div className="error_text">{error}</div>}
                <div className="reset_form_btns">
                  <Link to="/login" className="grey_btn">
                    Volver
                  </Link>
                  <button className="blue_btn">Continuar</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <>
          <div className="reset_form" style={{ height: "320px" }}>
            <div className="reset_form_header">
              Cambio de contraseña exitoso
            </div>
            <div className="reset_form_text">
              Registrate con tu nueva contraseña!
            </div>

            <div className="reset_form_btns">
              <Link to="/login">
                <button className="blue_btn">Continuar</button>
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
}
