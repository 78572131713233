import { Link } from "react-router-dom";
import FooterImage from "../../styles/icons/empresy-logo-white.svg";

export default function Footer() {
  return (
    <footer className="login_footer">
      <div className="login_footer_wrap">
        <img src={FooterImage} alt="empresy-logo-blanco" />
        <Link> © 2023</Link>
        <Link>Acerca de </Link>
        <Link>Terminos y condiciones</Link>
        {/* <select className="login_footer_wrap">
          <option>Select a language</option>
          <option>English(UK)</option>
          <option>Español(España)</option>
          <option>Français(FR)</option>
          <option>Italiano(IT)</option>
          <option>Deutsch(DE)</option>
          <option>Portugués(Brasil)</option>
          <option>हिन्दी</option>
          <option>中文</option>
        </select> */}
      </div>
    </footer>
  );
}
