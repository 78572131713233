import DarkModeIcon from "@mui/icons-material/DarkMode";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export default function DisplayAccesability({ setVisible }) {
  return (
    <div className="absolute_wrap">
      <div className="absolute_wrap_header">
        <div
          className="circle hover1"
          onClick={() => {
            setVisible(0);
          }}
        >
          <ChevronLeftIcon fontSize="large" />
        </div>
        Pantalla
      </div>
      <div className="mmenu_main">
        <div className="small_circle">
          <DarkModeIcon />
        </div>
        <div className="mmenu_col">
          <span className="mmenu_span1">Modo Oscuro </span>
          <span className="mmenu_span2">
            Ajusta la pantalla para dar descanso a tu ojos
          </span>
        </div>
      </div>
      <label htmlFor="darkOff" className="hover1">
        <span>Apagar</span>
        <input type="radio" name="dark" id="darkOff" />
      </label>
      <label htmlFor="darkOn" className="hover1">
        <span>Prender</span>
        <input type="radio" name="dark" id="darkOn" />
      </label>
    </div>
  );
}
