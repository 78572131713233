import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import LoadingMaerketPlaceCrad from "./LoadingMaerketPlaceCrad";
import style from "./style.css";

export default function LoadingMarketPlace() {
  return (
    <div className="loading_marketplace_card">
      <LoadingMaerketPlaceCrad />
      <LoadingMaerketPlaceCrad />
      <LoadingMaerketPlaceCrad />
    </div>
  );
}
