import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import RegisterInput from "../inputs/registerinput";
import * as Yup from "yup";
import EconomicSectorSelect from "./EconomicSectorSelect";
import axios from "axios";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import { useTranslation } from "react-i18next";
import useGeoLocation from "../../hooks/useGeoLocation";

const userInfos = {
  company_Name: "",
  email: "",
  password: "",
  phone_number: "",
  Economic_Sector: "",
  code: "",
  location: "",
};
export default function Registerform({ setVisible, setPolicy }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState(userInfos);
  const { city, region, country, zip } = useGeoLocation();
  const {
    company_Name,
    email,
    password,
    phone_number,
    Economic_Sector,
    code,
    location,
  } = user;

  useEffect(() => {
    if (city && region && country) {
      setUser((prevUser) => ({
        ...prevUser,
        location: {
          city: city,
          region: region,
          country: country,
          zip: zip,
        },
      }));
    }
  }, [city, region, country]);

  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const registerValidation = Yup.object({
    company_Name: Yup.string()
      .required(t("Enter-your-company-name"))
      .min(3, t("The-company-name-must-be"))
      .max(30, t("The-company-name-must-be"))
      .matches(
        /^(?!^(\p{L})\1{3})[\p{L}0-9\s!@#$%&*]+$/u,
        t("special-characters-are-not-allowed")
      ),
    email: Yup.string()
      .required(t("Email-is-required"))
      .email(t("Enter-a-valid-email"))
      .matches(/^[^@\s]+@[^@\s]+$/, t("invalid-format")),

    password: Yup.string()
      .required(t("Create-a-password"))
      .min(6, t("The-password-must-be-at-least-6-characters"))
      .max(20, t("The-password-must-have-a-maximum-of-20-characters")),
    phone_number: Yup.string()
      .required(t("Enter-a-valid-cell-phone-number"))
      .min(10, t("Enter-a-valid-cell-phone-number"))
      .max(10, t("Enter-a-valid-cell-phone-number"))
      .matches(/^[0-9]+$/, t("Only-numbers-are-accepted-in-this-box")),
    Economic_Sector: Yup.string().required(t("Select-an-economic-sector")),
  });
  const [selectError, setSelectError] = useState("");
  const [error, setError] = useState("");
  const [succes, setSucces] = useState("");
  const [loading, setLoading] = useState(false);

  /* install axios that help make the request form de frontend to de backend */
  const registerSubmit = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/register`,
        {
          company_Name,
          email,
          password,
          phone_number,
          Economic_Sector,
          code,
          location,
        }
      );

      setError("");
      setSucces(data.message);
      const { message, ...rest } = data;
      setTimeout(() => {
        dispatch({ type: "LOGIN", payload: rest });
        Cookies.set("user", JSON.stringify(rest));
        navigate("/");
      }, 2000);
    } catch (error) {
      setLoading(false);
      setSucces("");
      setError(error.response.data.message);
    }
  };
  return (
    <div className="blurr">
      <div className="register">
        <div className="register_header">
          <i className="exit_icon" onClick={() => setVisible(false)}></i>
          <span>{t("Join-Now")}</span>
          <span>{t("it-fast-and-easy")}</span>
        </div>

        <Formik
          enableReinitialize
          initialValues={{
            company_Name,
            email,
            password,
            phone_number,
            Economic_Sector,
          }}
          validationSchema={registerValidation}
          onSubmit={(user) => {
            let option = user.Economic_Sector;

            let notOption = "";
            let noOption = "Select an option";

            if (option === notOption) {
              setSelectError("Please select an option");
            } else if (option === noOption) {
              setSelectError("Please select an option");
            } else {
              setSelectError("");
              registerSubmit();
            }
          }}
        >
          {(formik) => (
            <Form className="register_form">
              <div className="register_line">
                <RegisterInput
                  type="text"
                  placeholder={t("company-name")}
                  name="company_Name"
                  onChange={handleRegisterChange}
                  autoComplete="organization"
                />
                <RegisterInput
                  type="email"
                  placeholder={t("E-mail")}
                  name="email"
                  onChange={handleRegisterChange}
                  autoComplete="email"
                />

                <RegisterInput
                  type="text"
                  placeholder={t("cell-phone-number")}
                  name="phone_number"
                  onChange={handleRegisterChange}
                  autoComplete="tel"
                />

                <RegisterInput
                  type="password"
                  placeholder={t("create-password")}
                  name="password"
                  onChange={handleRegisterChange}
                  autoComplete="new-password"
                />
                <span style={{ fontSize: "14px" }}>
                  {t("If-you-do-not-have-a-code-skip-this-box")}
                </span>
                <RegisterInput
                  type="text"
                  placeholder={t("Code")}
                  name="code"
                  onChange={handleRegisterChange}
                />
              </div>
              <div className="reg_col">
                <div className="reg_line_header">
                  {t("Economic-Sector")} <i className="info_icon"></i>
                </div>
                <EconomicSectorSelect
                  selectError={selectError}
                  handleRegisterChange={handleRegisterChange}
                />
              </div>
              <div className="reg_info">
                {t("By-clicking-Register-you-are-accepting-our")}
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => setPolicy(true)}
                >
                  {" "}
                  {t("Terms-Data-Policy-&-Cookies-Policy")}
                </span>{" "}
                {t("You-may-receive-SMS-from-us")}
              </div>
              <div className="reg_btn_wrapper">
                <button className="btn-register" disabled={loading}>
                  {loading ? (
                    <PulseLoader color="white" size={5} />
                  ) : (
                    t("Join-Now")
                  )}
                </button>
              </div>
              {succes && <div className="succes_text">{succes}</div>}
              {error && <div className="error_text">{error}</div>}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
