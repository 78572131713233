import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import LeakAddIcon from "@mui/icons-material/LeakAdd";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import chat from "../../icons/chat_black.png";
import { useEffect, useRef, useState } from "react";
import useClickOutside from "../../helpers/clickOutside";
import { useSelector } from "react-redux";
import {
  acceptRequest,
  addFriend,
  cancelRequest,
  deleteRequest,
  follow,
  unfollow,
  unfriend,
} from "../../functions/user";
import SocketContext from "../../context/SocketContext";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ShareIcon from "@mui/icons-material/Share";

function Friendship({
  friendshipp,
  profileid,
  socket,
  setSendMessage,
  visitor,
  user,
  setShowVisitorMessage,
  profile,
  setShare,
  setShareVisitor,
}) {
  const { t } = useTranslation();
  const [friendship, setFriendship] = useState(friendshipp);
  useEffect(() => {
    setFriendship(friendshipp);
  }, [friendshipp]);
  const [friendsMenu, setFriendsMenu] = useState(false);
  const [respondMenu, setRespondMenu] = useState(false);
  const menu = useRef(null);
  const respond = useRef(null);
  useClickOutside(menu, () => setFriendsMenu(false));
  useClickOutside(respond, () => setRespondMenu(false));
  const addFriendHandler = async () => {
    setFriendship({ ...friendship, requestSent: true, following: true });
    await addFriend(profileid, user.token);
  };
  const cancelRequestHandler = async () => {
    setFriendship({ ...friendship, requestSent: false, following: false });
    await cancelRequest(profileid, user.token);
  };
  const followtHandler = async () => {
    setFriendship({ ...friendship, following: true });
    await follow(profileid, user.token);
  };
  const unFollowtHandler = async () => {
    setFriendship({ ...friendship, following: false });
    await unfollow(profileid, user.token);
  };
  const acceptRequestHandler = async () => {
    setFriendship({
      ...friendship,
      friends: true,
      following: true,
      requestSent: false,
      requestReceived: false,
    });
    await acceptRequest(profileid, user.token);
  };
  const unfriendHandler = async () => {
    setFriendship({
      ...friendship,
      friends: false,
      following: false,
      requestSent: false,
      requestReceived: false,
    });
    await unfriend(profileid, user.token);
  };

  const deleteRequestHandler = async () => {
    setFriendship({
      ...friendship,
      friends: false,
      following: false,
      requestSent: false,
      requestReceived: false,
    });
    await deleteRequest(profileid, user.token);
  };

  const handleOpenConversation = async () => {
    socket.emit("conversation_from_profile", profileid);
  };

  return (
    <div className="friendship">
      {friendship?.friends ? (
        <div className="friends_menu_wrap">
          {/* <button className="grey_btn" onClick={() => setFriendsMenu(true)}>
            <CheckCircleIcon />
            <span>Conection</span>
          </button> */}
          {friendsMenu && (
            <div className="open_cover_menu" ref={menu}>
              <div className="open_cover_menu_item hover1">
                <EditIcon />
                {t("edit-follow")}
              </div>
              {friendship?.following ? (
                <div
                  className="open_cover_menu_item hover1"
                  onClick={() => unFollowtHandler()}
                >
                  <CancelPresentationIcon />
                  Dejar de seguir
                </div>
              ) : (
                <div
                  className="open_cover_menu_item hover1"
                  onClick={() => followtHandler()}
                >
                  <ConnectWithoutContactIcon />
                  Seguir
                </div>
              )}
              <div
                className="open_cover_menu_item hover1"
                onClick={() => unfriendHandler()}
              >
                <PersonRemoveIcon />
                Remover
              </div>
            </div>
          )}
        </div>
      ) : (
        !friendship?.requestSent &&
        !friendship?.requestReceived && <div className=""></div>
      )}
      {friendship?.requestSent ? (
        <button
          className="button blue_btn"
          onClick={() => cancelRequestHandler()}
        >
          <CancelIcon className="invert" />
          <span>Cancelar Solicitud</span>
        </button>
      ) : (
        friendship?.requestReceived && (
          <div className="friends_menu_wrap" ref={respond}>
            <button className="grey_btn" onClick={() => setRespondMenu(true)}>
              <CheckCircleIcon />
              <span>Responder</span>
            </button>
            {respondMenu && (
              <div className="open_cover_menu" ref={menu}>
                <div
                  className="open_cover_menu_item hover1"
                  onClick={() => acceptRequestHandler()}
                >
                  <CheckCircleIcon />
                  Confirmar
                </div>
                <div
                  className="open_cover_menu_item hover1"
                  onClick={() => deleteRequestHandler()}
                >
                  <DeleteIcon />
                  Eliminar
                </div>
              </div>
            )}
          </div>
        )
      )}
      <div className="flex1">
        {friendship?.following ? (
          <button
            className="button grey_btn"
            onClick={() => unFollowtHandler()}
          >
            <ConnectWithoutContactIcon />
            <span>{t("following")}</span>
          </button>
        ) : (
          <button
            className="button blue_btn"
            onClick={() =>
              visitor
                ? setShowVisitorMessage((prev) => !prev)
                : followtHandler()
            }
          >
            <ConnectWithoutContactIcon className="invert" />
            <span>{t("follow")}</span>
          </button>
        )}
        <Link
          to={`https://api.whatsapp.com/send?phone=57${profile?.phone_number}&text=Hola%20te%20vi%20en%20empresy.com%20quisiera%20conocer%20este%20producto!`}
          className="button green_btn"
          target="_blank"
        >
          <WhatsAppIcon />
          <span>Whatsapp</span>
        </Link>

        <div
          className="button blue_btn"
          onClick={() => setShareVisitor((prev) => !prev)}
        >
          <ShareIcon />
          <span>{t("share")}</span>
        </div>
      </div>
    </div>
  );
}

const FriendshipWithContext = (props) => (
  <SocketContext.Consumer>
    {(socket) => <Friendship {...props} socket={socket} />}
  </SocketContext.Consumer>
);
export default FriendshipWithContext;
