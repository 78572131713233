import ImageHomeMessenger from "../../styles/icons/empresy-logo-white.svg";
import "./style.css";
export default function EmpresyMessageHome() {
  return (
    <div className="empresy_home_messenger">
      {/*container */}
      <div className="mt-1.5 w-full h-full flex flex-col gap-y-8 items-center justify-center">
        <span>
          <img
            style={{ width: "150%", height: "150%" }}
            src={ImageHomeMessenger}
            alt="empresy_messenger"
          />
        </span>
        {/*infos*/}
        <div className="mt-1 text-center space-y-[12px]">
          <p className="text-[30px] dark:text-dark_text_4 font-extralight">
            Empresy Messenger
          </p>
          <p className="text-sm dark:text-dark_text_3">
            Envia y recibe mensajes
          </p>
        </div>
      </div>
    </div>
  );
}
