import React, { useState } from "react";
import { Formik, Form } from "formik";
import LoginInput from "../../components/inputs/logininput";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";

export default function CodeVerification({
  code,
  setCode,
  error,
  setError,
  loading,
  setLoading,
  setVisible,
  userInfos,
  email,
}) {
  const [sentCode, setSendCode] = useState(true);
  const validateCode = Yup.object({
    code: Yup.string()
      .required("code is required")
      .min("5", "Code must have 5 caracters")
      .max("5", "Code must have 5 caracters"),
  });

  const verifyCode = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/validateResetCode`,
        { email, code }
      );
      setVisible(3);
      setError("");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
    }
  };
  return (
    <>
      {sentCode ? (
        <div className="reset_form">
          <div className="reset_form_header">Revisa tu correo electronico</div>
          <div className="reset_form_text">
            Hemos enviado el codigo de verificación a tu correo electronico,
            recomendamos revisar la carpeta de spam!
          </div>
          <div>
            <div className="reset_form_btns">
              <Link to="/login" className="grey_btn">
                Volver
              </Link>
              <button
                className="blue_btn"
                onClick={() => setSendCode((prev) => !prev)}
              >
                Continuar
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="reset_form">
          <div className="reset_form_header">Verificación de Codigo</div>
          <div className="reset_form_text">
            Porfavor ingresa el codigo de verificación enviado a tu correo.
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              code,
            }}
            validationSchema={validateCode}
            onSubmit={() => {
              verifyCode();
            }}
          >
            {(formik) => (
              <Form>
                <LoginInput
                  type="text"
                  name="code"
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Code"
                />
                {error && <div className="error_text">{error}</div>}
                <div className="reset_form_btns">
                  <Link to="/login" className="grey_btn">
                    Volver
                  </Link>
                  <button className="blue_btn">Verificar</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}
