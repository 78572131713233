export default function Contact({ user, postss }) {
  return (
    <>
      {postss ? (
        <div></div>
      ) : (
        <div className="contact hover1">
          <div>
            <img src={user?.picture} alt="empresy recomended" />
          </div>
          <div className="contact_info">
            <span>{user?.company_Name}</span>
            <span>{user?.Economic_Sector}</span>
          </div>
        </div>
      )}
    </>
  );
}
