import { useDispatch, useSelector } from "react-redux";
import ChatHeader from "./chatheader/ChatHeader";
import ChatMessages from "./messages/ChatMessages";
import { getMessages } from "../../functions/messages";
import { useEffect, useRef } from "react";
import ChatActions from "./actions/ChatActions";
import SocketContext from "../../context/SocketContext";
import { checkOnlineStatus } from "../../helpers/chat_receiverid";

export default function ChatContainer({
  user,
  setShowMessages,
  onlineUsers,
  typing,
  deletedMessage,
  receiverId,
}) {
  const { activeConversation, messages } = useSelector((state) => state.chat);

  useEffect(() => {
    if (activeConversation?._id) {
      getData();
    }
  }, [activeConversation]);

  useEffect(() => {
    if (deletedMessage) {
      getData();
    }
  }, [deletedMessage]);

  const dispatch = useDispatch();
  const values = {
    convo_id: activeConversation?._id,
  };

  const getData = async () => {
    dispatch({ type: "MESSAGES_LOADING" });
    const data = await getMessages(values, user.token);
    if (data) {
      dispatch({ type: "GETMESSAGES", payload: data });
    } else {
      dispatch({ type: "MESSAGES_REJECTED", payload: data.data });
    }
  };

  const topRef = useRef();

  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="chat_conversation" ref={topRef}>
      {/*content*/}
      <div>
        {/*chat_header*/}
        <ChatHeader
          setShowMessages={setShowMessages}
          online={checkOnlineStatus(
            onlineUsers,
            user,
            activeConversation.users
          )}
          user={user}
        />
        {/*chat_messeges*/}
        <ChatMessages
          user={user}
          typing={typing}
          deletedMessage={deletedMessage}
        />
        {/*chat_actions*/}
        <ChatActions user={user} />
      </div>
    </div>
  );
}
