import NotificationComment from "./NotificationComment";
import NotificationFollow from "./NotificationFollow";
import NotificationReact from "./NotificationReact";

export default function Notifications({ user }) {
  const combinedNotifications = [
    ...(user?.notificationComment || []),
    ...(user?.notificationFollowing || []),
    ...(user?.notificationReact || []),
  ].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return (
    <div className="mmmmenu scrollbar">
      <div>
        <div className="mmenu_header">Notificaciones</div>
        <div className="mmenu_splitter" style={{ marginBottom: "10px" }}></div>

        {combinedNotifications.length === 0 ? (
          <span>No tienes notificaciones</span>
        ) : (
          combinedNotifications.map((notification, i) => {
            if (notification.type === "comment") {
              return (
                <NotificationComment
                  key={i}
                  username={notification?.user?.username}
                  postId={notification?.postId}
                  picture={notification?.user?.picture}
                  name={notification?.user?.company_Name}
                  created={notification?.createdAt}
                />
              );
            } else if (notification.type === "following") {
              return (
                <NotificationFollow
                  key={i}
                  username={notification?.user?.username}
                  picture={notification?.user?.picture}
                  name={notification?.user?.company_Name}
                  created={notification?.createdAt}
                />
              );
            } else if (notification?.type === "react") {
              return (
                <NotificationReact
                  key={i}
                  username={notification?.user?.username}
                  picture={notification?.user?.picture}
                  name={notification?.user?.company_Name}
                  created={notification?.createdAt}
                />
              );
            }
            // Add other types of notifications if needed
          })
        )}
      </div>
    </div>
  );
}
