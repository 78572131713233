import { Routes, Route } from "react-router-dom";
import Login from "./pages/login";
import Profile from "./pages/profile";
import Home from "./pages/home";
import LoggedInRoutes from "./routes/LoggedInRoutes";
import NotLoggedInRoutes from "./routes/NotLoggedInRoutes";
import Activate from "./pages/home/Activate";
import Reset from "./pages/reset";
import CreatePostPopup from "./components/createPostPopup";
import { useSelector } from "react-redux";
import { useEffect, useReducer, useState } from "react";
import axios from "axios";
import { postsReducer } from "./functions/reducers";
import Visitor from "./pages/HomeVisitor/Visitor";
import Connect from "./pages/connect";
import Messages from "./pages/messages";
import Premium from "./pages/premium";
import EmpresyAds from "./pages/empresyads/EmpresyAds";
import SavedPosts from "./pages/savedPost";
import { io } from "socket.io-client";
import SocketContext from "./context/SocketContext";
import About from "./pages/login/About";
import Contactt from "./pages/login/Contactt";
import Employ from "./pages/login/Employ";
import Searching from "./pages/searching";
import Terms from "./pages/login/Terms";
import MarketPlace from "./pages/marketplace";
import ProductScreen from "./pages/marketplace/ProductScreen";
import i18n from "./i18n";
import { Suspense } from "react";
import LocalesContext from "./LocalesContext";
import Cookies from "js-cookie";
import NotFound from "./pages/NotFound/NotFound";

//socket.io
const socket = io(process.env.REACT_APP_BACKEND_URL);

function App() {
  const { user } = useSelector((state) => ({ ...state }));

  return (
    <div>
      <SocketContext.Provider value={socket}>
        <LocalesContext.Provider
          value={{ locale: i18n.language, setLocale: i18n.changeLanguage }}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              {/* Routes for logged-in users */}
              {user ? (
                <Route element={<LoggedInRoutes user={user} />}>
                  <Route path="/profile" element={<Profile user={user} />} />
                  <Route path="/connect" element={<Connect />} />
                  <Route
                    path="/product/:id"
                    element={<ProductScreen user={user} />}
                  />
                  <Route
                    path="/myproduct/:id"
                    element={<ProductScreen user={user} myproducts />}
                  />
                  <Route
                    path="/searching"
                    element={<Searching user={user} />}
                  />
                  <Route path="/premium" element={<Premium user={user} />} />
                  <Route
                    path="/connect/:type"
                    element={<Connect user={user} />}
                  />
                  <Route
                    path="/empresyads"
                    element={<EmpresyAds user={user} />}
                  />
                  <Route
                    path="/profile/:username"
                    element={<Profile user={user} />}
                  />
                  <Route path="/messages" element={<Messages user={user} />} />
                  <Route
                    path="/savedposts"
                    element={<SavedPosts user={user} />}
                  />
                  <Route path="/" element={<MarketPlace user={user} />} />
                  <Route path="/activate/:token" element={<Activate />} />
                  <Route path="*" element={<NotFound />} />
                </Route>
              ) : (
                /* Routes for not logged-in users */
                <Route element={<NotLoggedInRoutes />}>
                  <Route path="/login" element={<Login />} />
                  <Route path="/" element={<MarketPlace visitor />} />
                  <Route
                    path="/product/:id"
                    element={<ProductScreen visitor />}
                  />
                  <Route
                    path="/profile/:username"
                    element={<Profile visitor />}
                  />
                  <Route path="/about" element={<About />} />
                  <Route path="/contact" element={<Contactt />} />
                  <Route path="/employ" element={<Employ />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/404" element={<NotFound />} />
                  <Route path="*" element={<NotFound />} />
                </Route>
              )}
              <Route path="/reset" element={<Reset />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </LocalesContext.Provider>
      </SocketContext.Provider>
    </div>
  );
}

export default App;
