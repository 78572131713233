import empresyFooter from "../../styles/icons/empresy-logo-white.svg";
import { Link } from "react-router-dom";

export default function Terms() {
  return (
    <div>
      <section class="premium-section">
        <div class="container">
          <div class="row">
            <div class="col">
              <h1>Terminos y Condiciones</h1>
              <p>
                <div className="policy_content">
                  Fecha de entrada en vigor: 1 de Enero 2023 1. Aceptación de
                  los Términos 1.1. Bienvenido a empresy. Estos términos y
                  condiciones ("Términos") regulan el uso de nuestros servicios
                  y sitio web. Al utilizar empresy, aceptas cumplir con estos
                  Términos y nuestra Política de Privacidad. 2. Registro y
                  Cuentas 2.1. Para utilizar nuestros servicios, debes crear una
                  cuenta. Debes proporcionar información precisa y actualizada
                  durante el proceso de registro. 2.2. Eres responsable de
                  mantener la confidencialidad de tu contraseña y de todas las
                  actividades realizadas en tu cuenta. 2.3. No puedes crear una
                  cuenta en empresy si eres menor de 18 años. 3. Contenido del
                  Usuario 3.1. Eres el único responsable del contenido que
                  compartes en empresy. No debes compartir contenido que sea
                  ilegal, difamatorio, abusivo, obsceno, discriminatorio, que
                  viole los derechos de propiedad intelectual de terceros o que
                  viole cualquier ley o regulación aplicable. 3.2. empresy se
                  reserva el derecho de eliminar o modificar cualquier contenido
                  que infrinja estos Términos. 4. Privacidad y Protección de
                  Datos 4.1. empresy recopila, almacena y utiliza tus datos
                  personales de acuerdo con nuestra Política de Privacidad. Al
                  utilizar nuestros servicios, aceptas esta recopilación y uso
                  de datos. 5. Propiedad Intelectual 5.1. Mantienes la propiedad
                  de los contenidos que compartes en empresy. Al publicar
                  contenido en la plataforma, nos otorgas una licencia
                  internacional, libre de regalías, sublicenciable, transferible
                  y no exclusiva para alojar, usar, distribuir, modificar,
                  publicar, copiar, mostrar o exhibir públicamente y traducir tu
                  contenido, así como para crear trabajos derivados de él (de
                  conformidad con tu configuración de privacidad y de la app).
                  5.2. No puedes utilizar la marca empresy ni ningún material
                  con derechos de autor sin nuestro permiso expreso por escrito.
                  6. Seguridad y Responsabilidad 6.1. empresy implementa medidas
                  de seguridad, pero no garantiza la seguridad absoluta de tus
                  datos ni la disponibilidad ininterrumpida de nuestros
                  servicios. 6.2. Eres responsable de mantener la seguridad de
                  tu cuenta y de informarnos inmediatamente en caso de acceso no
                  autorizado o cualquier otro problema de seguridad. 7.
                  Comportamiento del Usuario 7.1. Debes seguir un código de
                  conducta que promueva el respeto mutuo y la cortesía en
                  empresy. El acoso, la intimidación y el discurso de odio no
                  son tolerados. 8. Modificaciones y Actualizaciones 8.1.
                  empresy se reserva el derecho de modificar estos Términos en
                  cualquier momento. Notificaremos a los usuarios sobre cambios
                  importantes. Si continúas utilizando nuestros Servicios
                  después de los cambios, aceptas los nuevos Términos. 9.
                  Finalización de la Cuenta 9.1. Puedes cerrar tu cuenta en
                  cualquier momento. empresy también se reserva el derecho de
                  suspender o cerrar cuentas en caso de incumplimiento de estos
                  Términos. 10. Límites de Responsabilidad 10.1. empresy se
                  esfuerza por proporcionar servicios de alta calidad, pero no
                  garantiza que nuestros servicios sean seguros, libres de
                  errores o ininterrumpidos. empresy no es responsable de las
                  acciones de los usuarios. 10.2. empresy no será responsable de
                  ningún daño directo, indirecto, incidental, especial,
                  consecuente o punitivo que surja del uso o la imposibilidad de
                  usar nuestros servicios. 11. Ley Aplicable y Jurisdicción
                  11.1. Estos Términos se rigen por las leyes. Cualquier disputa
                  se resolverá en los tribunales. 12. Disposiciones Finales
                  12.1. Estos Términos representan el acuerdo completo entre tú
                  y empresy con respecto al uso de nuestros servicios y
                  reemplazan cualquier acuerdo anterior. 12.2. Si alguna parte
                  de estos Términos se considera inválida o no ejecutable, la
                  parte restante seguirá en pleno vigor y efecto. 12.3. No
                  puedes transferir tus derechos u obligaciones en virtud de
                  estos Términos sin nuestro consentimiento por escrito.
                  Política de Privacidad de Empresy Última actualización: 1 de
                  enero de 2023 Empresy, en adelante "nosotros" o "nuestra", es
                  una red social en línea que se preocupa por la privacidad y
                  seguridad de tus datos personales. Esta política de privacidad
                  tiene como objetivo explicarte cómo recopilamos, utilizamos,
                  compartimos y protegemos tu información personal cuando
                  utilizas nuestros servicios y la aplicación Empresy. Al
                  registrarte o utilizar Empresy, aceptas las prácticas
                  descritas en esta política. Información que Recopilamos 1.
                  Información Personal Recopilamos información personal que tú
                  nos proporcionas directamente, como: Nombre, dirección de
                  correo electrónico, fecha de nacimiento y otra información de
                  perfil. Contenido que creas, como publicaciones, comentarios,
                  fotos y videos. Mensajes que envías y recibes a través de
                  Empresy, incluyendo contenido y metadatos asociados.
                  Información de contacto que compartes con nosotros para fines
                  de comunicación y autenticación. 2. Información sobre el Uso
                  Recopilamos información sobre cómo utilizas Empresy,
                  incluyendo: Actividades en la plataforma, como las
                  publicaciones que ves o en las que interactúas, grupos a los
                  que te unes y eventos a los que asistes. Interacciones con
                  otros usuarios, como amistades, conexiones y mensajes.
                  Información sobre el dispositivo que utilizas para acceder a
                  Empresy, como el tipo de dispositivo, la dirección IP y el
                  navegador web. 3. Información de Ubicación Si das tu
                  consentimiento, podemos recopilar información sobre tu
                  ubicación, como la ubicación GPS de tu dispositivo móvil, para
                  proporcionarte funciones basadas en la ubicación. 4.
                  Información del Dispositivo Recopilamos información sobre tu
                  dispositivo, incluyendo el tipo de dispositivo, sistema
                  operativo, identificadores únicos del dispositivo y señales
                  del dispositivo para prevenir actividades fraudulentas. 5.
                  Información de Cookies y Tecnologías Similares Utilizamos
                  cookies y tecnologías similares para recopilar información
                  sobre cómo interactúas con Empresy y para mejorar nuestros
                  servicios. Puedes configurar tu navegador para que rechace las
                  cookies, pero esto puede afectar tu experiencia en nuestra
                  plataforma. Cómo Utilizamos Tu Información Utilizamos tu
                  información personal para los siguientes propósitos: 1.
                  Personalización Utilizamos tu información para personalizar tu
                  experiencia en Empresy, incluyendo la personalización de
                  contenido, sugerencias de conexiones y recomendaciones de
                  grupos y eventos. 2. Comunicación Utilizamos tu información de
                  contacto para comunicarnos contigo, proporcionarte
                  actualizaciones sobre Empresy y responder a tus consultas y
                  solicitudes. 3. Publicidad Podemos utilizar información sobre
                  tus intereses y actividades en Empresy para personalizar los
                  anuncios que ves dentro y fuera de la plataforma. No
                  compartimos información personal identificable con anunciantes
                  sin tu consentimiento. 4. Desarrollo y Mejora de Empresy
                  Utilizamos tu información para desarrollar, probar y mejorar
                  nuestros servicios, incluyendo la solución de problemas
                  técnicos y la optimización de la plataforma. 5. Seguridad y
                  Protección Utilizamos tu información para garantizar la
                  seguridad, integridad y protección de Empresy y sus usuarios,
                  así como para prevenir actividades dañinas, fraudulentas o
                  ilícitas. Compartir Tu Información Compartimos tu información
                  personal con terceros en las siguientes circunstancias: 1.
                  Conexiones y Usuarios Tu nombre y foto de perfil son visibles
                  para otros usuarios de Empresy con los que te conectas o
                  interactúas. La información que compartes en publicaciones y
                  comentarios también es visible para otros usuarios. 2.
                  Compartir Contenido Cuando compartes contenido en Empresy,
                  como publicaciones, fotos o videos, esta información puede ser
                  accesible para otros usuarios según tu configuración de
                  privacidad. 3. Socios y Anunciantes Podemos compartir
                  información con socios y anunciantes para proporcionar
                  funciones personalizadas y mostrar anuncios relevantes. No
                  compartimos información personal identificable con anunciantes
                  sin tu consentimiento. 4. Cumplimiento Legal Podemos divulgar
                  información cuando sea necesario para cumplir con la
                  legislación aplicable o responder a solicitudes legales
                  válidas, incluyendo órdenes judiciales y solicitudes de
                  autoridades policiales. Transferencia Internacional de Datos
                  Tu información puede ser transferida y almacenada en
                  servidores ubicados en diferentes países, incluyendo los
                  Estados Unidos y otros lugares fuera de tu país de residencia.
                  Al utilizar Empresy, aceptas estas transferencias. Tus
                  Derechos y Elecciones Tienes derechos en relación con tu
                  información personal, incluyendo el acceso, rectificación y
                  eliminación de tus datos. Puedes configurar tus preferencias
                  de privacidad en tu cuenta de Empresy. Seguridad de la
                  Información Tomamos medidas de seguridad para proteger tu
                  información personal y mantener su confidencialidad. Sin
                  embargo, ningún sistema es completamente seguro, y no podemos
                  garantizar la seguridad de tu información. Menores de Edad
                  Empresy no está dirigido a menores de 13 años. Si tienes
                  conocimiento de que un menor ha proporcionado información
                  personal en Empresy, por favor, contáctanos para que podamos
                  eliminarla. Cambios en esta Política de Privacidad Esta
                  política de privacidad puede actualizarse periódicamente para
                  reflejar cambios en nuestras prácticas de privacidad. Te
                  notificaremos sobre cambios significativos en la política a
                  través de Empresy o por otros medios. Contáctanos Si tienes
                  preguntas o preocupaciones sobre esta política de privacidad o
                  nuestra recopilación y uso de información, por favor
                  contáctanos en [tu dirección de contacto]. Esta política de
                  privacidad se rige por las leyes vigentes en [tu
                  jurisdicción]. Si tienes alguna preocupación sobre la
                  privacidad o el manejo de tus datos, te animamos a ponerte en
                  contacto con nosotros para que podamos resolver cualquier
                  inquietud que puedas tener. Normas Comunitarias de Empresy
                  Bienvenido a Empresy. Nuestra comunidad se basa en la
                  colaboración, el respeto mutuo y la construcción de conexiones
                  significativas entre profesionales y empresas. Estas normas
                  comunitarias están diseñadas para mantener un ambiente seguro
                  y productivo para todos los usuarios. Al unirte y utilizar
                  Empresy, aceptas cumplir con estas normas: 1. Respeto y
                  Cortesía Respuesta: El respeto mutuo es esencial en Empresy.
                  Si sientes que has sido víctima de intimidación, acoso o
                  discurso de odio, por favor, repórtalo de inmediato.
                  Investigaremos y tomaremos medidas apropiadas, que pueden
                  incluir la suspensión de la cuenta del infractor. 2. Contenido
                  Relevante Respuesta: Publica contenido relevante para la
                  temática de Empresy. El contenido inapropiado o spam será
                  eliminado, y podrías recibir advertencias. Si persistes en la
                  publicación de contenido no relevante, tu cuenta podría ser
                  suspendida temporal o permanentemente. 3. Privacidad y
                  Confidencialidad Respuesta: Respetar la privacidad y
                  confidencialidad de otros es fundamental. Cualquier
                  divulgación de información personal o confidencial sin
                  consentimiento será tratada con seriedad. Los infractores
                  pueden enfrentar la suspensión de su cuenta. 4. Veracidad y
                  Autenticidad Respuesta: Mantén información precisa en tu
                  perfil y en todas tus interacciones. La creación de perfiles
                  falsos o el uso de identidades falsas resultará en la
                  eliminación de la cuenta y medidas legales, si es necesario.
                  5. Propiedad Intelectual Respuesta: Respetar los derechos de
                  propiedad intelectual es crucial. Si se descubre que infringes
                  los derechos de autor, marcas registradas u otras leyes, tu
                  contenido será eliminado y podrías enfrentar acciones legales.
                  6. Denuncia de Contenido Inapropiado Respuesta: Si encuentras
                  contenido inapropiado, repórtalo inmediatamente.
                  Investigaremos y eliminaremos cualquier contenido que viole
                  nuestras normas. No permitimos represalias contra quienes
                  denuncian. 7. Colaboración y Networking Respuesta: Fomentamos
                  la colaboración. Conéctate con otros usuarios y participa en
                  grupos y discusiones relevantes. Establece conexiones valiosas
                  para tu crecimiento profesional. Violaciones graves de estas
                  normas pueden resultar en la pérdida de la capacidad de
                  networking. 8. Cumplimiento de la Ley Respuesta: Cumple con
                  todas las leyes y regulaciones aplicables. No promovas ni
                  participes en actividades ilegales. La violación de la ley
                  resultará en la eliminación de la cuenta y la notificación a
                  las autoridades competentes. 9. Contenido Apropiado para Todas
                  las Edades Respuesta: Si compartes contenido no apto para
                  menores de edad, etiquétalo adecuadamente y restringe su
                  acceso a usuarios mayores de 18 años. La falta de etiquetado
                  adecuado puede dar lugar a medidas disciplinarias. 10.
                  Moderación de Grupos y Comentarios Respuesta: Como
                  administrador, modera tu grupo de manera efectiva. Elimina
                  contenido que viole estas normas o las reglas de tu grupo. Los
                  administradores son responsables de mantener un ambiente
                  seguro. El incumplimiento de estas normas puede resultar en
                  medidas que van desde advertencias y restricciones de cuenta
                  hasta la eliminación permanente de la cuenta y posibles
                  acciones legales. Nos reservamos el derecho de tomar las
                  medidas apropiadas según la gravedad de la violación. Gracias
                  por ser parte de la comunidad Empresy. Juntos, creamos un
                  espacio valioso para el crecimiento profesional y el
                  networking empresarial. Estas normas están sujetas a cambios,
                  y se espera que los usuarios las revisen regularmente para
                  mantenerse actualizados sobre las pautas de la comunidad.
                  Fecha de última actualización: 1 de enero 2023
                </div>
              </p>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="container1">
          <div className="row">
            <div className="col">
              <Link to="/login">
                <div className="logo">
                  <img
                    src={empresyFooter}
                    alt="empresy-logo-white"
                    style={{ width: "50%" }}
                  />
                </div>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ul className="footer-links">
                <li>
                  <Link to="/About">
                    <a href="pagina-acerca-de.html">Acerca de</a>
                  </Link>
                </li>
                <li></li>
                <li>
                  <Link to="/contact">
                    <a style={{ cursor: "pointer" }}>Contacto</a>
                  </Link>
                </li>
                <li>
                  <Link to="/employ">
                    {" "}
                    <a>Empleos</a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
