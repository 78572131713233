import { useState } from "react";

import { saveAs } from "file-saver";
import MenuItem from "../../components/post/MenuItem";
import { deleteRequirement } from "../../functions/requirement";

export default function RequireMenu({ reqUserId, userId, token, reqId, reqR }) {
  const [test, setTest] = useState(reqUserId === userId ? true : false); // means my post if not is not

  const deleteHandler = async () => {
    const res = await deleteRequirement(reqId, token);
    if (res.status === "ok") {
      reqR.current.style.display = "none";
    } else {
      return;
    }
  };

  return (
    <ul className="post_menu">
      {/* {imagesLength && (
        <MenuItem icon="fullscreen_icon" title="Pantalla completa" subtitle />
      )} */}

      {test && (
        <div onClick={() => deleteHandler()}>
          <MenuItem icon="trash_icon" title="Eliminar Requerimiento" subtitle />
        </div>
      )}
      {!test && (
        <MenuItem
          img="../../../icons/report.png"
          title="Reportar post"
          subtitle
        />
      )}
    </ul>
  );
}
