import { useEffect, useRef, useState } from "react";
import { Return, Search } from "../../svg";
import useClickOutside from "../../helpers/clickOutside";
import { searchVisitor } from "../../functions/user";
import { Link } from "react-router-dom";

export default function SearchMenuVisitor({
  color,
  setshowSearchMenuVisitor,
  setVisitorModal,
  t,
}) {
  const [iconVisible, setIconVisible] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [filterCity, setFilterCity] = useState("");
  const [searchHistory, setSearchHistory] = useState([]);
  const menu = useRef(null);
  const input = useRef(null);
  useClickOutside(menu, () => {
    setshowSearchMenuVisitor(false);
  });

  useEffect(() => {
    input.current.focus();
  }, []);

  const searchHandler = async () => {
    if (searchTerm === "") {
      setResults([]);
    } else {
      const res = await searchVisitor(searchTerm);
      if (res.length === 0) {
        setResults([{ empty: true }]); // Set a placeholder item indicating empty results
      } else {
        setResults(res);
      }
    }
  };
  const filterHandler = () => {
    if (filterCity === "") {
      // If the filterCity is empty, show the original results (res from searchHandler)
      searchHandler(); // Call searchHandler to reset results
    } else {
      // Normalize the filterCity value to be accent-insensitive and lowercase
      const normalizedFilterCity = filterCity
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      // Use the filter method to filter results based on the normalized filterCity value
      const filteredResults = results.filter((user) => {
        const normalizedCurrentCity = user?.details?.currentCity
          ? user.details.currentCity
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          : "";

        return normalizedCurrentCity.includes(normalizedFilterCity);
      });

      setResults(filteredResults);
    }
  };

  return (
    <div className="header_left search_area scrollbar" ref={menu}>
      <div className="search_wrap">
        <div className="search_top">
          <div className="header_logo">
            <div
              className="circle hover1"
              onClick={() => setshowSearchMenuVisitor(false)}
            >
              <Return color={color} />
            </div>
          </div>
          <div
            className="search"
            onClick={() => {
              // input.current.focus();
            }}
          >
            {iconVisible && (
              <div>
                <Search color={color} />
              </div>
            )}

            <input
              type="text"
              placeholder={t("search")}
              ref={input}
              value={searchTerm}
              onKeyUp={searchHandler}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      {results == "" && (
        <div className="search_history_header">
          <span>{t("recent-search")}</span>
          <a href="/">Editar</a>
        </div>
      )}
      <div className="search_history scrollbar"></div>
      <div className="search_results scrollbar">
        {results &&
          results?.map((user) =>
            user.empty ? (
              <div className="no_results" style={{ margin: "20px" }}>
                {t("no-serach-result")}
              </div>
            ) : (
              <Link
                className="search_user_item hover1"
                key={user._id}
                to={`/profile/${user?.username}`}
              >
                <img src={user.picture} alt="user_empresy_empresa_resultado" />
                <span>{user.company_Name}</span>
              </Link>
            )
          )}
      </div>
    </div>
  );
}
