import React, { useState } from "react";

export default function GridSquare({ post, setShowPost, setSelectedPost }) {
  const [index, CurrentIndex] = useState(0);

  const handleClick = () => {
    // Pass the clicked post data to the Posti component
    setSelectedPost(post);
    setShowPost(true);
  };

  return (
    <div className="grid_square">
      <img src={post.images[index].url} alt="" onClick={() => handleClick()} />
    </div>
  );
}
