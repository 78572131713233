import React from "react";
import Header from "../../components/header";
import imagenTop from "../../styles/icons/empresy-ads-logo.svg";
import imagenMid from "../../styles/icons/phone-empresy-ads.png";
import imagenBottom from "../../styles/icons/empresy-post-pauta.png";
import empresyFooter from "../../styles/icons/empresy-logo-white.svg";
import "./style.css";

export default function EmpresyAds({ user }) {
  return (
    <>
      <Header user={user} />
      <section className="premium-section">
        <div className="row">
          <div className="col">
            <img src={imagenTop} alt="empresy-ads" className="imagenTopAds" />
            <h1 hidden>Empresy Ads</h1>
            <p>
              Empresy Ads es nuestra plataforma publicitaria que permite a las
              empresas pautar con nosotros a través de nuestros servicios de:
              Espacio Empresarial Destacado y Post a Publicidad en un solo
              click.
            </p>
            <h2>
              <strong>Espacio Empresarial Destacado</strong>
            </h2>
            <div className="empresy_ads_mid">
              <p>
                Destaque su negocio de manera efectiva en los espacios
                principales y más visibles de nuestra página web. Con miles de
                visitas mensuales de empresas y personas interesadas en
                resultados empresariales, ofrecemos una oportunidad única para
                promocionar su negocio. Nuestros espacios publicitarios
                estratégicos garantizan una mayor visibilidad y alcance,
                ayudándole a llegar a su audiencia objetivo y lograr resultados
                sólidos.<br></br>
              </p>

              <img
                src={imagenMid}
                alt="empresy-phone-ads"
                className="imagenMid"
              />
            </div>
            <h2>
              <strong>Post a Publicidad en un solo click</strong>
            </h2>
            <div className="empresy-bottom">
              <p>
                Con un solo clic, transforma tu publicación en una poderosa
                herramienta de publicidad. Destaca tu contenido para que
                aparezca en los primeros resultados cuando los usuarios busquen
                temas relevantes a tu sector o intereses, maximizando así su
                visibilidad y llegando directamente a tu audiencia objetivo.
                <br></br>
              </p>

              <img
                src={imagenBottom}
                alt="empresy-post-ads"
                className="imagenBottom"
              />
            </div>
          </div>
        </div>
      </section>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="logo">
                <img src={empresyFooter} alt={empresyFooter} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ul className="footer-links">
                <li>
                  <a href="pagina-acerca-de.html">Acerca de</a>
                </li>
                <li>
                  <a href="pagina-terminos.html">Términos y Condiciones</a>
                </li>
                <li>
                  <a href="pagina-contacto.html">Contacto</a>
                </li>
                <li>
                  <a href="pagina-empleos.html">Empleos</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
