import React, { useEffect, useReducer, useRef, useState } from "react";
import Header from "../../components/header";
import "./style.css";
import { Search } from "../../svg";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useParams } from "react-router-dom";
import { productReducer } from "../../functions/reducers";
import {
  getAllProducts,
  getCategoryProducts,
  getCompany,
  getMyProducts,
  getProductsbyType,
  getSector,
  getSectorOffer,
  getSectorProducts,
} from "../../functions/products";
import useClickOutside from "../../helpers/clickOutside";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { getParam, setParam } from "../../helpers/helpers";
import { useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import MarketPlaceAlert from "../../components/marketplace_alert/MarketPlaceAlert";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { set } from "lodash";
import { useTranslation } from "react-i18next";
import MarketPlaceWide from "../../components/MarketplaceWhideScreen/MarketPlaceWide";
import { economicSectors } from "./economicSectors";
import MarketPlaceMobile from "../../components/MarketPlaceMobileScreen/MarketPlaceMobile";
import useDetectScroll from "@smakss/react-scroll-direction";
import CreatePostMarketplace from "../../components/createPostMarketplace";
import SEO from "../../components/SEO/SEO";
import HeaderVisitor from "../../components/HeaderVisitor/HeaderVisitor";
import {
  getAllProductsVisitor,
  getCategoryProductsVisitor,
  getCompanyVisitor,
  getSectorOfferVisitor,
  getSectorProductsVisitor,
  getSectorVisitor,
} from "../../functions/productsVisitor";
import useGeoLocation from "../../hooks/useGeoLocation";
import Cookies from "js-cookie";
import { fetchDynamicRoutes } from "../../functions/fetchDynamicRoutes";
import image from "../../styles/EMPRESY-512x512.png";

export default function MarketPlace({
  user,
  setVisible,
  handleChange,
  setProducts,
  visitor,
}) {
  const { t } = useTranslation();
  const { city, region, country } = useGeoLocation();

  const color = "#65676b";
  const [openCreatePost, setOpenCreatePost] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const productRef = useRef(null);
  const navigate = useNavigate();
  const { type } = useParams();
  const paramSector = getParam("sector");
  const paramCategory = getParam("category");
  const location = useLocation();
  const [sector, setSector] = useState(paramSector ? paramSector : "foryou");
  const [category, setCategory] = useState(
    paramCategory ? paramCategory : null
  );
  const [page, setPage] = useState(1);
  const [more, setMore] = useState(null);
  const [items, setItems] = useState(9);
  const [alert, setAlert] = useState(null);
  const { scrollDir, scrollPosition } = useDetectScroll();
  const [visitorLocation, setVisitorLocation] = useState("");
  const [userLocation, setUserLocation] = useState("");
  const [route, setRoute] = useState(null);

  const [{ loading, error, products }, dispatch] = useReducer(productReducer, {
    loading: false,
    products: [],
    error: "",
  });

  const query770px = useMediaQuery({
    query: "(max-width:770px)",
  });

  const popup = useRef(null);
  useClickOutside(popup, () => {
    setOpenCreatePost(false);
  });

  const handleInViewChange = (inView) => {
    if (inView) {
      showMore();
    }
  };
  const showMore = () => {
    setPage((prev) => prev + 1);
  };

  useEffect(() => {
    if (visitor && (city, region, country)) {
      setVisitorLocation(`${city}, ${region}, ${country}`);
    } else {
      setUserLocation(
        `${user?.location?.city}, ${user?.location?.region}, ${user?.location?.country}`
      );
    }
  }, [city, region, country]);

  useEffect(() => {
    const paramSector = getParam("sector");
    const paramCategory = getParam("category");
    paramSector && setSector(paramSector);
    paramCategory && setCategory(paramCategory);

    // // no param
    // if (!paramSector && !paramCategory) {
    //   setParam("sector", "foryou");
    // }

    if (page === 1) {
      dispatch({
        type: "CLEAR_PRODUCTS",
      });
    }

    // setParam("category", 1);
  }, [location.search]);

  // en el momento, la locacion del usuario esta quedando en los cookies,
  // se toman de los cookies cotra vez y se envia con la funcion para buscar todos los productos,
  //en el momento como no tenemos tantos usuarios no es necesario profundizar en en la ubicacion, y solo se esta utilizando en la funciion para traer los productos

  useEffect(() => {
    const fetchData = async () => {
      if (page === 1) {
        dispatch({
          type: "CLEAR_PRODUCTS",
        });
      }
      try {
        let response;
        let data;
        let updatedProducts = [];

        if (sector !== "foryou" && !category) {
          if (visitor) {
            response = await getSectorVisitor(sector, items, page);
          } else {
            response = await getSector(sector, items, page, user?.token);
          }
          data = await response.data;
          updatedProducts = data?.sectorProducts;
          setMore(data?.totalProducts);
        } else if (sector === "foryou" && !category) {
          if (visitor) {
            response = await getAllProductsVisitor(items, page);
          } else {
            response = await getAllProducts(items, page, user?.token);
          }
          data = await response?.data;
          updatedProducts = data?.product || data?.productss;
          setMore(data?.totalProducts);
        } else if (sector && category === "directory") {
          if (visitor) {
            response = await getCompanyVisitor(sector, items, page);
          } else {
            response = await getCompany(sector, items, page, user?.token);
          }
          data = await response.data;
          updatedProducts = data?.users;
          setMore(data?.totalUsers);
        } else if (
          (sector && category === "products") ||
          category === "services"
        ) {
          if (visitor) {
            response = await getSectorProductsVisitor(
              sector,
              category,
              items,
              page
            );
          } else {
            response = await getSectorProducts(
              sector,
              category,
              items,
              page,
              user?.token
            );
          }
          data = await response.data;
          updatedProducts = data?.usersPosts;
          setMore(data?.totalPosts);
        } else if (sector && category === "offers") {
          if (visitor) {
            response = await getSectorOfferVisitor(
              sector,
              category,
              items,
              page
            );
          } else {
            response = await getSectorOffer(
              sector,
              category,
              items,
              page,
              user?.token
            );
          }
          data = await response.data;
          updatedProducts = data?.usersPosts;
          setMore(data?.totalProducts);
        } else if (category === "myproducts" && !visitor) {
          response = await getMyProducts(items, page, user?.token);
          data = await response?.data;
          updatedProducts = data?.userProducts;
          setMore(data?.totalProducts);
        } else if (
          category === "properties" ||
          category === "franchise" ||
          category === "events" ||
          category === "bussines"
        ) {
          if (visitor) {
            response = await getCategoryProductsVisitor(category, items, page);
          } else {
            response = await getCategoryProducts(
              category,
              items,
              page,
              user?.token
            );
          }
          data = await response.data;
          updatedProducts = data?.categoryProducts;
          setMore(data?.totalProducts);
        }

        dispatch({
          type: "PRODUCT_SUCCESS",
          payload: [...products, ...updatedProducts],
        });
      } catch (err) {
        console.error(err);
        dispatch({
          type: "PRODUCT_ERROR",
        });
      }
    };

    fetchData();
  }, [sector, category, page]);

  const handleSectorChange = (e) => {
    const { name, value } = e.target;
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get("category");

    if (
      param === "myproducts" ||
      param === "franchise" ||
      param === "properties" ||
      param === "events" ||
      param === "bussines"
    ) {
      const url = new URL(window.location.href);
      url.searchParams.delete("category");
      setCategory(null);
      setSector(value);
      url.searchParams.set(name, value);
      setPage(1);
      dispatch({
        type: "CLEAR_PRODUCTS",
      });
      // Replace the current URL with the modified one
      return window.history.replaceState(null, ``, url.href);
    } else {
      setSector(value);
      setParam(name, value);
      setPage(1);
      dispatch({
        type: "CLEAR_PRODUCTS",
      });
    }
  };

  const handleCategoryChange = (categorySelected) => {
    if (!sector || sector === "foryou") {
      setAlert((prev) => !prev);
      return;
    } else if (category === categorySelected) {
      return;
    } else {
      const name = "category";
      setCategory(categorySelected);
      setParam(name, categorySelected);
      setPage(1);
      dispatch({
        type: "CLEAR_PRODUCTS",
      });
    }
  };

  const handleMyProducts = (category) => {
    const name = "category";
    setCategory(category);
    setSector("");
    const url = new URL(window.location.href);
    url.searchParams.delete("sector");
    url.searchParams.set(name, category);
    setPage(1);
    dispatch({
      type: "CLEAR_PRODUCTS",
    });
    return window.history.replaceState(null, ``, url.href);
  };

  const handleOtherCategories = (category) => {
    const name = "category";
    setCategory(category);
    setSector("");
    const url = new URL(window.location.href);
    url.searchParams.delete("sector");
    url.searchParams.set(name, category);
    setPage(1);
    dispatch({
      type: "CLEAR_PRODUCTS",
    });
    return window.history.replaceState(null, ``, url.href);
  };

  // Handle navigation to the homepage and reset sector to "foryou"
  const navigateToHome = () => {
    navigate("/");
    setSector("foryou");
    setCategory(null);
    setPage(1);
    dispatch({
      type: "CLEAR_PRODUCTS",
    });
    window.location.reload();
    const url = new URL(window.location.href);
    url.searchParams.set("sector", "foryou");
    url.searchParams.delete("category");
    window.history.replaceState(null, "", url.href);
  };

  const headerTags = {
    title: "Empresy Marketplace",
    keywords:
      "marketplace, b2b, bc2, proveedor, provider, products,products, servicios, services, local, empresa, bussines",
    titleTemplate: "%s",
    description:
      "Discover a wide range of products and services in our marketplace.",
    type: "application",
    image: { image },
    url: "https://empresy.com",
  };

  //sitemap
  // useEffect(() => {
  //   const fetchRoutes = async () => {
  //     try {
  //       const result = await fetchDynamicRoutes();

  //       setRoute(result);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };
  //   fetchRoutes();
  // }, []);

  // console.log(route);

  return (
    <div className="marketplace">
      {/* <div>
        <SEO headerTags={headerTags} />
      </div> */}
      {visitor ? (
        <HeaderVisitor
          query770px={query770px}
          scrollDir={scrollDir}
          scrollPosition={scrollPosition}
          handleSectorChange={handleSectorChange}
          handleCategoryChange={handleCategoryChange}
          sector={sector}
          handleOtherCategories={handleOtherCategories}
          visitor={visitor}
          visitorLocation={visitorLocation}
          setVisitorLocation={setVisitorLocation}
          category={category}
          navigateToHome={navigateToHome}
          home
        />
      ) : (
        <Header
          user={user}
          navigateToHome={navigateToHome}
          setOpenCreatePost={setOpenCreatePost}
          handleMyProducts={handleMyProducts}
          scrollDir={scrollDir}
          sector={sector}
          handleSectorChange={handleSectorChange}
          home
          query770px={query770px}
          handleCategoryChange={handleCategoryChange}
          handleOtherCategories={handleOtherCategories}
          handleChange={handleChange}
          setProducts={setProducts}
          scrollPosition={scrollPosition}
          userLocation={userLocation}
          setUserLocation={setUserLocation}
        />
      )}

      {query770px ? (
        <MarketPlaceMobile
          handleCategoryChange={handleCategoryChange}
          handleMyProducts={handleMyProducts}
          category={category}
          setOpenCreatePost={setOpenCreatePost}
          sector={sector}
          handleSectorChange={handleSectorChange}
          productRef={productRef}
          handleOtherCategories={handleOtherCategories}
          loading={loading}
          products={products}
          user={user}
          more={more}
          handleInViewChange={handleInViewChange}
          popup={popup}
          t={t}
          openCreatePost={openCreatePost}
          economicSectors={economicSectors}
          visitor={visitor}
          scrollPosition={scrollPosition}
        />
      ) : (
        <MarketPlaceWide
          handleCategoryChange={handleCategoryChange}
          handleMyProducts={handleMyProducts}
          category={category}
          setOpenCreatePost={setOpenCreatePost}
          sector={sector}
          handleSectorChange={handleSectorChange}
          productRef={productRef}
          handleOtherCategories={handleOtherCategories}
          loading={loading}
          products={products}
          user={user}
          more={more}
          handleInViewChange={handleInViewChange}
          popup={popup}
          t={t}
          openCreatePost={openCreatePost}
          visitor={visitor}
        />
      )}
      {openCreatePost ? (
        <CreatePostMarketplace
          setOpenCreatePost={setOpenCreatePost}
          user={user}
          dispatch={dispatch}
          products={products}
        />
      ) : null}
      {alert ? <MarketPlaceAlert setAlert={setAlert} /> : null}
    </div>
  );
}
