export const economicSectors = [
  {
    id: "",
    value: "",
    label: "Sector Economico",
  },
  {
    id: "0",
    value: "Agroindustria, Alimentos y Bebidas",
    label: "Agroindustria, Alimentos y Bebidas",
  },
  {
    id: "1",
    value:
      "Alojamiento, Turismo, Restaurantes, Bares, Servicios De Comidas y Bebidas",
    label: "Alojamiento, Turismo, Restaurantes",
  },
  {
    id: "2",
    value: "Industria Moda, Textiles, Cueros y Accesorios",
    label: "Moda, Textiles, Cueros y Accesorios",
  },
  {
    id: "3",
    value: "Industria Química y Farmacéutica",
    label: "Química y Farmacéutica",
  },
  {
    id: "4",
    value: "Industria Maquinaria, Equipos, Productos Electrónicos",
    label: "Maquinaria, Equipos, Productos Electrónicos",
  },
  {
    id: "5",
    value: "Industria Creativa y Culturales",
    label: "Creativas y Culturales",
  },
  {
    id: "6",
    value: "Industria Automotriz, Vehículos de Transporte",
    label: "Automotriz, Vehículos de Transporte",
  },
  {
    id: "7",
    value: "Industria Mobiliario, Hogar y Decoración",
    label: "Mobiliario, Hogar y Decoración",
  },
  {
    id: "8",
    value: "Industria de Materiales, Empaques, Impresos y Productos Diversos",
    label: "Materiales, Empaques, Impresos y Productos Diversos",
  },
  {
    id: "9",
    value: "Información, Comunicaciones y Servicios Informáticos",
    label: "Tecnologías de la Información y Comunicación",
  },
  {
    id: "10",
    value:
      "Construcción, Infraestructura, Servicios, Gestión De Desechos y Saneamiento",
    label: "Construcción, Infraestructura, Saneamiento",
  },
  {
    id: "11",
    value: "Actividades Inmobiliarias, Financieras y de Seguros",
    label: "Inmobiliarias, Financieras y de Seguros",
  },
  {
    id: "12",
    value: "Servicios de Mantenimiento y Reparación",
    label: "Mantenimiento y Reparación",
  },
  {
    id: "13",
    value: "Logística, Transporte y Almacenamiento",
    label: "Logística, Transporte y Almacenamiento",
  },
  {
    id: "14",
    value: "Comercio Mayorista y Comisión (Excepto Vehículos)",
    label: "Comercio Mayorista y Comisión (Excepto Vehículos)",
  },
  {
    id: "15",
    value: "Comercio Minorista (Excepto Vehículos)",
    label: "Comercio Minorista (Excepto Vehículos)",
  },
  {
    id: "16",
    value: "Servicios de Salud y Asistencia Social",
    label: "Salud y Asistencia Social",
  },
  {
    id: "17",
    value:
      "Actividades Profesionales, Científicas, Técnicas, Servicios Administrativos y de Apoyo",
    label: "Profesionales, Científicos y Técnicos",
  },
  {
    id: "18",
    value: "Educación",
    label: "Educación",
  },
  {
    id: "19",
    value: "Sin Ánimo De Lucro",
    label: "Sin Ánimo De Lucro",
  },
  {
    id: "20",
    value: "Explotación de Minas y Canteras",
    label: "Explotación de Minas y Canteras",
  },
  {
    id: "21",
    value: "Otros",
    label: "Otros",
  },
];
