import React from "react";
import "./style.css";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function ProfileMarketPlaceCard({
  product,
  user,
  visitor,
  visitorProfile,
}) {
  const query770px = useMediaQuery({
    query: "(max-width:770px)",
  });
  // Check if product and images are defined before accessing them
  if (!product || !product?.images || product?.images?.length === 0) {
    return null; // Or return some default content or placeholder
  }
  return (
    <Link
      to={
        visitor
          ? `/product/${product?._id}`
          : visitorProfile
          ? `/product/${product?._id}`
          : `/myproduct/${product?._id}`
        // Add closing parenthesis here
      }
    >
      <div className="profile_marketplace_card">
        <div className="profile_marketplace_card_image">
          <LazyLoadImage src={product?.images[0]?.url} alt="" />
        </div>
        <div className="profile_marketplace_card_infos">
          <div className="profile_marketplace_card_infos_price">
            <span>
              $
              {product?.price
                ? product?.price
                    ?.toString()
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                : "--"}
            </span>
          </div>
          <div className="profile_marketplace_card_infos_description">
            <span>
              {query770px && product?.product_name?.length > 25
                ? product?.product_name?.substring(0, 25) + "..."
                : product?.product_name}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
}
