import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  ArrowIcon,
  ChatIcon,
  CommunityIcon,
  DotsIcon,
  StoryIcon,
} from "../../../svg2";
import { Dots } from "../../../svg";
import MenuSearchHeader from "./MenuSearchHeader";
import { ArrowLeft, ChevronLeft } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function SidebarHeader({ user }) {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="h-[50px] dark:bg-dark_bg_2 flex items-center p16">
      {/*container*/}
      <Link to="/">
        <ChevronLeft
          style={{ cursor: "pointer", borderRadius: "50%" }}
          className="dark:fill-dark_svg_1 hover3"
          fontSize="large"
        />
      </Link>

      <div className="w-full flex items-center justify-between">
        {/*user image*/}
        <button className="btn">
          <img
            src={user.picture}
            alt="empresy-message"
            className="w-full h-full rounded-full object-cover"
          />
        </button>
        {/*user icons*/}
        <ul className="flex items-center gap-x-2 5">
          {/* <li>
            <button className="btn">
              <CommunityIcon className="dark:fill-dark_svg_1" />
            </button>
          </li>
          <li>
            <button className="btn">
              <StoryIcon className="dark:fill-dark_svg_1" />
            </button>
          </li>
          <li>
            <button className="btn">
              <ChatIcon className="dark:fill-dark_svg_1" />
            </button>
          </li> */}
          <li className="relative" onClick={() => setShowMenu((prev) => !prev)}>
            <button className={`btn ${showMenu ? "bg-dark_hover_1" : ""}`}>
              <DotsIcon className="dark:fill-dark_svg_1" />
            </button>
            {showMenu ? <MenuSearchHeader /> : null}
          </li>
        </ul>
      </div>
    </div>
  );
}
