import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function LoadingMaerketPlaceCrad() {
  return (
    <div className="marketplacecard_link">
      <div className="marketplacecard">
        <div className="maketplacecard_img`">
          <Skeleton
            height="220px"
            width="270px"
            containerClassName="avatar-skeleton"
          />
        </div>
        <div className="marketplace_infos">
          <div className="marketplace_price">
            <Skeleton
              height="20px"
              width="100px"
              borderRadius="50px"
              containerClassName="avatar-skeleton"
            />
          </div>
          <div className="marketplace_title">
            <Skeleton
              height="20px"
              width="100px"
              borderRadius="50px"
              containerClassName="avatar-skeleton"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
