import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";
import { useTranslation } from "react-i18next";
import FilterLocation from "../FilterLocation/FilterLocation";
import PulseLoader from "react-spinners/PulseLoader";

export default function Modal({ title, alert, onClick }) {
  return (
    <div className="blurr">
      <div className="location_postBox">
        <div className="location_box_header">
          <div className="location_close_icon" onClick={() => onClick(false)}>
            <CloseIcon />
          </div>
          <span>{title}</span>
        </div>
        <div className="location_container">
          <div className="location_text">
            <span>{alert}</span>
          </div>
          <button
            className="blue_btn"
            style={{ width: "90%" }}
            onClick={() => onClick(false)}
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
}
