export const setParam = (name, value) => {
  const url = new URL(window.location.href);
  url.searchParams.delete("postTab");
  url.searchParams.set(name, value);
  return window.history.replaceState(null, ``, url.href);
};

export const getParam = (name) => {
  const urlParams = new URLSearchParams(window.location.search);
  const param = urlParams.get(name);
  return param ? param : null;
};
