import React from "react";
import products_icon from "../../styles/marketplace_icons/productos-marketplace-empresas-empresy (1).svg";
import offers_icon from "../../styles/marketplace_icons/ofertas-marketplace-empresas-empresy.svg";
import services_icon from "../../styles/marketplace_icons/servicios-marketplace-empresas-empresy (1).svg";
import franchise_icon from "../../styles/marketplace_icons/franquicias-marketplace-empresas-empresy (1).svg";
import property_icon from "../../styles/marketplace_icons/propiedadesmarketplace-empresas-empresy (1).svg";
import event_icon from "../../styles/marketplace_icons/eventos-marketplace-empresas-empresy (1).svg";
import bussines_icon from "../../styles/marketplace_icons/negocios-venta-marketplace-empresas-empresy (1).svg";
import bussines_profile from "../../styles/marketplace_icons/perfil-empresa-empresy (1).svg";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import useEconomicSectors from "../../hooks/useEconomicSectors";

export default function MarketPlaceOptions({
  handleCategoryChange,
  handleMyProducts,
  category,
  setOpenCreatePost,
  sector,
  handleSectorChange,
  productRef,
  handleOtherCategories,
  visitor,
  setVisible,
}) {
  const { t } = useTranslation();
  const economicSectors = useEconomicSectors();
  return (
    <div className="marketplace_options">
      <p>{t("Select-Economic-Sector")}</p>
      <div className="select_marketplace">
        <select
          name="sector"
          id="sector"
          required
          onChange={handleSectorChange}
          value={sector}
          className="select_marketplace_select"
        >
          {economicSectors?.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <p>{t("Select-Category")}:</p>
      <div className="marketplace_options_categories">
        <div
          ref={productRef}
          className={`marketplace_option hover2 ${
            category === "directory" && "active_friends"
          }`}
          onClick={() => {
            handleCategoryChange("directory");
          }}
        >
          <img src={bussines_profile} alt="icon_empresy" />
          <span>{t("Business-Directory")}</span>
        </div>
        <div
          ref={productRef}
          className={`marketplace_option hover2 ${
            category === "products" && "active_friends"
          }`}
          onClick={() => handleCategoryChange("products")}
        >
          <img src={products_icon} alt="icon_empresy" />
          <span>{t("Products")}</span>
        </div>
        <div
          className={`marketplace_option hover2 ${
            category === "offers" && "active_friends"
          }`}
          onClick={() => handleCategoryChange("offers")}
        >
          <img src={offers_icon} alt="icon_empresy" />
          <span>{t("Offers")}</span>
        </div>
        <div
          ref={productRef}
          className={`marketplace_option hover2 ${
            category === "services" && "active_friends"
          }`}
          onClick={() => handleCategoryChange("services")}
        >
          <img src={services_icon} alt="icon_empresy" />
          <span>{t("Services")}</span>
        </div>
        <div style={{ borderBottom: "1px solid lightgray" }}></div>
        <div
          className={`marketplace_option hover2 ${
            category === "franchise" && "active_friends"
          }`}
          onClick={() => handleOtherCategories("franchise")}
        >
          <img src={franchise_icon} alt="icon_empresy" />
          <span>{t("Franchises")}</span>
        </div>
        <div
          className={`marketplace_option hover2 ${
            category === "properties" && "active_friends"
          }`}
          onClick={() => handleOtherCategories("properties")}
        >
          <img src={property_icon} alt="icon_empresy" />
          <span>{t("Properties")}</span>
        </div>
        <div
          className={`marketplace_option hover2 ${
            category === "events" && "active_friends"
          }`}
          onClick={() => handleOtherCategories("events")}
        >
          <img src={event_icon} alt="icon_empresy" />
          <span>{t("Events")}</span>
        </div>
        <div
          className={`marketplace_option hover2 ${
            category === "bussines" && "active_friends"
          }`}
          onClick={() => handleOtherCategories("bussines")}
        >
          <img src={bussines_icon} alt="icon_empresy" />
          <span>{t("Businesses-for-Sale")}</span>
        </div>
      </div>
      {visitor ? null : (
        <>
          <button
            className="add_product_marketplace"
            onClick={() => setOpenCreatePost((prev) => !prev)}
          >
            <AddIcon /> {t("Add-Products")}
          </button>
          <div
            className="my_product_marketplace"
            onClick={() => handleMyProducts("myproducts")}
          >
            {t("My-Products")}
          </div>
        </>
      )}
    </div>
  );
}
