import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Story({ post }) {
  const { user } = useSelector((state) => ({ ...state }));
  return (
    <>
      {user ? (
        <div className="story">
          <Link to={`/profile/${post?.user?.username}`}>
            {" "}
            <img src={post?.images[0].url} alt="" className="story_img" />
            <div className="story_profile_pic">
              <img src={post?.user?.picture} alt="" />
            </div>
            <div className="story_profile_name">{post?.user?.company_Name}</div>
          </Link>
        </div>
      ) : (
        <div className="story">
          <img src={post?.images[0].url} alt="" className="story_img" />
          <div className="story_profile_pic">
            <img src={post?.user?.picture} alt="" />
          </div>
          <div className="story_profile_name">{post?.user?.company_Name}</div>
        </div>
      )}
    </>
  );
}
