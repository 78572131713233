import React from "react";
import { Link } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function MarketPlaceCard({
  product,
  myproducts,
  category,
  visitor,
  user,
}) {
  if (
    category === "" &&
    (!product || product?.images?.length === 0 || product?.type !== null)
  ) {
    return null; // Or return some default content or placeholder
  }

  const myproduct = product?.user?._id === user?.id;

  return (
    <Link
      className="marketplacecard_link"
      to={
        visitor && !category
          ? `/product/${product?._id}`
          : visitor && category === "directory"
          ? `/profile/${product?.username}`
          : visitor && category === "products"
          ? `/product/${product?._id}`
          : visitor && category === "services"
          ? `/product/${product?._id}`
          : visitor && category === "offers"
          ? `/product/${product?._id}`
          : myproducts || category === "myproducts"
          ? `/marketplace/myproduct/${product?._id}`
          : category === "directory"
          ? `/profile/${product?.username}`
          : category === "products" || category === "services"
          ? `/product/${product?._id}`
          : myproduct
          ? `/myproduct/${product?._id}`
          : `/product/${product?._id}`
      }
    >
      <div className="marketplacecard">
        <div className="marketplace_company">
          <LazyLoadImage
            src={
              product?.user?.company_Name
                ? product?.user?.picture
                : category === "products" ||
                  category === "services" ||
                  category === "offers"
                ? product?.user?.picture
                : product?.picture
            }
            alt={`${product?.user?.company_Name}}`}
          />

          <span>
            {product?.user?.company_Name
              ? product?.user?.company_Name
                  .toLowerCase()
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")
              : product?.company_Name
              ? product?.company_Name
              : ""}
          </span>
        </div>

        <div
          className={
            category !== "myproducts"
              ? `maketplacecard_img`
              : `maketplacecard_profile`
          }
        >
          <LazyLoadImage
            src={
              category === "directory" ||
              !product?.images ||
              product?.images?.length === 0
                ? product?.picture
                : product?.images[0]?.url
            }
            alt="empresy_profile"
          />
        </div>
        {category === "directory" ? (
          <>
            <div className="marketplacecard_directory">
              {category === "directory" && product?.company_Name
                ? product?.company_Name
                : null}
            </div>
            <div className="marketplacecard_location">
              {category === "directory" && product?.location ? (
                <span>
                  <LocationOnIcon />
                  {`${product?.location.city}`}
                </span>
              ) : null}
            </div>
          </>
        ) : null}

        <div className="marketplacecard_description">
          {category && product?.details?.bio?.length > 100 ? (
            <>
              {product?.details?.bio?.substring(0, 100)}
              <strong>...ver mas</strong>
            </>
          ) : (
            product?.details?.bio
          )}
        </div>
        <div className="marketplace_infos">
          <div className="marketplace_title">
            <p>
              {!product?.product_name && product?.text
                ? product?.text.substring(0, 20) + "...ver mas"
                : product?.product_name}
            </p>
          </div>
          <div className="marketplace_price">
            {category === "directory" ? null : (
              <span>
                $
                {product?.price
                  ? product?.price
                      ?.toString()
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                  : "--"}
              </span>
            )}
          </div>
          <div className="marketplacecard_product_location">
            {product?.user?.location ? (
              <span>
                <LocationOnIcon />
                {`${product?.user?.location?.city}`}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </Link>
  );
}
