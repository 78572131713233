export default function Messages(setShowMessages) {
  return (
    <div className="mmmenu">
      <div>
        <div className="mmenu_header hover3">Mensajes</div>
        <div className="mmenu_splitter"></div>
        <div className="mmenu_item hover3">
          <span>Pronto tendremos mensajeria entre usuarios</span>
        </div>
      </div>
    </div>
  );
}
