import promo from "../../../styles/icons/empresy-empresa-visible-aqui.webp";
import creame from "../../../styles/icons/creame.png";
import alcaldia from "../../../styles/icons/alcaldiaa.jpeg";
import ruta from "../../../styles/icons/rutann.jpeg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function Sponsor({ loading, loadingg }) {
  return (
    <>
      {loading || loadingg ? (
        <>
          <div className="sponsor">
            <div className="sponsor_img">
              <div>
                <Skeleton
                  height="100px"
                  width="100px"
                  borderRadius="10px"
                  containerClassName="avatar-skeleton"
                  style={{
                    transform: "translateY(1.5px)",
                    transform: "translatex(1.5px)",
                  }}
                />
              </div>
              <div className="sponsor_info">
                <div>
                  <Skeleton
                    height="30px"
                    width="100px"
                    borderRadius="10px"
                    containerClassName="avatar-skeleton"
                    style={{
                      marginLeft: "10px",
                    }}
                  />
                </div>
                <div>
                  <Skeleton
                    height="30px"
                    width="100px"
                    borderRadius="10px"
                    containerClassName="avatar-skeleton"
                    style={{
                      marginLeft: "10px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="sponsor">
            <div className="sponsor_img">
              <div>
                <Skeleton
                  height="100px"
                  width="100px"
                  borderRadius="10px"
                  containerClassName="avatar-skeleton"
                  style={{
                    transform: "translateY(1.5px)",
                    transform: "translatex(1.5px)",
                  }}
                />
              </div>
              <div className="sponsor_info">
                <div>
                  <Skeleton
                    height="30px"
                    width="100px"
                    borderRadius="10px"
                    containerClassName="avatar-skeleton"
                    style={{
                      marginLeft: "10px",
                    }}
                  />
                </div>
                <div>
                  <Skeleton
                    height="30px"
                    width="100px"
                    borderRadius="10px"
                    containerClassName="avatar-skeleton"
                    style={{
                      marginLeft: "10px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="sponsor">
            <div className="sponsor_img">
              <div>
                <Skeleton
                  height="100px"
                  width="100px"
                  borderRadius="10px"
                  containerClassName="avatar-skeleton"
                  style={{
                    transform: "translateY(1.5px)",
                    transform: "translatex(1.5px)",
                  }}
                />
              </div>
              <div className="sponsor_info">
                <div>
                  <Skeleton
                    height="30px"
                    width="100px"
                    borderRadius="10px"
                    containerClassName="avatar-skeleton"
                    style={{
                      marginLeft: "10px",
                    }}
                  />
                </div>
                <div>
                  <Skeleton
                    height="30px"
                    width="100px"
                    borderRadius="10px"
                    containerClassName="avatar-skeleton"
                    style={{
                      marginLeft: "10px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <a href="" target="_blank">
            <div className="sponsor hover2">
              <div className="sponsor_img">
                <img src={promo} alt="empresy sponsor" />
              </div>
              <div className="sponsor_info">
                <span>Tu empresa</span>
                <span>www.tuempresa.com.co</span>
              </div>
            </div>
          </a>

          <a href="" target="_blank">
            <div className="sponsor hover2">
              <div className="sponsor_img">
                <img src={promo} alt="empresy sponsor" />
              </div>
              <div className="sponsor_info">
                <span>Tu empresa</span>
                <span>www.tuempresa.com.co</span>
              </div>
            </div>
          </a>
          <a href="" target="_blank">
            <div className="sponsor hover2">
              <div className="sponsor_img">
                <img src={promo} alt="empresy sponsor" />
              </div>
              <div className="sponsor_info">
                <span>Tu empresa</span>
                <span>www.tuempresa.com.co</span>
              </div>
            </div>
          </a>
        </>
      )}
    </>
  );
}
