import React, { useRef } from "react";
import ImageIcon from "@mui/icons-material/Image";
import CloseIcon from "@mui/icons-material/Close";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export default function ProductImagesPreview({
  images,
  setImages,
  setShowPrev,
  setError,
}) {
  const { t } = useTranslation();

  const imageInputRef = useRef(null);
  const handleImages = (e) => {
    let files = Array.from(e.target.files);
    const currentImageCount = images.length;
    // Check if adding these files will exceed the limit
    if (currentImageCount + files.length > 3) {
      alert("You can only upload up to 3 photos.");
      return;
    }
    files.forEach((img) => {
      if (
        img.type !== "image/jpeg" &&
        img.type !== "image/png" &&
        img.type !== "image/jpg" &&
        img.type !== "image/svg" &&
        img.type !== "image/webp"
      ) {
        setError(
          `The format of the image is not supported, only jpeg, png, jpg, svg and webp.`
        );
        files = files.filter((item) => item.name !== img.name);
        return;
      } else if (img.size > 1024 * 1024 * 5) {
        setError(`The size of the image is too large, max 5MB allowed.`);
        files = files.filter((item) => item.name !== img.name);
        return;
      }
      e.target.value = null;
      const reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = (readerEvent) => {
        setImages((images) => [...images, readerEvent.target.result]);
      };
    });
  };
  return (
    <div className="overflow_a_product scrollbar">
      <div className="add_pics_wrap_product">
        <input
          type="file"
          accept="image/jpeg, image/png, image/jpg, image/svg, image/webp"
          multiple
          hidden
          ref={imageInputRef}
          onChange={handleImages}
        />
        {images && images.length ? (
          <div className="add_pics_inside1 p0 scrollbar">
            <div className="preview_actions">
              {/* <button className="hover1">Edit</button> */}
              <button
                className="hover1"
                onClick={() => {
                  imageInputRef.current.click();
                }}
                disabled={images?.length === 3}
              >
                <i className="add_Photo_icon"></i>
                {t("add-more-photos")}
              </button>
            </div>
            <div
              className="small_white_circle_product"
              onClick={() => {
                setImages([]);
              }}
            >
              <i>
                <CloseIcon />
              </i>
            </div>
            <div
              className={
                images.length === 1
                  ? "preview1"
                  : images.length === 2
                  ? "preview2"
                  : images.length === 3
                  ? "preview3"
                  : images.length === 4
                  ? "preview4"
                  : images.length === 5
                  ? "preview5"
                  : images.length % 2 === 0
                  ? "preview6"
                  : "preview6 singular_grid"
              }
            >
              {images?.map((img, i) => (
                <img src={img} key={i} alt="" />
              ))}
            </div>
          </div>
        ) : (
          <div className="add_pics_inside1_product">
            <div
              className="small_white_circle"
              onClick={() => {
                setShowPrev(false);
              }}
            >
              {/* <i>
                <CloseIcon color="action" />
              </i> */}
            </div>
            <div
              className="add_col"
              onClick={() => {
                imageInputRef.current.click();
              }}
            >
              <div className="add_circle">
                <AddPhotoAlternateIcon />
              </div>
              <span>{t("add-photos")}</span>
              <span></span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
