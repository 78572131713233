import React, { useState } from "react";
import SidebarHeader from "./sidebar-header/sidebarHeader";
import NotificationsSideBar from "./notifications_sidebar/NotificationsSideBar";
import SearchSideBar from "./search_sidebar/SearchSideBar";
import Conversations from "./conversations/conversations";
import SearchResults from "./search_sidebar/SearchResults";
import "./style.css";

export default function Sidebar({
  user,
  conversations,
  setShowMessages,
  onlineUsers,
  typing,
  receiverId,
}) {
  const [searchResults, setSearchResults] = useState([]);

  return (
    <div className="sidebar_container">
      {/*sidebar header*/}
      <SidebarHeader user={user} />
      {/*notifications*/}
      {/* <NotificationsSideBar /> */}
      {/*search*/}
      <SearchSideBar
        searchLength={searchResults.length}
        user={user}
        setSearchResults={setSearchResults}
      />
      {searchResults.length > 0 ? (
        <>
          {/*search results*/}
          <SearchResults
            searchResults={searchResults}
            setSearchResults={setSearchResults}
            setShowMessages={setShowMessages}
            receiverId={receiverId}
          />
        </>
      ) : (
        <>
          {/*conversatios*/}
          <Conversations
            conversations={conversations}
            user={user}
            setShowMessages={setShowMessages}
            onlineUsers={onlineUsers}
            typing={typing}
            receiverId={receiverId}
          />
        </>
      )}
    </div>
  );
}
