import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";
import { useTranslation } from "react-i18next";
import FilterLocation from "../FilterLocation/FilterLocation";
import PulseLoader from "react-spinners/PulseLoader";

export default function ChangeLocationModal({
  onClick,
  location,
  setLocation,
}) {
  const { t } = useTranslation();
  const [locationValue, setLocationValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLocationChange = () => {
    try {
      setLoading(true);
      setLocation(locationValue);
      setLocationValue("");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      onClick((prev) => !prev);
    }
  };

  return (
    <div className="blurr">
      <div className="location_postBox">
        <div className="location_box_header">
          <div
            className="location_close_icon"
            onClick={() => onClick((prev) => !prev)}
          >
            <CloseIcon />
          </div>
          <span>{t("title-change-location")}</span>
        </div>
        <div className="location_container">
          <div className="location_text">
            <span>{t("alert-change-location")}</span>
          </div>
          <FilterLocation
            visitorLocation={location}
            setLocationValue={setLocationValue}
            placeholder={t("title-change-location")}
          />
          <button
            disabled={loading}
            className="blue_btn"
            onClick={() => handleLocationChange()}
            style={{ width: "90%" }}
          >
            {loading ? <PulseLoader color="white" size={3} /> : t("Save")}
          </button>
        </div>
      </div>
    </div>
  );
}
