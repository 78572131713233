import Header from "../../components/header";
import "./style.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import HomeIcon from "@mui/icons-material/Home";
import { useEffect, useReducer } from "react";
import { getFollowers } from "../../functions/user";
import { useSelector } from "react-redux";
import { connectPageReducer } from "../../functions/reducers";
import Card from "./Card";
import { Link, useParams } from "react-router-dom";

export default function Connect() {
  const user = useSelector((state) => ({ ...state }));
  const { type } = useParams();

  const [{ loading, error, data }, dispatch] = useReducer(connectPageReducer, {
    loading: false,
    data: {},
    error: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    dispatch({ type: "CONNECTION_REQUEST" });
    const data = await getFollowers(user.user.token);
    if (data.status === "ok") {
      dispatch({ type: "CONNECTION_SUCCESS", payload: data.data });
    } else {
      dispatch({ type: "CONNECTION_ERROR", payload: data.data });
    }
  };

  return (
    <div className="friends_back">
      <>
        <Header page="connect" user={user.user} />
        <div className="friends">
          <div className="friends_left">
            <div className="friends_left_header">
              <h3>Conecta</h3>
              {/* <div className="small_circle">
                <i className="settings_filled_icon"></i>
              </div> */}
            </div>
            <div className="friends_left_wrap">
              <Link
                to="/connect"
                className={`mmenu_item hover3 ${
                  type === undefined && "active_friends"
                }`}
              >
                <div className="small_circle">
                  <HomeIcon />
                </div>
                <span>Home</span>
                <div className="rArrow">
                  <ChevronRightIcon fontSize="large" />
                </div>
              </Link>
              <Link
                to="/connect/followers"
                className={`mmenu_item hover3 ${
                  type === "followers" && "active_friends"
                }`}
              >
                <div className="small_circle">
                  <FontAwesomeIcon icon={faUsers} size="lg" />
                </div>
                <span>Seguidores</span>
                <div className="rArrow">
                  <ChevronRightIcon fontSize="large" />
                </div>
              </Link>
              <Link
                to="/connect/following"
                className={`mmenu_item hover3 ${
                  type === "following" && "active_friends"
                }`}
              >
                <div className="small_circle">
                  <ConnectWithoutContactIcon />
                </div>
                <span>Siguiendo</span>
                <div className="rArrow">
                  <ChevronRightIcon fontSize="large" />
                </div>
              </Link>
              <Link to="/connect/suggestion" className="mmenu_item hover3">
                <div className="small_circle">
                  <FontAwesomeIcon
                    icon={faGlobe}
                    size="lg"
                    style={{ color: "#07090e" }}
                  />
                </div>
                <span>Sugerencias</span>
                <div className="rArrow">
                  <ChevronRightIcon fontSize="large" />
                </div>
              </Link>
            </div>
          </div>
          <div className="friends_right">
            {(type === undefined || type === "following") && (
              <div className="friends_rigth_wrap">
                <div className="friends_left_header">
                  <h3>Seguidores</h3>
                  {type === undefined && (
                    <Link to="/connect/followers" className="see_link hover3">
                      Ver todo
                    </Link>
                  )}
                </div>
                <div className="flex_wrap">
                  {data?.followers &&
                    data?.followers.map((user) => (
                      <Card userr={user} key={user._id} type="followers" />
                    ))}
                </div>
              </div>
            )}
            {(type === undefined || type === "followers") && (
              <div className="friends_rigth_wrap">
                <div className="friends_left_header">
                  <h3>Siguiendo</h3>
                  {type === undefined && (
                    <Link to="/connect/following" className="see_link hover3">
                      Ver todo
                    </Link>
                  )}
                </div>
                <div className="flex_wrap">
                  {data?.following &&
                    data?.following.map((user) => (
                      <Card userr={user} key={user._id} type="following" />
                    ))}
                </div>
              </div>
            )}
            {(type === undefined || type === "suggestion") && (
              <div className="friends_rigth_wrap">
                <div className="friends_left_header">
                  <h3>Sugerencias</h3>
                  {type === undefined && (
                    <Link to="/connect/suggestion" className="see_link hover3">
                      Ver todo
                    </Link>
                  )}
                </div>
                <div className="flex_wrap">
                  {data?.similarSector &&
                    data?.similarSector.map((user) => (
                      <Card userr={user} key={user._id} type="suggestion" />
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </div>
  );
}
