import { useState } from "react";
import { SendIcon } from "../../../svg2";
import Attachments from "./attachments/Attachments";
import Input from "./Input";
import { sendMessage } from "../../../functions/messages";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import EmojiPickerApp from "./emojiPicker";
import { useRef } from "react";
import "./style.css";
import SocketContext from "../../../context/SocketContext";

function ChatActions({ user, socket }) {
  const [message, setMessage] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [loading, setLoading] = useState(false);
  const textRef = useRef();

  const { activeConversation, status } = useSelector((state) => state.chat);

  const dispatch = useDispatch();

  let convo_id = activeConversation?._id;
  let files = [];

  const sendMessageHandler = async (e) => {
    e.preventDefault();
    if (message.length > 0) {
      setLoading(true);
      dispatch({ type: "SEND_MESSAGES_LOADING" });

      const data = await sendMessage(convo_id, message, files, user.token);

      if (data) {
        dispatch({ type: "SEND_MESSAGE", payload: data });
        setMessage("");
        setLoading(false);
        socket.emit("send message", data);
        socket.emit("stop typing", convo_id);
      } else {
        dispatch({ type: "SEND_MESSAGE_REJECTED", payload: data.data });
      }
    }
  };
  return (
    <form onSubmit={(e) => sendMessageHandler(e)} className="actions_container">
      {/*container*/}
      <div className="w-full flex items-center gap-x-2">
        {/*emojis and atachment*/}
        <ul className=" flex gap-x-2">
          <EmojiPickerApp
            textRef={textRef}
            message={message}
            setMessage={setMessage}
            showPicker={showPicker}
            setShowPicker={setShowPicker}
            setShowAttachments={setShowAttachments}
          />
          {/* <Attachments
            setShowPicker={setShowPicker}
            setShowAttachments={setShowAttachments}
            showAttachments={showAttachments}
          /> */}
        </ul>
        {/*input*/}
        <Input message={message} setMessage={setMessage} textRef={textRef} />
        {/*send button*/}
        <button type="submit" className="btn" disabled={loading}>
          {status === "loading" && loading ? (
            <ClipLoader color="#E9EDEF" size={25} />
          ) : (
            <SendIcon className="dark:fill-dark_svg_1" />
          )}
        </button>
      </div>
    </form>
  );
}

const ChatActionsWithContext = (props) => (
  <SocketContext.Consumer>
    {(socket) => <ChatActions {...props} socket={socket} />}
  </SocketContext.Consumer>
);
export default ChatActionsWithContext;
