import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SearchMenu from "../header/SearchMenu";
import SearchMenuVisitor from "../header/SearchMenuVisitor";
import AllMenu from "../header/AllMenu";
import { useMediaQuery } from "react-responsive";
import useEconomicSectors from "../../hooks/useEconomicSectors";
import { Link, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import products_icon from "../../styles/marketplace_icons/productos-marketplace-empresas-empresy (1).svg";
import offers_icon from "../../styles/marketplace_icons/ofertas-marketplace-empresas-empresy.svg";
import services_icon from "../../styles/marketplace_icons/servicios-marketplace-empresas-empresy (1).svg";
import franchise_icon from "../../styles/marketplace_icons/franquicias-marketplace-empresas-empresy (1).svg";
import property_icon from "../../styles/marketplace_icons/propiedadesmarketplace-empresas-empresy (1).svg";
import event_icon from "../../styles/marketplace_icons/eventos-marketplace-empresas-empresy (1).svg";
import bussines_icon from "../../styles/marketplace_icons/negocios-venta-marketplace-empresas-empresy (1).svg";
import bussines_profile from "../../styles/marketplace_icons/perfil-empresa-empresy (1).svg";
import useClickOutside from "../../helpers/clickOutside";
import { Market, Search, ArrowDown } from "../../svg";
import PlaceIcon from "@mui/icons-material/Place";
import { grey } from "@mui/material/colors";
import FilterLocation from "../FilterLocation/FilterLocation";
import ChangeLocationModal from "../marketplace_change_location/ChangeLocationModal";
import { getParam } from "../../helpers/helpers";

export default function HeaderVisitor({
  visitor,
  query770px,
  scrollDir,
  scrollPosition,
  handleSectorChange,
  handleCategoryChange,
  sector,
  handleOtherCategories,
  visitorLocation,
  setVisitorLocation,
  category,
  navigateToHome,
  home,
}) {
  const { t } = useTranslation();
  const query1040px = useMediaQuery({
    query: "(max-width:1040px)",
  });

  const economicSectors = useEconomicSectors();

  const maxx = query1040px && <img src="../../icons/empresy e.svg" />;
  const color = "#65676b";
  const [showSearchMenu, setshowSearchMenu] = useState(false);
  const [showSearchMenuVisitor, setshowSearchMenuVisitor] = useState(false);
  const [showChangeVisitorLocation, setshowChangeVisitorLocation] =
    useState(false);
  const [showAllMenu, setShowAllMenu] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [showNotifications, setsShowNotifications] = useState(false);
  const [notification, setNotification] = useState();
  const [post, setpost] = useState(false);
  const navigate = useNavigate();
  const allmenu = useRef(null);
  const usermenu = useRef(null);
  const messages = useRef(null);
  const notifications = useRef(null);
  const [optionsState, setOptionsState] = useState("up");
  const [openCategories, setOpenCategories] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    name: t("Select-Category"),
    image: null,
  });
  const [prevScrollPosition, setPrevScrollPosition] = useState(scrollPosition);

  useClickOutside(allmenu, () => {
    setShowAllMenu(false);
  });
  useClickOutside(usermenu, () => {
    setShowUserMenu(false);
  });
  useClickOutside(messages, () => {
    setShowMessages(false);
  });
  useClickOutside(notifications, () => {
    setsShowNotifications(false);
  });

  const handleGoHome = () => {
    navigate("/");
  };

  useEffect(() => {
    const scrollDif = scrollPosition?.bottom - scrollPosition?.top;

    if (scrollDir === "down") {
      setOptionsState(scrollDir);
      setOpenCategories(false);
    } else if (scrollDir === "up" && scrollPosition?.top === 0) {
      setOptionsState(scrollDir);
    }
  }, [scrollDir, scrollPosition]);

  useEffect(() => {
    let displayName, imageSrc;
    switch (category) {
      case "directory":
        displayName = t("Business-Directory");
        imageSrc = bussines_profile;
        break;
      case "products":
        displayName = t("Products");
        imageSrc = products_icon;
        break;
      case "services":
        displayName = t("Services");
        imageSrc = services_icon;
        break;
      case "offers":
        displayName = t("Offers");
        imageSrc = offers_icon;
        break;
      case "franchise":
        displayName = t("Franchise");
        imageSrc = franchise_icon;
        break;
      case "properties":
        displayName = t("Properties");
        imageSrc = property_icon;
        break;
      case "events":
        displayName = t("Events");
        imageSrc = event_icon;
        break;
      case "bussines":
        displayName = t("Businesses-for-Sale");
        imageSrc = bussines_icon;
        break;
      default:
        displayName = t("Select-Category");
        imageSrc = null;
    }

    if (
      category === "directory" ||
      category === "products" ||
      category === "services" ||
      category === "offers"
    ) {
      handleCategoryChange(category);
    } else if (
      category === "franchise" ||
      category === "properties" ||
      category === "events" ||
      category === "bussines"
    ) {
      handleOtherCategories(category);
    } else {
      return;
    }
    setSelectedCategory({ name: displayName, image: imageSrc });
  }, []);

  const handleSelectCategory = (category, displayName, imageSrc) => {
    if (
      category === "directory" ||
      category === "products" ||
      category === "services" ||
      category === "offers"
    ) {
      handleCategoryChange(category);
    } else {
      handleOtherCategories(category);
    }
    setSelectedCategory({ name: displayName, image: imageSrc });
    setOpenCategories(false);
  };

  return (
    <header>
      <div className="header_container" style={{ width: "100%" }}>
        <div className="header_up">
          <div className="header-left">
            <div
              className="header-logo"
              onClick={() => {
                home ? navigateToHome() : handleGoHome();
              }}
            >
              <div>
                {!maxx ? (
                  <div className="circle_2">
                    <img
                      src="../../images/empresy-logo-red-empresas.svg"
                      alt="empresy logo"
                    />
                  </div>
                ) : (
                  <div className="circle">
                    <img
                      className="empresy_peque"
                      src="../../images/e-empresy-logo-red-empresas.svg"
                      alt="empresy logo"
                    />
                  </div>
                )}
              </div>
            </div>

            <div
              className="search search1"
              onClick={() => {
                setshowSearchMenuVisitor(true);
              }}
            >
              <Search color={color} />
              <input
                className="hide_input"
                type="text"
                placeholder={t("search")}
              />
            </div>
            <div
              className="search search1"
              onClick={() => {
                setshowChangeVisitorLocation((prev) => !prev);
              }}
            >
              <PlaceIcon sx={{ color: grey[600] }} />
              <input
                className="hide_input"
                type="text"
                placeholder={t("location")}
                defaultValue={visitorLocation}
                readOnly
              />
            </div>
          </div>
          {showChangeVisitorLocation ? (
            <ChangeLocationModal
              onClick={setshowChangeVisitorLocation}
              location={visitorLocation}
              setLocation={setVisitorLocation}
            />
          ) : null}

          {showSearchMenuVisitor && (
            <SearchMenuVisitor
              color={color}
              setshowSearchMenuVisitor={setshowSearchMenuVisitor}
              t={t}
              //   setVisitorModal={setVisitorModal}
            />
          )}

          {/* // iconos que van en la mitad del header */}
          <div className="header-right">
            <Link to="/login">
              <button className="blue_btn">{t("Log-In")}</button>
            </Link>
          </div>
        </div>
        {visitor && query770px ? (
          <>
            {optionsState === "down" ||
            showUserMenu ||
            showNotifications ? null : (
              <div className="market_place_header_mobile_bottom">
                <>
                  <span>{t("welcome-home-page")}</span>
                  <span style={{ fontWeight: "400" }}>
                    {t("Filter-Economic-Sector")}
                  </span>
                  <div className="select_marketplace_mobile">
                    <select
                      name="sector"
                      id="sector"
                      required
                      onChange={handleSectorChange}
                      value={sector}
                      className="select_marketplace_select_mobile_select"
                      style={{ borderRadius: "20px" }}
                    >
                      {economicSectors?.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                    <span style={{ fontWeight: "400" }}>
                      {t("Select-Category")}
                    </span>
                    <div
                      className="select_marketplace_select_mobile"
                      onClick={() => setOpenCategories((prev) => !prev)}
                    >
                      {selectedCategory.image && (
                        <img
                          src={selectedCategory.image}
                          alt={selectedCategory.name}
                        />
                      )}
                      {selectedCategory.name}
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <KeyboardArrowDownIcon />
                      <div></div>
                    </div>
                  </div>

                  {openCategories ? (
                    <div className="categories_mobile">
                      <div
                        className="category_mobile"
                        onClick={() =>
                          handleSelectCategory(
                            "directory",
                            t("Business-Directory"),
                            bussines_profile
                          )
                        }
                      >
                        <img src={bussines_profile} alt="" />
                        <span>{t("Business-Directory")}</span>
                      </div>
                      <div
                        className="category_mobile"
                        onClick={() =>
                          handleSelectCategory(
                            "products",
                            t("Products"),
                            products_icon
                          )
                        }
                      >
                        <img src={products_icon} alt="" />
                        <span>{t("Products")}</span>
                      </div>
                      <div
                        className="category_mobile"
                        onClick={() =>
                          handleSelectCategory(
                            "services",
                            t("Services"),
                            services_icon
                          )
                        }
                      >
                        <img src={services_icon} alt="" />
                        <span>{t("Services")}</span>
                      </div>
                      <div
                        className="category_mobile"
                        onClick={() =>
                          handleSelectCategory(
                            "offers",
                            t("Offers"),
                            offers_icon
                          )
                        }
                      >
                        <img src={offers_icon} alt="" />
                        <span>{t("Offers")}</span>
                      </div>
                      <div
                        className="category_mobile"
                        onClick={() =>
                          handleSelectCategory(
                            "franchise",
                            t("Franchise"),
                            franchise_icon
                          )
                        }
                      >
                        <img src={franchise_icon} alt="" />
                        <span>{t("Franchise")}</span>
                      </div>
                      <div
                        className="category_mobile"
                        onClick={() =>
                          handleSelectCategory(
                            "properties",
                            t("Properties"),
                            property_icon
                          )
                        }
                      >
                        <img src={property_icon} alt="" />
                        <span>{t("Properties")}</span>
                      </div>
                      <div
                        className="category_mobile"
                        onClick={() =>
                          handleSelectCategory(
                            "events",
                            t("Events"),
                            event_icon
                          )
                        }
                      >
                        <img src={event_icon} alt="" />
                        <span>{t("Events")}</span>
                      </div>
                      <div
                        className="category_mobile"
                        onClick={() =>
                          handleSelectCategory(
                            "bussines",
                            t("Businesses-for-Sale"),
                            bussines_icon
                          )
                        }
                      >
                        <img src={bussines_icon} alt="" />
                        <span>{t("Businesses-for-Sale")}</span>
                      </div>
                    </div>
                  ) : null}
                </>
              </div>
            )}
          </>
        ) : null}
      </div>
    </header>
  );
}
