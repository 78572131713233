import { useTranslation } from "react-i18next";

const useEconomicSectors = () => {
  const { t } = useTranslation();

  return [
    {
      id: "",
      value: "",
      label: t("Select-Economic-Sector"),
    },
    {
      id: "0",
      value: "Agribusiness",
      label: t("Agribusiness"),
    },
    {
      id: "1",
      value: "Food and beverage",
      label: t("food-beverage"),
    },
    {
      id: "2",
      value: "Pets",
      label: t("pets"),
    },
    {
      id: "3",
      value: "Fashion & Beauty",
      label: t("Fashion-Beauty"),
    },
    {
      id: "4",
      value: "Hobbies & Sports",
      label: t("hobbies-sports"),
    },
    {
      id: "5",
      value: "Baby & Kids",
      label: t("baby-kids"),
    },
    {
      id: "6",
      value: "Hospitality",
      label: t("Hospitality"),
    },

    {
      id: "7",
      value: "Chemical Pharmaceutical",
      label: t("Chemical-Pharmaceutical"),
    },
    {
      id: "8",
      value: "Machinery, Equipment and Electronics Industry",
      label: t("Machinery-Equipment-and-Electronics-Industry"),
    },
    {
      id: "9",
      value: "Arts, Entertainment and Recreation",
      label: t("Arts-Entertainment-and-Recreation"),
    },
    {
      id: "10",
      value: "Automotive Industry and Transportation Vehicles",
      label: t("Automotive-Industry-and-Transportation-Vehicles"),
    },
    {
      id: "11",
      value: "Furniture, Home and Decor",
      label: t("Furniture-Home-and-Decor"),
    },
    {
      id: "12",
      value: "Materials, Packaging and Printing Industry",
      label: t("Materials-Packaging-and-Printing-Industry"),
    },
    {
      id: "13",
      value: "Information and Tecnology",
      label: t("Information-and-Tecnology"),
    },
    {
      id: "14",
      value: "Construction, Utilities and Waste Management",
      label: t("Construction-Utilities-and-Waste-Management"),
    },
    {
      id: "15",
      value: "Real Estate, Financial, and Insurance Activities",
      label: t("Real-Estate-Financial-and-Insurance-Activities"),
    },
    {
      id: "16",
      value: "Business Support, Maintenance Services and Workwear",
      label: t("Business-Support-and-Maintenance-Services"),
    },
    {
      id: "17",
      value: "Logistic, Transportation and Warehousing",
      label: t("Logistic-Transportation-and-Warehousing"),
    },
    {
      id: "18",
      value: "Wholesale Trade",
      label: t("Wholesale-Trade"),
    },
    {
      id: "19",
      value: "Retail Trade",
      label: t("Retail-Trade"),
    },
    {
      id: "20",
      value: "Healthcare and Social Assistance",
      label: t("Healthcare-and-Social-Assistance"),
    },
    {
      id: "21",
      value: "Professional, Scientific and Technical Services",
      label: t("Professional-Scientific-and-Technical-Services"),
    },
    {
      id: "22",
      value: "Education",
      label: t("Education"),
    },
    {
      id: "23",
      value: "Non profit Activities",
      label: t("Non-profit-Activities"),
    },
    {
      id: "24",
      value: "Mining and Quarrying",
      label: t("Mining-and-Quarrying"),
    },
  ];
};

export default useEconomicSectors;
