import "./style.css";
import Header from "../../components/header";
import { useEffect, useState } from "react";
import {
  getConversation,
  getOpenConversation,
} from "../../functions/conversations.js";
import Sidebar from "../../components/sidebar/Sidebar";
import EmpresyMessageHome from "../../components/EmpresyChat/EmpresyMessageHome";
import { useDispatch, useSelector } from "react-redux";
import ChatContainer from "../../components/EmpresyChat/ChatContainer";
import "./style.css";
import { useMediaQuery } from "react-responsive";
import SocketContext from "../../context/SocketContext";

function Messages({ setVisible, user, socket }) {
  const { chat } = useSelector((state) => ({ ...state }));
  const { activeConversation } = useSelector((state) => state.chat);
  const [onlineUsers, setOnlineUsers] = useState([]);
  //typing
  const [typing, setTyping] = useState(false);
  //delete
  const [deletedMessage, setDeleteMessage] = useState(false);

  const dispatch = useDispatch();
  const [showMessages, setShowMessages] = useState(false);
  // receiverID from profile message button
  const [receiverId, setReceiverId] = useState(false);
  //join user into the socket
  useEffect(() => {
    socket.emit("join", user.id);
    // get online users
    socket.on("get-online-users", (users) => {
      setOnlineUsers(users);
    });
  }, [user]);

  // listening to receive messages
  useEffect(() => {
    // listen receiveing
    socket.on("receive message", (message) => {
      dispatch({ type: "UPDATE_MESSAGES_AND_CONVERSATIONS", payload: message });
      getData();
    });

    // listen to send
    socket.on("send message", () => {
      getData();
    });

    socket.on("conversation_from_profile", (receiver) => {
      setReceiverId(receiver);
      setShowMessages((prev) => !prev);
    });

    // listen to delete
    socket.on("delete", (message) => {
      setDeleteMessage(message);
      getData();
    });

    // listen to typing
    socket.on("typing", (conversation) => {
      setTyping(conversation);
    });

    //listen to stop typing
    socket.on("stop typing", () => {
      setTyping(false);
    });
  }, [user]);

  useEffect(() => {
    getData();
  }, [user]);

  const getData = async () => {
    dispatch({ type: "CONVERSATIONS_LOADING" });
    const data = await getConversation(user.token);
    if (data) {
      dispatch({ type: "GETCONVERSATION", payload: data });
    } else {
      dispatch({ type: "CONVERSATIONS_REJECTED", payload: data.data });
    }
  };

  useEffect(() => {
    create_conversation_from_outside(receiverId);
  }, [receiverId]);

  const create_conversation_from_outside = async (receiverId) => {
    if (receiverId) {
      dispatch({ type: "CONVERSATIONS_LOADING" });
      const data = await getOpenConversation(receiverId, user.token);

      if (data) {
        dispatch({ type: "GETACTIVECONVERSATION", payload: data });

        socket.emit("join conversation", data._id);
      } else {
        dispatch({ type: "CONVERSATIONS_REJECTED", payload: data.data });
      }
    }
  };

  const query1040px = useMediaQuery({
    query: "(max-width:570px)",
  });

  return (
    <div className="dark">
      {query1040px ? (
        <div className="all-container">
          {/*container*/}
          <div className="container_msg">
            {/*sidebar*/}

            {!activeConversation._id || !showMessages ? (
              <Sidebar
                user={user}
                conversations={chat.conversations}
                setShowMessages={setShowMessages}
                onlineUsers={onlineUsers}
                receiverId={receiverId}
              />
            ) : (
              <ChatContainer
                user={user}
                setShowMessages={setShowMessages}
                onlineUsers={onlineUsers}
                typing={typing}
                deletedMessage={deletedMessage}
                receiverId={receiverId}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="all-container">
          {/*container*/}
          <div className="container_msg">
            {/*sidebar*/}
            <Sidebar
              user={user}
              conversations={chat.conversations}
              onlineUsers={onlineUsers}
              typing={typing}
              receiverId={receiverId}
            />
            {Object.keys(chat?.activeConversation).length === 0 ? (
              <EmpresyMessageHome />
            ) : (
              <ChatContainer
                user={user}
                onlineUsers={onlineUsers}
                typing={typing}
                deletedMessage={deletedMessage}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

const MessagesWithSocket = (props) => (
  <SocketContext.Consumer>
    {(socket) => <Messages {...props} socket={socket} />}
  </SocketContext.Consumer>
);
export default MessagesWithSocket;
