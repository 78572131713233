import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Card({ userr, type }) {
  const { user } = useSelector((state) => ({ ...state }));
  return (
    <div className="req_card">
      <Link to={`/profile/${userr?.username}`}>
        <img src={userr?.picture} alt="empresy_follower" />
      </Link>
      <div className="req_name">{userr?.company_Name}</div>
      {/* {type === "followers" ? (
        <button className="blue_btn">Seguir</button>
      ) : type === "following" ? (
        <button className="blue_btn"> Dejar de Seguir</button>
      ) : type === "suggestion" ? (
        <button className="blue_btn">Seguir</button>
      ) : (
        ""
      )} */}
    </div>
  );
}
