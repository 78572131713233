import "./style.css";
import { useField, ErrorMessage } from "formik";
export default function ProductTextArea({ placeholder, bottom, ...props }) {
  const [field, meta] = useField(props);
  return (
    <div className="input_wrap_text">
      {meta.touched && meta.error && !bottom && (
        <div className="input_error">
          {meta.touched && meta.error && <ErrorMessage name={field.name} />}
        </div>
      )}
      <textarea
        maxLength={props.maxLength}
        className={`textfield_input ${
          meta.touched && meta.error ? "input_error_border" : ""
        }`}
        placeholder={placeholder}
        {...field}
        {...props}
      />
      {meta.touched && meta.error && bottom && (
        <div className="input_error">
          {meta.touched && meta.error && <ErrorMessage name={field.name} />}
        </div>
      )}
      {meta.touched && meta.error && (
        <i className="error_icon" style={{ top: `${!bottom && "45%"}` }}></i>
      )}
    </div>
  );
}
