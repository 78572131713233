import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { profileReducer } from "../../functions/reducers";
import axios from "axios";
import Header from "../../components/header";
import "./style.css";
import Cover from "./Cover";
import ProfilePictureInfos from "./ProfilePictureInfos";
import { useRef } from "react";
import CreatePostPopup from "../../components/createPostPopup";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ProfileMarketPlaceCard from "../../components/profile_marketplace_card/ProfileMarketPlaceCard";
import LoadingMarketPlace from "../../components/loading_marketplace/LoadingMarketPlace";
import ModalMessage from "../../components/modal_message_marketplace/ModalMessage";
import CreatePostMarketplace from "../../components/createPostMarketplace";
import Share from "./Share";
import SEO from "../../components/SEO/SEO";
import HeaderVisitor from "../../components/HeaderVisitor/HeaderVisitor";
import ShareVisitor from "./ShareVisitor";

export default function Profile({ user, handleChange, setVisible, visitor }) {
  const [openCreatePost, setOpenCreatePost] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [share, setShare] = useState(false);
  const [shareVisitor, setShareVisitor] = useState(false);
  const { username } = useParams();
  const navigate = useNavigate();

  const [photos, setPhotos] = useState({});

  var userName = username === undefined ? user.username : username;

  const [{ loading, error, profile }, dispatch] = useReducer(profileReducer, {
    loading: false,
    profile: {},
    error: "",
  });

  //when page loads
  useEffect(() => {
    getProfile();
  }, [userName]);

  useEffect(() => {
    setMessage(profile?.details?.bio);
  }, [profile]);

  var visitorProfile = userName === user?.username ? false : true;
  const [message, setMessage] = useState();
  const path = `${userName}/*`;
  const max = 1;
  const sort = "desc";

  const getProfile = async () => {
    try {
      dispatch({
        type: "PROFILE_REQUEST",
      });
      const { data } = await axios.get(
        visitor
          ? `${process.env.REACT_APP_BACKEND_URL}/getProfileVisitor/${userName}`
          : `${process.env.REACT_APP_BACKEND_URL}/getProfile/${userName}`,
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );

      if (data?.ok === false) {
        navigate("/profile");
      } else {
        try {
          const images = await axios.post(
            visitor
              ? `${process.env.REACT_APP_BACKEND_URL}/listImagesVisitor`
              : `${process.env.REACT_APP_BACKEND_URL}/listImages`,
            { path, sort, max },
            {
              headers: {
                Authorization: `Bearer ${user?.token}`,
              },
            }
          );
          setPhotos(images?.data);
        } catch (error) {
          console.error(error);
        }
        dispatch({
          type: "PROFILE_SUCCESS",
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: "PROFILE_ERROR",
        payload: error.response.data.message,
      });
    }
  };
  const profileTop = useRef(null);
  const [height, setHeight] = useState();
  const leftSide = useRef(null);
  const [scrollHeight, setScrollHeight] = useState();

  useEffect(() => {
    setHeight(profileTop.current.clientHeight + 10);

    window.addEventListener("scroll", getScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", getScroll, { passive: true });
    };
  }, [loading, scrollHeight]);

  const getScroll = () => {
    setScrollHeight(window.pageYOffset);
  };

  useEffect(() => {
    if ((openCreatePost, sendMessage)) {
      document.body.style.overflow = "hidden"; // Prevent scrolling
    } else {
      document.body.style.overflow = "auto"; // Allow scrolling
    }

    return () => {
      document.body.style.overflow = "auto"; // Reset on component unmount
    };
  }, [openCreatePost, sendMessage]);

  const headerTags = {
    title: `${profile?.company_Name} Profile`,
    keywords:
      "marketplace, b2b, bc2, proveedor, provider, products,products, servicios, services, local, empresa, bussines",
    titleTemplate: "%s",
    description: `Check out ${profile?.company_Name}'s e-commerce on Empresy.`,
    type: "application",
    image: `${profile?.picture}`,
    url: `https://empresy.com/profileVisitor/${profile?.username}`,
  };

  return (
    <div className="profile">
      {/* <div>
        <SEO headerTags={headerTags} />
      </div> */}
      {openCreatePost ? (
        <CreatePostMarketplace
          setOpenCreatePost={setOpenCreatePost}
          user={user}
          dispatch={dispatch}
          profile={profile}
        />
      ) : null}
      {visitor ? (
        <HeaderVisitor />
      ) : (
        <Header
          setVisible={setVisible}
          profile={profile}
          user={user}
          setOpenCreatePost={setOpenCreatePost}
          handleChange={handleChange}
        />
      )}
      <div className="profile_top" ref={profileTop}>
        <div className="profile_container_top">
          <div className="profile_box">
            {loading ? (
              <>
                <div className="profile_cover">
                  <Skeleton
                    height="210px"
                    borderRadius="10px"
                    containerClassName="avatar-skeleton"
                  />
                </div>
                <div className="profile_img_wrap">
                  <div className="profile_w_left">
                    <Skeleton
                      height="125px"
                      width="125px"
                      borderRadius="20px"
                      containerClassName="avatar-skeleton"
                      style={{ transform: "translateY(-5rem" }}
                    />
                    <div className="profile_w_col">
                      <div className="profile_name">
                        <Skeleton
                          height="30px"
                          width="300px"
                          borderRadius="20px"
                          containerClassName="avatar-skeleton"
                        />
                      </div>
                      <div className="profile_followers_count">
                        <Skeleton
                          height="20px"
                          width="100px"
                          borderRadius="20px"
                          containerClassName="avatar-skeleton"
                        />
                      </div>
                      <div className="profile_followers_count">
                        <Skeleton
                          height="20px"
                          width="100px"
                          borderRadius="20px"
                          containerClassName="avatar-skeleton"
                        />
                      </div>
                      <div className="profile_description">
                        <Skeleton
                          height="20px"
                          width="100px"
                          borderRadius="20px"
                          containerClassName="avatar-skeleton"
                        />
                      </div>
                      <div className="profile_city">
                        <Skeleton
                          height="20px"
                          width="100px"
                          borderRadius="20px"
                          containerClassName="avatar-skeleton"
                        />
                      </div>
                      <div className="profile_webpage">
                        <Skeleton
                          height="20px"
                          width="100px"
                          borderRadius="20px"
                          containerClassName="avatar-skeleton"
                        />
                      </div>
                      <div className="profile_economic">
                        <Skeleton
                          height="20px"
                          width="200px"
                          borderRadius="20px"
                          containerClassName="avatar-skeleton"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Cover profile={profile} visitor={visitorProfile} user={user} />
                <ProfilePictureInfos
                  followers={profile?.followers}
                  detailss={profile?.details}
                  profile={profile}
                  visitorProfile={visitorProfile}
                  photos={photos?.resources}
                  message={message}
                  setMessage={setMessage}
                  setSendMessage={setSendMessage}
                  setShare={setShare}
                  visitor={visitor}
                  user={user}
                  location={profile?.location}
                  setShareVisitor={setShareVisitor}
                />
              </>
            )}
            {/* <Profilemenu
              visitor={visitor}
              setVisible={setVisible}
              user={user}
              profileT={profile}
              profile
            /> */}
          </div>
        </div>
      </div>
      <div className="profile_bottom">
        <div className="profile_container">
          {loading ? (
            <>
              <LoadingMarketPlace />
            </>
          ) : (
            <>
              <div className="posts">
                {profile?.products && profile?.products?.length > 0 ? (
                  profile?.products
                    .slice(0, 20)
                    .map((product, index) => (
                      <ProfileMarketPlaceCard
                        product={product}
                        user={user}
                        key={index}
                        visitorProfile={visitorProfile}
                        visitor={visitor}
                      />
                    ))
                ) : (
                  <div className="no_post">No products available</div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {share ? <Share setShare={setShare} user={user} /> : null}
      {shareVisitor ? (
        <ShareVisitor setShareVisitor={setShareVisitor} profile={profile} />
      ) : null}
      {sendMessage ? (
        <ModalMessage
          setSendMessage={setSendMessage}
          profilee
          profile={profile}
          user={user}
        />
      ) : null}
    </div>
  );
}
