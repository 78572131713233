import React, { useState } from "react";
import { FilterIcon, ReturnIcon, SearchIcon } from "../../../svg2";
import { searchUserMessenger } from "../../../functions/conversations";
import axios from "axios";
import "./style.css";

export default function SearchSideBar({
  searchLength,
  user,
  setSearchResults,
}) {
  const [show, setShow] = useState(false);
  let cancelRequest = null;

  const handleSearch = async (e) => {
    try {
      const searchTerm = e.target.value;

      // Cancel the previous request if it exists
      if (cancelRequest) {
        cancelRequest.cancel();
      }

      if (searchTerm.trim() === "") {
        setSearchResults([]);
      } else {
        // Create a new CancelToken source for this request
        const source = axios.CancelToken.source();
        cancelRequest = source;

        const res = await searchUserMessenger(searchTerm, user.token, {
          cancelToken: source.token,
        });

        // Check if the request was canceled before updating results
        if (!source.token.reason) {
          setSearchResults(res);
        }
      }
    } catch (error) {
      return error.response.data.error.message;
    }
  };

  return (
    <div className="h-[55px] py-1.5 mt-3">
      {/*container */}
      <div className="px-[10px]">
        {/*search input container*/}{" "}
        <div className="flex items-center gap-x-2">
          <div className="w-full flex dark:bg-dark_bg_2 rounded-lg pl-2">
            {show || searchLength > 0 ? (
              <span
                className="w-8 flex items-center justify-center rotateAnimation cursor-pointer"
                onClick={() => {
                  setSearchResults([]);
                }}
              >
                <ReturnIcon className="fill-green_1 w-5" />
              </span>
            ) : (
              <span className="w-8 flex items-center justify-center">
                <SearchIcon className="dark:fill-dark_svg_2 w-5" />
              </span>
            )}
            <input
              className="messages_search_input"
              type="text"
              placeholder="busca o inicia un nuevo chat"
              onFocus={() => setShow(true)}
              onBlur={() => searchLength === 0 && setShow(false)}
              onChange={(e) => handleSearch(e)}
            />
          </div>
          <button className="btn">
            <FilterIcon className="dark:fill-dark_svg_2" />
          </button>
        </div>
      </div>
    </div>
  );
}
