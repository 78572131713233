import { useEffect, useRef, useState } from "react";
import "./style.css";
import EditIcon from "@mui/icons-material/Edit";
import ProfilePicture from "../../components/ProfilePicture";
import { useSelector } from "react-redux";
import Friendship from "./Friendship";
import "./style.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import axios from "axios";
import EditProfile from "./EditProfile";
import LanguageIcon from "@mui/icons-material/Language";
import BadgeIcon from "@mui/icons-material/Badge";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import ShareIcon from "@mui/icons-material/Share";
import Modal from "../../components/Modal/Modal";

export default function ProfilePictureInfos({
  profile,
  visitorProfile,
  photos,
  detailss,
  message,
  followers,
  setMessage,
  setSendMessage,
  setShare,
  visitor,
  user,
  location,
  setShareVisitor,
}) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const pRef = useRef(null);
  const [details, setDetails] = useState();
  const [visible, setVisible] = useState(false);
  const [showVisitorMessage, setShowVisitorMessage] = useState(false);
  const [userLocationProfile, setUserLocationProfile] = useState(null);

  const query590px = useMediaQuery({
    query: "(max-width:590px)",
  });

  useEffect(() => {
    setDetails(detailss);
    setInfos(detailss);
    setUserLocationProfile(
      `${location?.city}, ${location?.region}, ${location?.country}`
    );
  }, [detailss]);

  const initial = {
    bio: details?.bio ? details?.bio : "",
    webpage: details?.webpage ? details?.webpage : "",
    Economic_Sector: details?.Economic_Sector ? details?.Economic_Sector : "",
    currentCity: details?.currentCity ? details?.currentCity : "",
  };

  const map = {
    city: location?.city ? location?.city : "",
    region: location?.region ? location?.region : "",
    country: location?.country ? location?.country : "",
  };
  const [userLocation, setUserLocation] = useState(map);
  const [infos, setInfos] = useState(initial);

  const updateAbout = async () => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/updateAbout`,
        {
          infos,
          userLocation,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setDetails(data?.details);
      setUserLocationProfile(
        `${data?.location?.city}, ${data?.location?.region}, ${data?.location?.country}`
      );
      setMessage(data.bio);
    } catch (error) {
      console.error(error.response.data.message);
    }
  };

  return (
    <div className="profile_img_wrap">
      {show && (
        <ProfilePicture
          setShow={setShow}
          pRef={pRef}
          photos={photos}
          profile={profile}
        />
      )}
      <div className="profile_w_left">
        {!visitorProfile ? (
          <div className="profile_w_img">
            <div
              className="profile_w_bg"
              ref={pRef}
              onClick={() => setShow((prev) => !prev)}
              style={{
                backgroundSize: "cover",
                backgroundImage: `url(${profile?.picture})`,
              }}
            ></div>
          </div>
        ) : (
          <div className="profile_w_img">
            <div
              className="profile_w_bg"
              style={{
                backgroundSize: "cover",
                backgroundImage: `url(${profile.picture})`,
              }}
            ></div>
          </div>
        )}

        <div className="profile_w_col">
          <div className="profile_name_follows">
            <div className="profile_name">{profile?.company_Name}</div>
            <div className="profile_followers">
              <div className="profile_followers_count">
                {profile?.followers && (
                  <div className="profile_card_count">
                    {profile?.followers?.length === 0
                      ? `${t("followers")}: 0`
                      : profile?.followers?.length === 1
                      ? `${t("followers")}: 1`
                      : `${t("followers")} ${profile?.followers?.length}`}
                  </div>
                )}
              </div>
              <div className="profile_followers_count">
                {profile?.following && (
                  <div className="profile_card_count">
                    {profile?.following?.length === 0
                      ? `${t("following")}: 0`
                      : profile?.following?.length === 1
                      ? `${t("following")}: 1`
                      : `${t("following")} ${profile?.followers?.length}`}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="profile_description">
            <span>
              {message === undefined ? t("description") : `${message}`}
            </span>
          </div>
          <div className="profile_infos">
            <div className="profile_city">
              <LocationOnIcon />
              {!userLocationProfile ? t("city") : userLocationProfile}
            </div>
            <div className="profile_webpage">
              <LanguageIcon />
              {details?.webpage === undefined ? (
                t("webpage")
              ) : (
                <a
                  href={`https://${details?.webpage}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {details?.webpage}
                </a>
              )}
            </div>
            <div className="profile_economic">
              <BadgeIcon />
              {query590px
                ? profile?.Economic_Sector?.substring(0, 25) + "..."
                : profile?.Economic_Sector}
            </div>
          </div>
        </div>
      </div>
      {visitorProfile ? (
        <Friendship
          friendshipp={profile?.friendship}
          profileid={profile?._id}
          setSendMessage={setSendMessage}
          user={user}
          visitor={visitor}
          setShowVisitorMessage={setShowVisitorMessage}
          profile={profile}
          setShare={setShare}
          setShareVisitor={setShareVisitor}
        />
      ) : (
        <div className="profile_w_rigth">
          <div className="grey_btn_profile" onClick={() => setVisible(true)}>
            <EditIcon />
            <span>{t("edit-profile")}</span>
          </div>
          <div
            className="blue_btn_profile"
            onClick={() => setShare((prev) => !prev)}
          >
            <ShareIcon />
            <span>{t("share")}</span>
          </div>
        </div>
      )}
      {visible && (
        <EditProfile
          setVisible={setVisible}
          infos={infos}
          updateAbout={updateAbout}
          details={details}
          setInfos={setInfos}
          userLocation={userLocation}
          setUserLocation={setUserLocation}
        />
      )}
      {showVisitorMessage ? (
        <Modal
          title={`${t("Join-Now")} / ${t("Log-In")}`}
          alert={t("sign-up-message")}
          onClick={setShowVisitorMessage}
        />
      ) : null}
    </div>
  );
}
