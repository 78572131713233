import React, { useEffect, useReducer, useRef, useState } from "react";
import Header from "../../components/header";
import LeftHome from "../../components/home/left";
import RightHome from "../../components/home/right";
import Require from "./Require";
import CreatePost from "../../components/createPost";
import RequirePost from "./RequirePost";
import { getRequirements } from "../../functions/requirement";
import { requirementReducer } from "../../functions/reducers";
import { InView } from "react-intersection-observer";
import { useInView } from "framer-motion";

export default function Searching({ user, setVisible }) {
  const [visibleRequire, setVisibleRequire] = useState(false);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const [count, setCount] = useState(10);

  const handleInViewChange = (inView) => {
    if (inView) {
      showMore();
    }
  };

  const showMore = () => {
    setCount((prev) => prev + 10);
  };

  const [{ loading, error, data }, dispatch] = useReducer(requirementReducer, {
    loading: false,
    data: [],
    error: "",
  });

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    scrollToTop();
  }, []);
  const topPage = useRef();

  const scrollToTop = () => {
    topPage.current.scrollIntoView({ behavior: "auto" });
  };

  const getData = async () => {
    dispatch({ type: "REQUIREMENT_REQUEST" });

    const data = await getRequirements(user.token);
    if (data.status === "ok") {
      dispatch({ type: "REQUIREMENT_SUCCESS", payload: data.data });
    } else {
      dispatch({ type: "REQUIREMENT_ERROR", payload: data.data });
    }
  };

  return (
    <>
      <div ref={topPage}></div>
      <Header user={user} setVisible={setVisible} />
      <LeftHome user={user} />
      <div className="require_middle">
        <CreatePost user={user} setVisibleRequire={setVisibleRequire} />
        {data?.slice(0, count).map((req) => (
          <Require key={req._id} req={req} postss user={user} />
        ))}
        {count < data?.length && (
          <InView as="div" onChange={handleInViewChange}>
            <div
              ref={ref}
              className="view_comments"
              onClick={() => showMore()}
              style={{ margin: "16px" }}
            >
              ver mas posts...
            </div>
          </InView>
        )}
      </div>
      <RightHome />
      {visibleRequire ? (
        <RequirePost
          user={user}
          setVisibleRequire={setVisibleRequire}
          dispatch={dispatch}
          data={data}
        />
      ) : null}
    </>
  );
}
