import React, { useEffect, useMemo, useRef, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import "./style.css";
import Downshift from "downshift";

const libraries = ["places"]; // Define libraries as a static array

export default function AutoComplete({
  setEventLocation,
  placeholder,
  userLocation,
  name,
  handleChangeUserLocation,
}) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (!isLoaded) return <div>Loading...</div>;
  return (
    <Map
      setEventLocation={setEventLocation}
      placeholder={placeholder}
      userLocation={userLocation}
      name={name}
      handleChangeUserLocation={handleChangeUserLocation}
    />
  );
}

function Map({
  setEventLocation,
  placeholder,
  userLocation,
  name,
  handleChangeUserLocation,
}) {
  const center = useMemo(() => ({ lat: 43.45, lng: -80.49 }), []);
  const [selected, setSelected] = useState(null);

  return (
    <>
      <div className="places-container">
        <PlacesAutocomplete
          setSelected={setSelected}
          setEventLocation={setEventLocation}
          placeholder={placeholder}
          userLocation={userLocation}
          name={name}
          handleChangeUserLocation={handleChangeUserLocation}
        />
      </div>
      {/* <GoogleMap
        zoom={10}
        center={center}
        mapContainerClassName="map-container"
      >
        {selected && <Marker position={selected} />}
      </GoogleMap> */}
    </>
  );
}

const PlacesAutocomplete = ({
  setSelected,
  setEventLocation,
  placeholder,
  userLocation,
  name,
  handleChangeUserLocation,
}) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  useEffect(() => {
    if (userLocation) {
      setValue(
        `${userLocation?.city}, ${userLocation?.region}, ${userLocation?.country}` ||
          "",
        false
      );
    }
  }, []);

  const handleSelect = async (address) => {
    setValue(address, false);
    setEventLocation(address);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng });
  };

  const handleTextAreaChange = async (address) => {
    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    const addressComponents = results[0].address_components;
    const formattedAddress = formatAddressComponents(addressComponents);

    handleChangeUserLocation(formattedAddress); // Pass an object containing the updated value and its corresponding name
    setValue(address, false);
    clearSuggestions();
  };

  const formatAddressComponents = (components) => {
    const address = {
      city: "",
      region: "",
      country: "",
    };

    components.forEach((component) => {
      if (component.types.includes("locality")) {
        address.city = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        address.region = component.long_name;
      } else if (component.types.includes("country")) {
        address.country = component.long_name;
      }
    });

    return address;
  };

  return (
    <Downshift
      onChange={(selectedItem) => {
        if (selectedItem) {
          if (userLocation) {
            handleTextAreaChange(selectedItem.description);
          } else {
            handleSelect(selectedItem.description);
          }
        }
      }}
      itemToString={(item) => (item ? item.description : "")}
    >
      {({
        getInputProps,
        getMenuProps,
        getItemProps,
        isOpen,
        inputValue,
        highlightedIndex,
      }) => (
        <div>
          <input
            {...getInputProps({
              value,
              name,
              onChange: (e) => setValue(e.target.value),
              disabled: !ready,
              className: "combobox-input",
              placeholder,
            })}
          />
          <ul
            {...getMenuProps()}
            style={{ position: "absolute", zIndex: "200", background: "white" }}
          >
            {isOpen &&
              status === "OK" &&
              data.map((suggestion, index) => (
                <li
                  key={suggestion.place_id}
                  {...getItemProps({
                    item: suggestion,
                    index,
                    style: {
                      backgroundColor:
                        highlightedIndex === index ? "lightgray" : "white",
                    },
                  })}
                >
                  {suggestion.description}
                </li>
              ))}
          </ul>
        </div>
      )}
    </Downshift>
  );
};
