import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";
import { useTranslation } from "react-i18next";

export default function MarketPlaceAlert({ setAlert }) {
  const { t } = useTranslation();
  return (
    <div className="blurr">
      <div className="alert_postBox">
        <div className="alert_box_header">
          <div
            className="alert_close_icon"
            onClick={() => setAlert((prev) => !prev)}
          >
            <CloseIcon />
          </div>
          <span>{t("Select-Economic-Sector")}</span>
        </div>
        <div className="alert_text">
          <span>{t("alert-marketplace")}</span>
        </div>
      </div>
    </div>
  );
}
