import React from "react";
import { useTranslation } from "react-i18next";

export default function EconomicSectorSelect({
  selectError,
  handleRegisterChange,
}) {
  const { t } = useTranslation();
  return (
    <div className="reg_grid" onChange={handleRegisterChange}>
      <select name="Economic_Sector" required>
        <option>{t("Select-Economic-Sector")}</option>
        <option value={"Agribusiness"}>{t("Agribusiness")}</option>
        <option value={"Food and beverage"}>{t("food-beverage")}</option>
        <option value={"Pets"}>{t("pets")}</option>
        <option value={"Hospitality"}>{t("Hospitality")}</option>
        <option value={"Fashion & Beauty"}>{t("Fashion-Beauty")}</option>
        <option value={"Hobbies & Sports"}>{t("hobbies-sports")}</option>
        <option value={"Baby & Kids"}>{t("baby-kids")}</option>
        <option value={"Chemical Pharmaceutical"}>
          {t("Chemical-Pharmaceutical")}
        </option>
        <option value={"Machinery, Equipment and Electronics Industry"}>
          {t("Machinery-Equipment-and-Electronics-Industry")}
        </option>
        <option value={"Arts, Entertainment and Recreation"}>
          {t("Arts-Entertainment-and-Recreation")}
        </option>
        <option value={"Automotive Industry and Transportation Vehicles"}>
          {t("Automotive-Industry-and-Transportation-Vehicles")}
        </option>
        <option value={"Furniture and Home and Decor"}>
          {t("Furniture-Home-and-Decor")}
        </option>
        <option value={"Materials, Packaging and Printing Industry"}>
          {t("Materials-Packaging-and-Printing-Industry")}
        </option>
        <option value={"Information and Tecnology"}>
          {t("Information-and-Tecnology")}
        </option>
        <option value={"Construction, Utilities and Waste Management"}>
          {t("Construction-Utilities-and-Waste-Management")}
        </option>
        <option value={"Real Estate, Financial, and Insurance Activities"}>
          {t("Real-Estate-Financial-and-Insurance-Activities")}
        </option>
        <option value={"Business Support, Maintenance Services and Workwear"}>
          {t("Business-Support-and-Maintenance-Services")}
        </option>
        <option value={"Logistic, Transportation and Warehousing"}>
          {t("Logistic-Transportation-and-Warehousing")}
        </option>
        <option value={"Wholesale Trade"}>{t("Wholesale-Trade")}</option>
        <option value={"Retail Trade"}>{t("Retail-Trade")}</option>
        <option value={"Healthcare and Social Assistance"}>
          {t("Healthcare-and-Social-Assistance")}
        </option>
        <option value={"Professional, Scientific and Technical Services"}>
          {t("Professional-Scientific-and-Technical-Services")}
        </option>
        <option value={"Education"}>{t("Education")}</option>
        <option value={"Non-profit Activities"}>
          {t("Non-profit-Activities")}
        </option>
        <option value={"Mining and Quarrying"}>
          {t("Mining-and-Quarrying")}
        </option>
      </select>
      {selectError && <div className="input_error">{selectError}</div>}
    </div>
  );
}
