export function postsReducer(state, action) {
  switch (action.type) {
    case "POST_REQUEST":
      return { ...state, loading: true, error: "" };
    case "POST_SUCCESS":
      return { ...state, loading: false, posts: action.payload, error: "" };

    case "POST_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
export function productReducer(state, action) {
  switch (action.type) {
    case "PRODUCT_REQUEST":
      return { ...state, loading: true, error: "" };

    case "CLEAR_PRODUCTS":
      return {
        ...state,
        loading: true,
        products: [],
      };
    case "PRODUCT_SUCCESS":
      return {
        ...state,
        loading: false,
        products: action.payload,
        error: "",
      };
    case "PRODUCT_POSTS":
      return {
        loading: false,
        products: action.payload,
        error: "",
      };
    case "PRODUCT_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
export function profileReducer(state, action) {
  switch (action.type) {
    case "PROFILE_REQUEST":
      return { ...state, loading: true, error: "" };
    case "PROFILE_SUCCESS":
      return { ...state, loading: false, profile: action.payload, error: "" };
    case "PROFILE_POSTS":
      return {
        loading: false,
        profile: { ...state.profile, post: action.payload },
        error: "",
      };
    case "PROFILE_PRODUCTS":
      return {
        loading: false,
        profile: { ...state.profile, products: action.payload },
        error: "",
      };
    case "PROFILE_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export function photosReducer(state, action) {
  switch (action.type) {
    case "PHOTOS_REQUEST":
      return { ...state, loading: true, error: "" };
    case "PHOTOS_SUCCESS":
      return { ...state, loading: false, photos: action.payload, error: "" };
    case "PHOTOS_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export function connectPageReducer(state, action) {
  switch (action.type) {
    case "CONNECTION_REQUEST":
      return { ...state, loading: true, error: "" };
    case "CONNECTION_SUCCESS":
      return { ...state, loading: false, data: action.payload, error: "" };
    case "CONNECTION_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export function notificationReducer(state, action) {
  switch (action.type) {
    case "NOTIFICATION_REQUEST":
      return { ...state, loading: true, error: "" };
    case "NOTIFICATION_SUCCESS":
      return { ...state, loading: false, data: action.payload, error: "" };
    case "NOTIFICATION_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export function savedPostReducer(state, action) {
  switch (action.type) {
    case "SAVEDPOSTS_REQUEST":
      return { ...state, loading: true, error: "" };
    case "SAVEDPOSTS_SUCCESS":
      return { ...state, loading: false, data: action.payload, error: "" };
    case "SAVEDPOSTS_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export function requirementReducer(state, action) {
  switch (action.type) {
    case "REQUIREMENT_REQUEST":
      return { ...state, loading: true, error: "" };
    case "REQUIREMENT_SUCCESS":
      return { ...state, loading: false, data: action.payload, error: "" };
    case "REQUIREMENT_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
