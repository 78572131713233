import React, { useEffect, useState } from "react";
import "./styles.css";
import CreatePostMarketplace from "../createPostMarketplace";
import FadeLoader from "react-spinners/FadeLoader";
import ProfileMarketPlaceCard from "../profile_marketplace_card/ProfileMarketPlaceCard";
import MarketPlaceCard from "../../pages/marketplace/MarketPlaceCard";
import { useInView } from "react-intersection-observer";
import { InView } from "react-intersection-observer";

export default function MarketPlaceMobile({
  category,
  loading,
  products,
  user,
  more,
  handleInViewChange,
  t,
  visitor,
  scrollPosition,
}) {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.1,
  });
  const [isPagePushedDown, setIsPagePushedDown] = useState(false);

  useEffect(() => {
    if (scrollPosition?.top === 0) {
      setIsPagePushedDown(true);
    } else {
      setIsPagePushedDown(false);
    }
  }, [scrollPosition?.top, isPagePushedDown]);

  return (
    <div
      className="marketplace_content_mobile"
      style={{ marginTop: isPagePushedDown ? "280px" : "76px" }}
    >
      {loading ? (
        <FadeLoader style={{ position: "relative", left: "50%", top: "50%" }} />
      ) : (
        <>
          {products?.length > 0 ? (
            products?.map((product, index) =>
              category === "myproducts" ? (
                <ProfileMarketPlaceCard
                  product={product}
                  user={user}
                  key={index}
                  ref={ref}
                />
              ) : (
                <MarketPlaceCard
                  key={index}
                  product={product}
                  category={category}
                  visitor={visitor}
                  user={user}
                />
              )
            )
          ) : (
            <h1> {t("no-products")}</h1>
          )}
          {products?.length > 0 && more !== products?.length && (
            <InView onChange={handleInViewChange}>
              <div>
                <FadeLoader />
              </div>
            </InView>
          )}
          <div style={{ height: "10px" }}></div>
        </>
      )}
    </div>
  );
}
