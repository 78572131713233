import React, { useRef, useState } from "react";
import "./style.css";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import useClickOutside from "../../helpers/clickOutside";
import PostMenu from "../../components/post/PostMenu";
import RequireMenu from "./RequireMenu";

export default function ({ req, user, post }) {
  const [showMenu, setShowMenu] = useState(false);
  const [checkSaved, setCheckSaved] = useState();

  const postmenu = useRef(null);
  const reqR = useRef(null);

  useClickOutside(postmenu, () => {
    setShowMenu(false);
  });

  return (
    <div className="container_requirement" ref={reqR}>
      <div className="requirement_infos">
        <div className="require_text_date">
          <p className="require_text">Requieren</p>
          <Moment className="require_date" fromNow interval={30}>
            {req.createdAt}
          </Moment>
        </div>
        <div className="require_menu_right hover3">
          <div ref={postmenu}>
            <div
              onClick={() => {
                setShowMenu((prev) => !prev);
              }}
            >
              <MoreHorizIcon color="action" />
            </div>

            {showMenu && (
              <RequireMenu
                userId={user.id}
                reqUserId={req.user._id}
                reqId={req._id}
                token={user.token}
                reqR={reqR}
              />
            )}
          </div>
        </div>
      </div>

      <Link to={`/profile/${req.user.username}`}>
        <div className="requirement_info_user">
          <img className="requirement_img" src={req.user.picture} />
          <div className="requirement_user_infos">
            <div className="requirement_user_name">{req.user.company_Name}</div>
            <div className="requirement_user_economic">
              {req.user.Economic_Sector}
            </div>
          </div>
        </div>
      </Link>
      <div className="requirement_message">{req.text}</div>
    </div>
  );
}
