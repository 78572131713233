import moment from "moment";
import TraingleIcon from "../../../svg2/triangle";
import { SyncLoader } from "react-spinners";

export default function Typing({ message }) {
  return (
    <div className={`w-full flex mt-2 space-x-3 max-w-xs `}>
      {/*Message container*/}
      <div>
        <div
          className={`relative h-full dark:text-dark_text_1 p-2 rounded-lg dark:bg-dark_bg_2`}
        >
          {/*message*/}

          <p className="float-left h-full text-sm pb-4 pr-8 text-white">
            <SyncLoader color="gray" size={4} speedMultiplier="0.5" />
          </p>
          {/*message date*/}

          {/*triangle*/}
        </div>
      </div>
    </div>
  );
}
