import React from "react";

export default function Description({
  infos,
  handleChange,
  max,
  updateAbout,
  name,
  detail,
  placeholder,
}) {
  const handleTextAreaChange = (e) => {
    const { name, value } = e.target;
    handleChange({ [name]: value }); // Pass an object containing the updated value and its corresponding name
  };
  return (
    <div className="add_bio_wrap">
      <textarea
        placeholder={placeholder}
        name={name}
        value={infos?.[name]}
        maxLength="200"
        className="textarea_blue details_input"
        onChange={handleTextAreaChange}
      ></textarea>
      {!detail && <div className="remaining">{max} caracteres restantes</div>}
    </div>
  );
}
