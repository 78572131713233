import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import { Link } from "react-router-dom";
import logoW from "../../../icons/Digital_Glyph_Green.png";

export default function HelpSupport({ setVisible }) {
  return (
    <div className="absolute_wrap">
      <div className="absolute_wrap_header">
        <div
          className="circle hover1"
          onClick={() => {
            setVisible(0);
          }}
        >
          <ChevronLeftIcon fontSize="large" />
        </div>
        Soporte
      </div>
      <Link
        to="https://api.whatsapp.com/send?phone=573136260281"
        className="hover1"
        style={{ margin: "0 0 0 0" }}
        target="_blank"
      >
        <div className="mmenu_item hover3">
          <div style={{ margin: "10px" }}>
            <img src={logoW} style={{ width: "25px", height: "25px" }} />
          </div>
          <span>Chat en vivo</span>
        </div>
      </Link>
    </div>
  );
}
