import React from "react";
import { Formik, Form } from "formik";
import LoginInput from "../../components/inputs/logininput";
import { Link } from "react-router-dom";
import * as Yup from "yup"; /*nos va a ayudar a hacer las validaciones */
import axios from "axios";

export default function SearchAccount({
  email,
  setEmail,
  error,
  setLoading,
  setError,
  setUserInfos,
  setVisible,
}) {
  /*funcion que realiza la validacdion*/
  const validateEmail = Yup.object({
    email: Yup.string()
      .required("Email adress is required")
      .email("Must be a valid email address")
      .max(60, "Email adress can`t have more than 60 characters"),
  });
  const handleSearch = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/findUser`,
        { email }
      );
      setUserInfos(data);
      setVisible(1);
      setError("");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
    }
  };
  return (
    <div className="reset_form">
      <div className="reset_form_header">Olvidaste tu Contraseña?</div>
      <div className="reset_form_text">
        Porfavor ingresa tu email para recuperar tu contraseña.
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          email,
        }}
        validationSchema={
          validateEmail
        } /*para hacer las validaciones de si lo intserado en el input es valido*/
        onSubmit={() => {
          handleSearch();
        }} /*se crea la funcion para hacer la busqueda del usuario y hacer reset passord*/
      >
        {(formik) => (
          <Form>
            <LoginInput
              type="text"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Ingresa tu correo electronico"
            />
            {error && <div className="error_text">{error}</div>}
            <div className="reset_form_btns">
              <Link to="/login" className="grey_btn">
                Volver
              </Link>
              <button className="blue_btn">Buscar</button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
