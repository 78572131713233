import { useSelector } from "react-redux";
import Message from "./Message";
import { useEffect, useRef, useState } from "react";
import "./style.css";
import Typing from "./Typing";

export default function ChatMessages({ user, typing, deletedMessage }) {
  const { messages } = useSelector((state) => state.chat);
  const endRef = useRef();

  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  useEffect(() => {
    scrollToBottom();
  }, [typing]);

  const scrollToBottom = () => {
    endRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="messages_container_1">
      {/*container*/}
      <div className="scrollbar overflow_scrollbar overflow-auto py-3 px-[9%]">
        {/*messages*/}
        {messages &&
          messages?.map((message) => (
            <Message
              message={message}
              key={message._id}
              me={user.id === message.sender._id}
              user={user}
              deletedMessage={deletedMessage}
            />
          ))}

        {typing === messages[0]?.conversation?._id ? (
          <div>
            <Typing />
          </div>
        ) : null}

        <div ref={endRef}></div>
      </div>
    </div>
  );
}
