import React, { useEffect, useRef, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Header from "../../components/header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { deleteProduct, getProductById } from "../../functions/products";
import FadeLoader from "react-spinners/FadeLoader";
import "./style.css";
import SocketContext from "../../context/SocketContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getPost } from "../../functions/post";
import ModalMessage from "../../components/modal_message_marketplace/ModalMessage";
import SEO from "../../components/SEO/SEO";
import { getProductByIdVisitor } from "../../functions/productsVisitor";
import HeaderVisitor from "../../components/HeaderVisitor/HeaderVisitor";
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal/Modal";
import { useMediaQuery } from "react-responsive";
import CloseIcon from "@mui/icons-material/Close";
import { grey } from "@mui/material/colors";
import { LazyLoadImage } from "react-lazy-load-image-component";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ShareIcon from "@mui/icons-material/Share";
import Share from "../profile/Share";
import ShareProduct from "../../components/shareproduc/ShareProduct";

function ProductScreen({
  user,
  setVisible,
  socket,
  myproducts,
  handleChange,
  visitor,
}) {
  const { id: productId } = useParams();
  const [openMessage, setOpenMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [showVisitorMessage, setShowVisitorMessage] = useState(false);
  const [share, setShare] = useState(false);
  const { t } = useTranslation();
  const url = `https://www.empresy.com/products/${productId}`;

  useEffect(() => {
    setLoading((prev) => !prev);
    const fetchProduct = async () => {
      try {
        if (visitor) {
          const productRequested = await getProductByIdVisitor(productId);
          setProduct(productRequested);
          if (productRequested?.data === null) {
            const productRequested = await getPost(productId, user.token);
            setProduct(productRequested);
          }
        } else {
          const productRequested = await getProductById(productId, user.token);
          setProduct(productRequested);
          if (productRequested?.data === null) {
            const productRequested = await getPost(productId, user.token);
            setProduct(productRequested);
          }
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoading((prev) => !prev);
      }
    };

    fetchProduct();
  }, [productId]);

  const query420px = useMediaQuery({
    query: "(max-width:420px)",
  });

  const handleOpenConversation = async () => {
    setOpenMessage((prev) => !prev);
  };

  const settings = {
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: true,
    swipeToSlide: true,
    lazyLoad: true,

    responsive: [
      {
        breakpoint: 770,
        settings: {
          dots: true,
        },
      },
    ],
  };
  const slider = React.useRef(null);

  const handleDeleteProduct = async (productId) => {
    setLoading((prev) => !prev);
    try {
      const response = await deleteProduct(productId, user.token);
    } catch (error) {
      console.error("Error deleting product:", error);
    } finally {
      setLoading((prev) => !prev);
      window.history.back();
    }
  };

  const headerTags = {
    title: product?.data?.product_name,
    keywords:
      "marketplace, b2b, bc2, proveedor, provider, products,products, servicios, services, local, empresa, bussines",
    titleTemplate: "%s",
    description: product?.data?.description,
    type: "application",
    image: product?.data?.images[0]?.url,
    url: `https://www.empresy.com/product/${productId}`,
  };

  return (
    <div className="product_screen">
      {/* <div>
        <SEO headerTags={headerTags} />
      </div> */}
      {visitor ? (
        <HeaderVisitor />
      ) : (
        <Header
          user={user}
          setVisible={setVisible}
          handleChange={handleChange}
        />
      )}
      {query420px ? (
        <Link
          to={myproducts && `/myproducts`}
          onClick={() => window.history.back()}
          className="close_icon_product"
        >
          <CloseIcon sx={{ color: grey[50] }} />
        </Link>
      ) : (
        <Link
          to={myproducts && `/myproducts`}
          onClick={() => window.history.back()}
          className="arrow_back"
        >
          <ArrowBackIcon />
        </Link>
      )}

      {loading ? (
        <FadeLoader />
      ) : (
        <div className="product_screen_container">
          {product?.data?.images?.length > 1 ? (
            <div className="slider-container_slick_product">
              <div className="container_slider_slick_product">
                <Slider ref={slider} {...settings}>
                  {product?.data?.images?.map((image, index) => (
                    <div key={index}>
                      <div className="img-body_product">
                        <LazyLoadImage src={image?.url} alt={image?.alt} />
                      </div>
                    </div>
                  ))}
                </Slider>
                <div className="container_slider_slick_product_buttons">
                  <button
                    onClick={() => slider?.current?.slickPrev()}
                    className="container_slider_slick_product_button"
                  >
                    <ArrowBackIosNewIcon />
                  </button>
                  <button
                    onClick={() => slider?.current?.slickNext()}
                    className="container_slider_slick_product_button"
                  >
                    <ArrowForwardIosIcon />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <LazyLoadImage
              src={product?.data?.images[0]?.url}
              alt=""
              className="product_screen_container_img"
            />
          )}

          <div className="product_screen_infos">
            <div className="product_screen_anouncer">
              <span className="product_screen_anouncer_span">
                {t("anouncer")}
              </span>
              <Link
                to={
                  visitor
                    ? `/profile/${product?.data?.user?.username}`
                    : `/profile/${product?.data?.user?.username}`
                }
                className="product_screen_anouncer_info"
              >
                <img src={product?.data?.user?.picture} />
                <div>{product?.data?.user?.company_Name}</div>
              </Link>
            </div>
            <div className="product_screen_name">
              {product?.data?.product_name}
            </div>
            {product?.data?.price ? (
              <div className="product_screen_price">
                $
                {product?.data?.price
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </div>
            ) : (
              "$--"
            )}

            <div className="product_screen_description">
              {!product?.data?.description
                ? product?.data?.text
                : product?.data?.description}
            </div>
            {myproducts ? (
              <>
                <button
                  className="add_product_marketplace_delete"
                  onClick={() => {
                    handleDeleteProduct(productId);
                  }}
                >
                  {t("delete")}
                </button>
              </>
            ) : (
              <div className="product_buttons">
                <a
                  href={`https://api.whatsapp.com/send?phone=57${
                    product?.data?.user?.phone_number
                  }&text=Hola%20te%20vi%20en%20empresy.com%20quisiera%20conocer%20este%20producto!%20${encodeURIComponent(
                    url
                  )}`}
                  className="button green_btn"
                  style={{ margin: "0 0 0 0" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WhatsAppIcon />
                  <span>Whatsapp</span>
                </a>

                <div
                  className="button blue_btn"
                  onClick={() => setShare((prev) => !prev)}
                >
                  <ShareIcon />
                  <span>{t("share")}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {openMessage ? (
        <ModalMessage
          setOpenMessage={setOpenMessage}
          product={product}
          // user={user}
        />
      ) : null}
      {showVisitorMessage ? (
        <Modal
          title={`${t("Join-Now")} / ${t("Log-In")}`}
          alert={t("sign-up-message")}
          onClick={setShowVisitorMessage}
        />
      ) : null}
      {share ? (
        <ShareProduct setShare={setShare} productId={productId} />
      ) : null}
    </div>
  );
}
const ProductScreenWithContext = (props) => (
  <SocketContext.Consumer>
    {(socket) => <ProductScreen {...props} socket={socket} />}
  </SocketContext.Consumer>
);
export default ProductScreenWithContext;
