import { useRef, useState } from "react";
import "./style.css";
import UpdateProfilePicture from "./UpdateProfilePicture";
import useClickOutside from "../../helpers/clickOutside";
import { photosReducer } from "../../functions/reducers";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";

export default function ProfilePicture({
  setShow,
  pRef,
  photos,
  profile,
  username,
}) {
  const { t } = useTranslation();
  const popup = useRef(null);
  useClickOutside(popup, () => {
    setShow(false);
  });
  const [error, setError] = useState("");
  const [image, setImage] = useState("");
  const refInput = useRef(null);
  const { user } = useSelector((state) => ({ ...state }));

  const handleImage = (e) => {
    let file = e.target.files[0];
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/webp"
    ) {
      setError(`The format of the file is not supported.`);
      return;
    } else if (file.size > 1024 * 1024 * 5) {
      setError("The size of the file is to large");
      return;
    }
    e.target.value = null;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      setImage(event.target.result);
    };
  };

  return (
    <div className="blurr">
      <input
        type="file"
        ref={refInput}
        hidden
        onChange={handleImage}
        accept="image/jpeg,image/png,image/webp,image/jfif,"
      />
      {/* implementar click outside */}
      <div className="postBox pictureBox">
        <div className="box_header">
          <div className="small_circle" onClick={() => setShow(false)}>
            <i className="exit_icon"></i>
          </div>
          <span>{t("profile-picture")}</span>
        </div>
        <div className="update_picture_wrap">
          <div className="update_picture_buttons">
            <button
              className="blue_btn"
              onClick={() => {
                refInput.current.click();
              }}
              style={{ width: "80%" }}
            >
              <i className="plus_icon"></i>
              {t("add-new")}
            </button>
            {/* <button
              className="grey_btn"
              style={{ background: "#ced0d4", color: "black" }}
            >
              <i>
                <EditIcon />
              </i>
              Edit Photo
            </button> */}
          </div>
        </div>
        {error && (
          <div className="postError comment_error">
            <div className="postError_error">{error}</div>
            <button
              className="blue_btn"
              onClick={() => {
                setError("");
              }}
            >
              Try again
            </button>
          </div>
        )}
        <div className="old_pictures_wrap">
          <h4></h4>
          <div className="old_pictures">
            {photos
              .filter(
                (img) => img?.folder === `${user.username}/profile_pictures`
              )
              .map((photo) => (
                <img src={photo.secure_url} key={photo.public_id} />
              ))}
          </div>
        </div>
      </div>
      {image && (
        <UpdateProfilePicture
          setImage={setImage}
          image={image}
          setError={setError}
          setShow={setShow}
          pRef={pRef}
        />
      )}
    </div>
  );
}
