import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import PulseLoader from "react-spinners/PulseLoader";
import "./style.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ProductInput from "../inputs/productinput/ProductInput";
import ProductTextArea from "../inputs/productTextArea/ProductTextArea";
import ProductImagesPreview from "./ProductImagesPreview";
import { createProduct } from "../../functions/products";
import dataURIToBlob from "../../helpers/DataUrlToBlob";
import { uploadImages } from "../../functions/uploadImages";
import product_image from "../../icons/productos-marketplace-empresas-empresy (1).svg";
import offer_image from "../../icons/ofertas-marketplace-empresas-empresy.svg";
import services_image from "../../icons/servicios-marketplace-empresas-empresy (1).svg";
import franchise_image from "../../icons/franquicias-marketplace-empresas-empresy (1).svg";
import property_image from "../../icons/propiedadesmarketplace-empresas-empresy (1).svg";
import bussines_image from "../../icons/negocios-venta-marketplace-empresas-empresy (1).svg";
import event_image from "../../icons/eventos-marketplace-empresas-empresy (1).svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AutoComplete from "../google_autocomplete/AutoComplete";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { set } from "lodash";
import Modal from "../Modal/Modal";

export default function CreatePostMarketplace({
  setVisible,
  popup,
  user,
  products,
  dispatch,
  setOpenCreatePost,
  profile,
}) {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(null);
  const [error, setError] = useState(null);
  const [images, setImages] = useState([]);
  const [propertyType, setPropertyType] = useState(""); // 'rent' or 'sale'
  const [date, setDate] = useState(null);
  const [eventLocation, setEventLocation] = useState("");
  const [sameUser, setSameUser] = useState(false);
  const [myProducts, setMyProducts] = useState(null);
  const [home, setHome] = useState(null);
  const { t } = useTranslation();

  const InputInfos = {
    product_name: "",
    price: "",
    description: "",
    Economic_Sector: "",
    property: "",
  };
  const [product, setProduct] = useState(InputInfos);
  const { product_name, price, description, Economic_Sector, property } =
    product;

  const [openAlertMessage, setOpenAlertMessage] = useState(false);

  useEffect(() => {
    // Parse the username from the pathname
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get("sector");

    if (param === "foryou") {
      setHome(true);
    } else {
      setHome(false);
    }
  }, [location, user, setOpenCreatePost]);

  useEffect(() => {
    // Parse the username from the pathname
    const pathnameUsername = location.pathname.split("/profile/")[1];
    const decodedUsername = decodeURIComponent(pathnameUsername);

    if (decodedUsername === user?.username) {
      setSameUser((prev) => !prev);
    } else {
      setSameUser(false);
    }
  }, [location, user, setOpenCreatePost]);

  useEffect(() => {
    // Parse the username from the pathname
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get("category");

    if (param === "myproducts") {
      setMyProducts(true);
    } else {
      setMyProducts(false);
    }
  }, [location, user, setOpenCreatePost]);

  useEffect(() => {
    if (user) {
      setProduct({ ...product, Economic_Sector: user.Economic_Sector });
    }
  }, [user, setOpenCreatePost]);

  const handleProductChange = (e) => {
    const { name, value } = e.target;
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
    setProduct({ ...product, [name]: value });
  };
  const handlePropertyTypeChange = (event) => {
    setPropertyType(event.target.value);
  };

  const productValidation = Yup.object().shape({
    product_name: Yup.string().required(t("required-product-name")),
    price: Yup.string().required(t("required-product-price")),
    description: Yup.string().required(t("required-product-description")),
  });

  const eventValidation = Yup.object().shape({
    product_name: Yup.string().required(t("required-product-name")),
    description: Yup.string().required(t("required-product-description")),
  });

  const productSubmit = async () => {
    if (!images || images.length === 0) {
      // Alert the user if images are not selected
      alert(t("add-1-photo"));
      return;
    }
    if (category === "properties" && !propertyType) {
      // Alert the user if images are not selected
      alert(t("select-sale-rent"));
      return;
    }

    if (category === "events" && (!eventLocation || !date)) {
      // Alert the user if images are not selected
      alert(t("select-location-date"));
      return;
    }
    if (images && images.length) {
      setLoading((prev) => !prev);
      const postImages = images.map((img) => {
        return dataURIToBlob(img);
      });
      const path = `${user.username}/product_images`;
      let formData = new FormData();
      formData.append("path", path);
      postImages.forEach((image) => {
        formData.append("file", image);
      });
      const response = await uploadImages(formData, path, user.token);
      if (category === "properties") {
        const product_post = await createProduct(
          category,
          propertyType,
          response,
          product_name,
          price,
          null,
          null,
          description,
          Economic_Sector,
          user.id,
          user.token
        );
        if (product_post?.status === "ok") {
          if (sameUser) {
            dispatch({
              type: "PROFILE_PRODUCTS",
              payload: [product_post?.data, ...profile?.products],
            });
            setLoading((prev) => !prev);
            setCategory(null);
            setOpenCreatePost(false);
          } else if (home || myProducts) {
            dispatch({
              type: "PRODUCT_POSTS",
              payload: [product_post?.data, ...products],
            });
            setLoading((prev) => !prev);
            setCategory(null);
            setOpenCreatePost(false);
          } else {
            setCategory(null);
            setLoading((prev) => !prev);
            setOpenCreatePost(false);
          }
        } else {
          alert("Product can`t be uploaded");
          setLoading((prev) => !prev);
          setError(product_post);
        }
      } else if (category === "events") {
        try {
          const product_post = await createProduct(
            category,
            null,
            response,
            product_name,
            null,
            date,
            eventLocation,
            description,
            Economic_Sector,
            user.id,
            user.token
          );
          if (product_post?.status === "ok") {
            if (sameUser) {
              dispatch({
                type: "PROFILE_PRODUCTS",
                payload: [product_post?.data, ...profile?.products],
              });
              setLoading((prev) => !prev);
              setCategory(null);
              setOpenCreatePost(false);
            } else if (home || myProducts) {
              dispatch({
                type: "PRODUCT_POSTS",
                payload: [product_post?.data, ...products],
              });
              setLoading((prev) => !prev);
              setCategory(null);
              setOpenCreatePost(false);
            } else {
              setCategory(null);
              setLoading((prev) => !prev);
              setOpenCreatePost(false);
            }
          } else {
            alert("Product can`t be uploaded");
            setLoading((prev) => !prev);
            setError(product_post);
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        try {
          const product_post = await createProduct(
            category,
            null,
            response,
            product_name,
            price,
            null,
            null,
            description,
            Economic_Sector,
            user.id,
            user.token
          );
          if (product_post?.status === "ok") {
            if (sameUser) {
              dispatch({
                type: "PROFILE_PRODUCTS",
                payload: [product_post?.data, ...profile?.products],
              });
              setLoading((prev) => !prev);
              setCategory(null);
              setOpenCreatePost(false);
            } else if (home || myProducts) {
              dispatch({
                type: "PRODUCT_POSTS",
                payload: [product_post?.data, ...products],
              });
              setLoading((prev) => !prev);
              setCategory(null);
              setOpenCreatePost(false);
            } else {
              setCategory(null);
              setLoading((prev) => !prev);
              setOpenCreatePost(false);
            }
          } else {
            alert("Product can`t be uploaded");
            setLoading((prev) => !prev);
            setError(product_post);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const handleAlertMessage = () => {
    if (openAlertMessage === false) {
      setOpenAlertMessage(true);
    }
  };

  return (
    <div className="blurr">
      <div className="marketplace_postBox" ref={popup}>
        <div className="marketplace_box_header">
          <div
            className="marketplace_close_icon"
            onClick={() => {
              !category
                ? setOpenCreatePost((prev) => !prev)
                : setCategory(null);
            }}
          >
            <CloseIcon />
          </div>
          <span>
            {category === "events" ? "Agrega tu evento" : t("Publish-Product")}
          </span>
          <div></div>
        </div>

        {!category ? (
          <div className="marketplace_select_bussines_category">
            <span>{t("Select-Category")}:</span>
            <div className="bussines_category_select_container">
              <div
                className="bussines_category"
                onClick={() => setCategory("offers")}
              >
                <img
                  src={offer_image}
                  alt="empresy-marketplace-ofertas-offers"
                />
                <h1>{t("Offers")}</h1>
                <span>{t("offers_message")}</span>
              </div>
              <div
                className="bussines_category"
                onClick={() => setCategory("products")}
              >
                <img
                  src={product_image}
                  alt="empresy-marketplace-productos-products"
                />
                <h1>{t("Products")}</h1>
                <span>{t("Physical-or-digital-products")}</span>
              </div>

              <div
                className="bussines_category"
                onClick={() => setCategory("services")}
              >
                <img
                  src={services_image}
                  alt="empresy-marketplace-servicios-services"
                />
                <h1>{t("Services")}</h1>
                <span>{t("Activities-performed-by-experts")}</span>
              </div>
              <div
                className="bussines_category"
                onClick={() => handleAlertMessage()}
              >
                <img src={franchise_image} alt="empresy-franquicia-franchise" />
                <h1>{t("Franchise")}</h1>
                <span>{t("Franchises-available-for-acquisition")}</span>
              </div>
              <div
                className="bussines_category"
                onClick={() => handleAlertMessage()}
              >
                <img
                  src={property_image}
                  alt="empresy-marketplace-prpiedades-property"
                />
                <h1>{t("Properties")}</h1>
                <span>{t("properties_sell_rent")}</span>
              </div>
              <div
                className="bussines_category"
                onClick={() => handleAlertMessage()}
              >
                <img src={bussines_image} alt="" />
                <h1>{t("Businesses-for-Sale")}</h1>
                <span>
                  {t("Companies-Businesses-Commercial-Brands-for-Sale")}
                </span>
              </div>
              <div
                className="bussines_category"
                onClick={() => handleAlertMessage()}
              >
                <img
                  src={event_image}
                  alt="empresy-marketplace-eventos-events"
                />
                <h1>{t("Events")}</h1>
                <span>{t("Events-Workshops-Trade-Fairs")}</span>
              </div>
            </div>
          </div>
        ) : null}
        {category ? (
          <div className="marketplace_post_fill">
            <span>{t("Photos-0/3-you-can-add-up-to-3-photos")}</span>
            <ProductImagesPreview
              images={images}
              setImages={setImages}
              error={error}
              setError={setError}
            />
            <div className="marketplace_product_infos">
              <Formik
                enableReinitialize
                initialValues={{
                  product_name,
                  price,
                  description,
                }}
                validationSchema={
                  category !== "events" ? productValidation : eventValidation
                }
                onSubmit={() => productSubmit()}
              >
                {(formik) => (
                  <Form>
                    <div className="marketplace_product_inputs">
                      {category === "properties" ? (
                        <div className="property_type_radio">
                          <label className="label">
                            <input
                              type="radio"
                              name="propertyType"
                              value="rent"
                              checked={propertyType === "rent"}
                              onChange={handlePropertyTypeChange}
                            />
                            <span>{t("rent")}</span>
                          </label>
                          <label className="label">
                            <input
                              type="radio"
                              name="propertyType"
                              value="sale"
                              checked={propertyType === "sale"}
                              onChange={handlePropertyTypeChange}
                            />
                            <span>{t("Sale")}</span>
                          </label>
                        </div>
                      ) : null}
                      {category === "events" ? (
                        <>
                          <div
                            style={{
                              margin: "10px",
                              border: "1px solid lightgray",
                              borderRadius: "10px",
                              height: "50px",
                              width: "100%",
                              color: "gray",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              zIndex: "99",
                            }}
                          >
                            <DatePicker
                              selected={date}
                              onChange={(date) => setDate(date)}
                              closeOnScroll={true}
                              isClearable
                              showTimeSelect
                              dateFormat="Pp"
                              placeholderText={t("Select-event-date")}
                              showMonthDropdown
                              fixedHeight
                            />
                          </div>

                          <AutoComplete
                            setEventLocation={setEventLocation}
                            placeholder={t("Select-Location")}
                          />
                        </>
                      ) : null}

                      <ProductInput
                        type="text"
                        name="product_name"
                        placeholder={
                          category === "events"
                            ? t("name-of-event")
                            : category === "services"
                            ? t("name-of-the-service")
                            : t("name-of-product")
                        }
                        onChange={handleProductChange}
                        length={22}
                      />
                      {category === "events" ? null : (
                        <ProductInput
                          type="number"
                          name="price"
                          placeholder={t("price-of-product")}
                          onChange={handleProductChange}
                          bottom
                          length={22}
                        />
                      )}

                      <ProductTextArea
                        maxLength="200"
                        type="text"
                        name="description"
                        placeholder={
                          category === "events"
                            ? t("event-description")
                            : t("product-description")
                        }
                        onChange={handleProductChange}
                        bottom
                      />

                      <div className="marketplace_post_button">
                        <button type="submit" disabled={loading}>
                          {loading ? (
                            <PulseLoader color="white" />
                          ) : (
                            t("Publish")
                          )}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        ) : null}
      </div>
      {openAlertMessage ? (
        <Modal
          title={`${t("contact-us")}`}
          alert={t("bussiness-alert")}
          onClick={setOpenAlertMessage}
        />
      ) : null}
    </div>
  );
}
