import { Link } from "react-router-dom";

export default function AllMenuItem({ text, description, img, to }) {
  return (
    <Link to={to}>
      <div className="all_menu_item hover1">
        <img src={`../../left/${img}.svg`} alt="" />
        <div className="all_menu_col">
          <span>{text}</span>
          <span>{description}</span>
        </div>
      </div>
    </Link>
  );
}
