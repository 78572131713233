import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

export default function NotificationComment({
  picture,
  name,
  postId,
  created,
}) {
  return (
    <>
      <Link
        // to={`/post/${postId}`} // Assuming you have a post route to view a single post
        className="notification hover3"
      >
        <img src={picture} alt="empresy-notification" />
        <div className="notification_flex">
          <div className="notification_desc">
            <span>
              <strong>{name}</strong> Comentó en tu publicación
            </span>
          </div>

          <div className="notification_date">
            <Moment fromNow interval={30}>
              {created}
            </Moment>
          </div>
        </div>
      </Link>
    </>
  );
}
