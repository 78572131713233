import { Link } from "react-router-dom";

export default function LeftLink({
  img,
  text,
  notification,
  to,
  postss,
  setVisitorModal,
}) {
  return (
    <>
      {postss ? (
        <>
          <div
            className="left_link hover1"
            onClick={() => {
              setVisitorModal(true);
            }}
          >
            <img src={`../../../left/${img}.svg`} alt="" />
            {notification !== undefined ? (
              <div className="col">
                <div className="col_1">{text}</div>
                <div className="col_2">{notification}</div>
              </div>
            ) : (
              <span>{text}</span>
            )}
          </div>
        </>
      ) : (
        <>
          <Link to={to}>
            <div className="left_link hover1">
              <img src={`../../../left/${img}.svg`} alt="" />
              {notification !== undefined ? (
                <div className="col">
                  <div className="col_1">{text}</div>
                  <div className="col_2">{notification}</div>
                </div>
              ) : (
                <span>{text}</span>
              )}
            </div>
          </Link>
        </>
      )}
    </>
  );
}
