import moment from "moment";
import TraingleIcon from "../../../svg2/triangle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useRef, useState } from "react";
import { deleteMessage } from "../../../functions/messages";
import { useSelector } from "react-redux";
import SocketContext from "../../../context/SocketContext";

function Message({ message, me, user, socket, deletedMessage }) {
  const handleEraseMessage = async () => {
    const res = await deleteMessage(message._id, user.token);

    if (res.status === "ok") {
      messageR.current.style.display = "none";
      socket.emit("delete", message);
    } else {
      return;
    }
  };

  const messageR = useRef(null);
  const [optionMessage, setOptionMessage] = useState(false);

  return (
    <>
      {deletedMessage === message._id ? (
        <div></div>
      ) : (
        <div
          ref={messageR}
          onClick={() => setOptionMessage((prev) => !prev)}
          className={`w-full flex mt-2 space-x-3 max-w-xs ${
            me ? "ml-auto justify-end" : ""
          }`}
        >
          {/*Message container*/}
          <div>
            <div
              className={`relative flex h-full dark:text-dark_text_1 p-2 rounded-lg ${
                me ? "bg-green_3" : "dark:bg-dark_bg_2"
              }`}
            >
              {/*message*/}
              <div className="message_message">
                <div className="message_text">{message.message}</div>
              </div>
              {me && (
                <div
                  style={{ cursor: "pointer", transform: "translateY(-5px)" }}
                >
                  <ExpandMoreIcon />
                </div>
              )}

              {/*message date*/}
              <span className="absolute right-1.5 bottom-1.5 text-xs text-dark_text_5 leading-none">
                {moment(message.createdAt).format("HH:mm")}
              </span>
              {/*triangle*/}
              {!me ? (
                <span>
                  <TraingleIcon className="dark:fill-dark_bg_2 rotate-[60deg] absolute top-[-5px] -left-1.5" />
                </span>
              ) : (
                <TraingleIcon className="dark:fill-green_3 rotate-[60deg] absolute top-[-5px] -right-1.5" />
              )}
            </div>
          </div>
          {optionMessage ? (
            <>
              {me && (
                <div
                  className="message_option"
                  onClick={() => handleEraseMessage()}
                >
                  Eliminar
                </div>
              )}
            </>
          ) : null}
        </div>
      )}
    </>
  );
}

const MessageWithSocket = (props) => (
  <SocketContext.Consumer>
    {(socket) => <Message {...props} socket={socket} />}
  </SocketContext.Consumer>
);
export default MessageWithSocket;
