import React from "react";
import Conversation from "./Conversation";
import "./style.css";
import { useSelector } from "react-redux";
import {
  checkOnlineStatus,
  getConversationId,
} from "../../../helpers/chat_receiverid";

export default function Conversations({
  conversations,
  user,
  setShowMessages,
  onlineUsers,
  typing,
  receiverId,
}) {
  const { activeConversation } = useSelector((state) => state?.chat);
  return (
    <div className="convos scrollbar">
      <ul>
        {conversations &&
          conversations
            .filter(
              (c) => c?.latestMessage || c?._id === activeConversation?._id
            )
            .map((convo, i) => {
              let check = checkOnlineStatus(onlineUsers, user, convo?.users);
              return (
                <Conversation
                  convo={convo}
                  key={i}
                  user={user}
                  setShowMessages={setShowMessages}
                  online={check ? true : false}
                  typing={typing}
                  receiverId={receiverId}
                />
              );
            })}
      </ul>
    </div>
  );
}
