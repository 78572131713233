// export function chatReducer(
//   state = {
//     status: "",
//     error: "",
//     conversations: [],
//     activeConversation: {},
//     messages: [],
//     notification: [],
//   },
//   action
// ) {
//   switch (action.type) {
//     case "CONVERSATIONS_LOADING":
//       return { ...state, loading: true, error: "", status: "loading" };

//     case "GETCONVERSATION":
//       return { ...state, conversations: action.payload, status: "" };

//     case "GETACTIVECONVERSATION":
//       return { ...state, activeConversation: action.payload };

//     case "CONVERSATIONS_REJECTED":
//       return (state.status = "failed")((state.error = action.payload));

//     case "MESSAGES_LOADING":
//       return { ...state, loading: true, error: "", status: "loading" };

//     case "GETMESSAGES":
//       return { ...state, messages: action.payload, status: "" };

//     case "MESSAGES_REJECTED":
//       return (state.status = "failed")((state.error = action.payload));

//     case "SEND_MESSAGE_LOADING":
//       return { ...state, error: "", status: "loading" };

//     case "SEND_MESSAGE":
//       const conversation = {
//         ...action.payload.conversation,
//         latestMessage: action.payload,
//       };
//       const newConvos = [...state.conversations].filter(
//         (c) => c._id !== conversation._id
//       );
//       newConvos.unshift(conversation);

//       return {
//         ...state,
//         messages: [...state.messages, action.payload],
//         status: "",
//         conversation: conversation,
//         conversations: newConvos, // Corrected from 'state.conversations = newConvos'
//       };

//     case "SEND_MESSAGE_REJECTED":
//       return (state.status = "failed")((state.error = action.payload));

//     case "UPDATE_MESSAGES_AND_CONVERSATIONS":
//       let convo = state.activeConversation;
//       if (convo._id === action.payload.conversation._id) {
//         return { ...state, messages: [...state.messages, action.payload] };
//       }
//       const updatedConversation = {
//         ...action.payload.conversation,
//         latestMessage: action.payload,
//       };
//       const updatedConversations = [...state.conversations].filter(
//         (c) => c.id !== updatedConversation._id
//       );
//       updatedConversations.unshift(updatedConversation);

//       return {
//         ...state,
//         conversations: updatedConversations,
//       };

//     default:
//       return state;
//   }
// }
export function chatReducer(
  state = {
    status: "",
    error: "",
    conversations: [],
    activeConversation: {},
    messages: [],
    notification: [],
  },
  action
) {
  switch (action.type) {
    case "CONVERSATIONS_LOADING":
      return { ...state, loading: true, error: "", status: "loading" };

    case "GETCONVERSATION":
      return { ...state, conversations: action.payload, status: "" };

    case "GETACTIVECONVERSATION":
      return { ...state, activeConversation: action.payload };

    case "CONVERSATIONS_REJECTED":
      return { ...state, status: "failed", error: action.payload };

    case "MESSAGES_LOADING":
      return { ...state, loading: true, error: "", status: "loading" };

    case "GETMESSAGES":
      return { ...state, messages: action.payload, status: "" };

    case "MESSAGES_REJECTED":
      return { ...state, status: "failed", error: action.payload };

    case "SEND_MESSAGE_LOADING":
      return { ...state, error: "", status: "loading" };

    case "SEND_MESSAGE":
      const sentMessage = action.payload;
      const updatedSentMessages = [...state.messages, sentMessage];
      const updatedSentConversations = state.conversations.map(
        (conversation) => {
          if (conversation._id === sentMessage.conversation._id) {
            return {
              ...conversation,
              latestMessage: sentMessage,
            };
          } else {
            return conversation;
          }
        }
      );

      return {
        ...state,
        messages: updatedSentMessages,
        status: "",
        conversations: updatedSentConversations,
      };

    case "SEND_MESSAGE_REJECTED":
      return { ...state, status: "failed", error: action.payload };

    case "UPDATE_MESSAGES_AND_CONVERSATIONS":
      const receivedMessage = action.payload;
      const conversationId = receivedMessage.conversation._id;

      // Check if the received message belongs to the active conversation
      if (
        state.activeConversation &&
        state.activeConversation._id === conversationId
      ) {
        const updatedMessages = [...state.messages, receivedMessage];
        const updatedConversations = state.conversations.map(
          (existingConversation) => {
            if (existingConversation._id === conversationId) {
              return {
                ...existingConversation,
                latestMessage: receivedMessage,
              };
            } else {
              return existingConversation;
            }
          }
        );

        return {
          ...state,
          messages: updatedMessages,
          conversations: updatedConversations,
        };
      } else {
        // If it's not the active conversation, update conversations without modifying messages
        const updatedConversations = state.conversations.map(
          (existingConversation) => {
            if (existingConversation._id === conversationId) {
              return {
                ...existingConversation,
                latestMessage: receivedMessage,
              };
            } else {
              return existingConversation;
            }
          }
        );

        return {
          ...state,
          conversations: updatedConversations,
        };
      }

    default:
      return state;
  }
}
