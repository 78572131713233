import React from "react";
import MarketPlaceOptions from "../marketplace_options/MarketPlaceOptions";
import FadeLoader from "react-spinners/FadeLoader";
import ProfileMarketPlaceCard from "../profile_marketplace_card/ProfileMarketPlaceCard";
import MarketPlaceCard from "../../pages/marketplace/MarketPlaceCard";
import CreatePostMarketplace from "../createPostMarketplace";
import { useInView } from "react-intersection-observer";
import { InView } from "react-intersection-observer";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useTranslation } from "react-i18next";

export default function MarketPlaceWide({
  handleCategoryChange,
  handleMyProducts,
  category,
  setOpenCreatePost,
  sector,
  handleSectorChange,
  productRef,
  handleOtherCategories,
  loading,
  products,
  user,
  more,
  handleInViewChange,
  popup,
  t,
  openCreatePost,
  setVisible,
  visitor,
}) {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.1,
  });

  return (
    <div className="all_marketplace">
      <MarketPlaceOptions
        handleCategoryChange={handleCategoryChange}
        handleMyProducts={handleMyProducts}
        category={category}
        setOpenCreatePost={setOpenCreatePost}
        sector={sector}
        handleSectorChange={handleSectorChange}
        productRef={productRef}
        handleOtherCategories={handleOtherCategories}
        setVisible={setVisible}
        visitor={visitor}
      />
      <div className="marketplace_right">
        <div className="marketplace_header">
          <span>{t("welcome-home-page")}</span>
        </div>

        {loading ? (
          <FadeLoader
            style={{ position: "relative", left: "50%", top: "50%" }}
          />
        ) : (
          <div
            className={
              category === "myproducts"
                ? "marketplace_content_myproducts"
                : "marketplace_content"
            }
          >
            {products?.length > 0 ? (
              products?.map((product, index) =>
                category === "myproducts" ? (
                  <ProfileMarketPlaceCard
                    product={product}
                    user={user}
                    key={index}
                    ref={ref}
                  />
                ) : (
                  <MarketPlaceCard
                    key={index}
                    product={product}
                    category={category}
                    visitor={visitor}
                  />
                )
              )
            ) : (
              <h1>{t("no-products")}</h1>
            )}
            {products?.length > 0 && more !== products?.length && (
              <InView onChange={handleInViewChange}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FadeLoader />
                </div>
              </InView>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
