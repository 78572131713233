import EditIcon from "@mui/icons-material/Edit";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useCallback, useEffect, useRef, useState } from "react";
import useClickOutside from "../../helpers/clickOutside";
import getCroppedImg from "../../helpers/getCroppedImg";
import Cropper from "react-easy-crop";
import { uploadImages } from "../../functions/uploadImages";
import { useSelector } from "react-redux";
import { updateCover } from "../../functions/user";
import { createPost } from "../../functions/post";
import PulseLoader from "react-spinners/PulseLoader";
import { useTranslation } from "react-i18next";

export default function Cover({ profile, visitor, user }) {
  const { t } = useTranslation();

  const [showCoverMenu, setshowCoverMenu] = useState(false);
  const [coverPicture, setCoverPicture] = useState("");
  const coverpopup = useRef(null);
  const refInput = useRef(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const cRef = useRef(null);
  const [coverImage, setCoverImage] = useState(
    profile?.cover ? profile?.cover : ""
  );
  const [height, setHeight] = useState(); // Add height state

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        if (loading) {
          setLoading(false);
          setCoverImage(profile?.cover);
        }
      }, 100);
    }
  }, [loading, setLoading, setCoverImage, profile?.cover]);

  useClickOutside(coverpopup, () => {
    setshowCoverMenu(false);
  });

  const handleImage = (e) => {
    let file = e.target.files[0];
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/webp"
    ) {
      setError(`The format of the file is not supported.`);
      return;
    } else if (file.size > 1024 * 1024 * 5) {
      setError("The size of the file is to large");
      return;
    }
    e.target.value = null;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      setCoverPicture(event.target.result);
    };
    setshowCoverMenu(false);
  };

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setcroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setcroppedAreaPixels(croppedAreaPixels);
  }, []);

  const getCroppedImage = useCallback(
    async (show) => {
      try {
        const img = await getCroppedImg(coverPicture, croppedAreaPixels);
        if (show) {
          setZoom(1);
          setCrop({ x: 0, y: 0 });
          setCoverPicture(img);
        } else {
          return img;
        }
      } catch (error) {
        console.error(error);
      }
    },
    [croppedAreaPixels]
  );

  const coverRef = useRef(null);
  const [width, setWidth] = useState();

  useEffect(() => {
    setWidth(coverRef.current.clientWidth);
    setHeight(coverRef.current.clientHeight);
  }, [window.innerWidth]);

  const updateCoverPicture = async () => {
    try {
      setLoading(true);
      let img = await getCroppedImage();
      let blob = await fetch(img).then((b) => b.blob());
      const path = `${user.username}/cover_pictures`;
      let formData = new FormData();
      formData.append("file", blob);
      formData.append("path", path);
      const res = await uploadImages(formData, path, user.token);
      const updated_picture = await updateCover(res[0].url, user.token);

      if (updated_picture === "ok") {
        const new_post = await createPost(
          "coverPicture",
          null,
          res,
          user.id,
          user.token
        );

        setLoading(false);
        setCoverPicture("");

        if (new_post.status === "ok") {
          setCoverImage(res[0].url);
        } else {
          setLoading(false);
          setError(new_post);
        }
      } else {
        setLoading(false);
        setError(updated_picture);
      }
    } catch (error) {
      setLoading(false);
      setError(error.response.data.error);
    }
  };

  return (
    <div className="profile_cover" ref={coverRef}>
      {coverPicture && (
        <div className="save_changes_cover">
          <div className="save_changes_left">
            <div
              className="open_cover_update"
              onClick={() => setCoverPicture("")}
            >
              {t("Cancel")}
            </div>
          </div>
          <div
            className="save_changes_right"
            onClick={() => updateCoverPicture()}
          >
            <button className="blue_btn" style={{ padding: "4px 8px" }}>
              {loading ? <PulseLoader color="white" size={5} /> : t("Save")}
            </button>
          </div>
        </div>
      )}
      <input
        type="file"
        ref={refInput}
        hidden
        accept="image/jpeg, image/png, image/webp, image/jfif"
        onChange={handleImage}
      />
      {error && (
        <div className="postError comment_error">
          <div className="postError_error">{error}</div>
          <button
            className="blue_btn"
            onClick={() => {
              setError("");
            }}
          >
            Try again
          </button>
        </div>
      )}
      {coverPicture && (
        <div className="cover_crooper">
          <Cropper
            image={coverPicture}
            crop={crop}
            zoom={zoom}
            aspect={width / height}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            showGrid={true}
            objectFit="horizontal-cover"
          />
        </div>
      )}
      {profile?.cover && !coverPicture && (
        <img
          ref={cRef}
          src={coverImage}
          className="cover"
          alt="empresy cover"
        />
      )}
      {!visitor && (
        <div className="update_cover_wrapper" ref={coverpopup}>
          {!coverPicture && (
            <div
              className="open_cover_update"
              onClick={() => {
                setshowCoverMenu((prev) => !prev);
              }}
            >
              <EditIcon />
              {t("edit")}
            </div>
          )}

          {showCoverMenu && (
            <div className="open_cover_menu">
              <div
                className="open_cover_menu_item hover1"
                onClick={() => refInput.current.click()}
              >
                <FileUploadIcon />
                {t("select-picture")}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
