import "./styles.css";
import { useEffect, useReducer, useRef, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { Market, Search, ArrowDown } from "../../svg";
import HomeIcon from "@mui/icons-material/Home";
import { useSelector } from "react-redux";
import AppsIcon from "@mui/icons-material/Apps";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SearchMenu from "./SearchMenu";
import AllMenu from "./AllMenu";
import useClickOutside from "../../helpers/clickOutside";
import UserMenu from "./UserMenu";
import chat from "../../icons/chat_black.png";
import { useMediaQuery } from "react-responsive";
import AddIcon from "@mui/icons-material/Add";
import CreatePostPopup from "../createPostPopup";
import Messages from "./Messages";
import Notifications from "./Notifications";
import { notificationReducer, profileReducer } from "../../functions/reducers";
import axios from "axios";
import { removeNotifications } from "../../functions/notifications";
import { getNotifications } from "../../functions/notifications";
import SearchMenuVisitor from "./SearchMenuVisitor";
import EmailIcon from "@mui/icons-material/Email";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { economicSectors } from "../../pages/marketplace/economicSectors";
import SliderCategories from "./SliderCategories/SliderCategories";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import products_icon from "../../styles/marketplace_icons/productos-marketplace-empresas-empresy (1).svg";
import offers_icon from "../../styles/marketplace_icons/ofertas-marketplace-empresas-empresy.svg";
import services_icon from "../../styles/marketplace_icons/servicios-marketplace-empresas-empresy (1).svg";
import franchise_icon from "../../styles/marketplace_icons/franquicias-marketplace-empresas-empresy (1).svg";
import property_icon from "../../styles/marketplace_icons/propiedadesmarketplace-empresas-empresy (1).svg";
import event_icon from "../../styles/marketplace_icons/eventos-marketplace-empresas-empresy (1).svg";
import bussines_icon from "../../styles/marketplace_icons/negocios-venta-marketplace-empresas-empresy (1).svg";
import bussines_profile from "../../styles/marketplace_icons/perfil-empresa-empresy (1).svg";
import useEconomicSectors from "../../hooks/useEconomicSectors";
import PlaceIcon from "@mui/icons-material/Place";
import { grey } from "@mui/material/colors";
import ChangeLocationModal from "../marketplace_change_location/ChangeLocationModal";

export default function Header({
  page,
  postss,
  setVisitorModal,
  user,
  visitor,
  navigateToHome,
  setOpenCreatePost,
  handleMyProducts,
  home,
  scrollDir,
  sector,
  handleSectorChange,
  query770px,
  handleCategoryChange,
  handleOtherCategories,
  handleChange,
  setProducts,
  scrollPosition,
  userLocation,
  setUserLocation,
}) {
  const query1040px = useMediaQuery({
    query: "(max-width:1040px)",
  });

  const economicSectors = useEconomicSectors();

  const { t } = useTranslation();

  const maxx = query1040px && <img src="../../icons/empresy e.svg" />;
  const color = "#65676b";
  const [showSearchMenu, setshowSearchMenu] = useState(false);
  const [showSearchMenuVisitor, setshowSearchMenuVisitor] = useState(false);
  const [showAllMenu, setShowAllMenu] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [showNotifications, setsShowNotifications] = useState(false);
  const [notification, setNotification] = useState();
  const [post, setpost] = useState(false);
  const navigate = useNavigate();
  const allmenu = useRef(null);
  const usermenu = useRef(null);
  const messages = useRef(null);
  const notifications = useRef(null);
  const [optionsState, setOptionsState] = useState("up");
  const [openCategories, setOpenCategories] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    name: t("Select-Category"),
    image: null,
  });
  const [showChangeUserLocation, setshowChangeUserLocation] = useState(false);

  useClickOutside(allmenu, () => {
    setShowAllMenu(false);
  });
  useClickOutside(usermenu, () => {
    setShowUserMenu(false);
  });
  useClickOutside(messages, () => {
    setShowMessages(false);
  });
  useClickOutside(notifications, () => {
    setsShowNotifications(false);
  });

  const [{ loading, error, data }, dispatch] = useReducer(notificationReducer, {
    loading: false,
    data: {},
    error: "",
  });

  useEffect(() => {
    getNotification();
  }, []);

  const getNotification = async () => {
    dispatch({ type: "NOTIFICATION_REQUEST" });
    const data = await getNotifications(user.token);
    setNotification(data.length);
  };

  const handleRemoveNotifications = async () => {
    setNotification(0);
    await removeNotifications(user.token);
  };

  const handleGoHome = () => {
    navigate("/");
  };

  useEffect(() => {
    const scrollDif = scrollPosition?.bottom - scrollPosition?.top;

    if (scrollDir === "down") {
      setOptionsState(scrollDir);
      setOpenCategories(false);
    } else if (scrollDir === "up" && scrollPosition?.top === 0) {
      setOptionsState(scrollDir);
    }
  }, [scrollDir, scrollPosition]);

  const handleSelectCategory = (category, displayName, imageSrc) => {
    if (
      category === "directory" ||
      category === "products" ||
      category === "services" ||
      category === "offers"
    ) {
      handleCategoryChange(category);
    } else {
      handleOtherCategories(category);
    }
    setSelectedCategory({ name: displayName, image: imageSrc });
    setOpenCategories(false);
  };

  return (
    <header>
      <div className="header_container" style={{ width: "100%" }}>
        <div className="header_up">
          <div className="header-left">
            <div
              className="header-logo"
              onClick={() => {
                home ? navigateToHome() : handleGoHome();
              }}
            >
              <div>
                {!maxx ? (
                  <div className="circle_2">
                    <img
                      src="../../images/empresy-logo-red-empresas.svg"
                      alt="empresy logo"
                    />
                  </div>
                ) : (
                  <div className="circle">
                    <img
                      className="empresy_peque"
                      src="../../images/e-empresy-logo-red-empresas.svg"
                      alt="empresy logo"
                    />
                  </div>
                )}
              </div>
            </div>
            {postss ? (
              <div
                className="search search1"
                onClick={() => {
                  setshowSearchMenuVisitor(true);
                }}
              >
                <Search color={color} />
                <input
                  className="hide_input"
                  type="text"
                  placeholder={t("search")}
                  postss
                />
              </div>
            ) : (
              <div
                className="search search1"
                onClick={() => {
                  setshowSearchMenu(true);
                }}
              >
                <Search color={color} />
                <input
                  className="hide_input"
                  type="text"
                  placeholder={t("search")}
                />
              </div>
            )}
            <div
              className="search search1"
              onClick={() => {
                setshowChangeUserLocation((prev) => !prev);
              }}
            >
              <PlaceIcon sx={{ color: grey[600] }} />
              <input
                className="hide_input"
                type="text"
                placeholder={t("location")}
                defaultValue={userLocation}
                readOnly
              />
            </div>
          </div>
          {showChangeUserLocation ? (
            <ChangeLocationModal
              onClick={setshowChangeUserLocation}
              location={userLocation}
              setLocation={setUserLocation}
            />
          ) : null}
          {showSearchMenu && (
            <SearchMenu
              color={color}
              setshowSearchMenu={setshowSearchMenu}
              token={user.token}
              t={t}
              postss
              setProducts={setProducts}
            />
          )}

          {/* // iconos que van en la mitad del header */}
          <div className="header-right">
            <div
              className={`circle_icon_ hover1 ${
                showAllMenu && "active_header"
              }`}
            >
              <div
                style={{ transform: "translateY(-1px)" }}
                onClick={() => {
                  setOpenCreatePost((prev) => !prev);
                }}
              >
                <AddIcon />
              </div>
              {showAllMenu && <AllMenu />}
            </div>

            {/* {postss ? (
              <div></div>
            ) : (
              <Link to="/messages" ref={messages}>
                <div
                  className={`circle_icon_ hover1 ${
                    showMessages && "active_header"
                  }`}
                >
                  <EmailIcon />
                </div>
              </Link>
            )} */}
            {showMessages && <Messages setShowMessages={setShowMessages} />}
            {!postss && (
              <div
                ref={notifications}
                onClick={() => handleRemoveNotifications()}
              >
                <div
                  className={`circle_icon_ hover1 ${
                    showNotifications && "active_header"
                  }`}
                  onClick={() => {
                    setsShowNotifications((prev) => !prev);
                  }}
                >
                  {notification >= 1 ? (
                    <div className="right_notification">{notification}</div>
                  ) : (
                    <div className=""></div>
                  )}
                  <NotificationsActiveIcon />
                </div>
                {showNotifications && <Notifications user={user} />}
              </div>
            )}

            {postss ? (
              <Link to="/login">
                <button className="blue_btn">Ingresa / Registrate</button>
              </Link>
            ) : (
              <div ref={usermenu}>
                <div
                  className="profile_user hover2"
                  style={{ transform: "translateY(0px)", cursor: "pointer" }}
                  onClick={() => {
                    setShowUserMenu((prev) => !prev);
                  }}
                >
                  <img src={user?.picture} alt="empresy profile" />
                </div>
                {showUserMenu && (
                  <UserMenu
                    user={user}
                    setShowUserMenu={setShowUserMenu}
                    handleMyProducts={handleMyProducts}
                    handleChange={handleChange}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {home && query770px ? (
          <>
            {optionsState === "down" ||
            showUserMenu ||
            showNotifications ? null : (
              <div className="market_place_header_mobile_bottom">
                <>
                  <span>{t("welcome-home-page")}</span>
                  <span style={{ fontWeight: "400" }}>
                    {t("Filter-Economic-Sector")}
                  </span>
                  <div className="select_marketplace_mobile">
                    <select
                      name="sector"
                      id="sector"
                      required
                      onChange={handleSectorChange}
                      value={sector}
                      className="select_marketplace_select_mobile_select"
                      style={{ borderRadius: "20px" }}
                    >
                      {economicSectors?.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                    <span style={{ fontWeight: "400" }}>
                      {t("Select-Category")}
                    </span>
                    <div
                      className="select_marketplace_select_mobile"
                      onClick={() => setOpenCategories((prev) => !prev)}
                    >
                      {selectedCategory.image && (
                        <img
                          src={selectedCategory.image}
                          alt={selectedCategory.name}
                        />
                      )}
                      {selectedCategory.name}
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <KeyboardArrowDownIcon />
                      <div></div>
                    </div>
                  </div>

                  {openCategories ? (
                    <div className="categories_mobile">
                      <div
                        className="category_mobile"
                        onClick={() =>
                          handleSelectCategory(
                            "directory",
                            t("Business-Directory"),
                            bussines_profile
                          )
                        }
                      >
                        <img src={bussines_profile} alt="" />
                        <span>{t("Business-Directory")}</span>
                      </div>
                      <div
                        className="category_mobile"
                        onClick={() =>
                          handleSelectCategory(
                            "products",
                            t("Products"),
                            products_icon
                          )
                        }
                      >
                        <img src={products_icon} alt="" />
                        <span>{t("Products")}</span>
                      </div>
                      <div
                        className="category_mobile"
                        onClick={() =>
                          handleSelectCategory(
                            "services",
                            t("Services"),
                            services_icon
                          )
                        }
                      >
                        <img src={services_icon} alt="" />
                        <span>{t("Services")}</span>
                      </div>
                      <div
                        className="category_mobile"
                        onClick={() =>
                          handleSelectCategory(
                            "offers",
                            t("Offers"),
                            offers_icon
                          )
                        }
                      >
                        <img src={offers_icon} alt="" />
                        <span>{t("Offers")}</span>
                      </div>
                      <div
                        className="category_mobile"
                        onClick={() =>
                          handleSelectCategory(
                            "franchise",
                            t("Franchise"),
                            franchise_icon
                          )
                        }
                      >
                        <img src={franchise_icon} alt="" />
                        <span>{t("Franchise")}</span>
                      </div>
                      <div
                        className="category_mobile"
                        onClick={() =>
                          handleSelectCategory(
                            "properties",
                            t("Properties"),
                            property_icon
                          )
                        }
                      >
                        <img src={property_icon} alt="" />
                        <span>{t("Properties")}</span>
                      </div>
                      <div
                        className="category_mobile"
                        onClick={() =>
                          handleSelectCategory(
                            "events",
                            t("Events"),
                            event_icon
                          )
                        }
                      >
                        <img src={event_icon} alt="" />
                        <span>{t("Events")}</span>
                      </div>
                      <div
                        className="category_mobile"
                        onClick={() =>
                          handleSelectCategory(
                            "bussines",
                            t("Businesses-for-Sale"),
                            bussines_icon
                          )
                        }
                      >
                        <img src={bussines_icon} alt="" />
                        <span>{t("Businesses-for-Sale")}</span>
                      </div>
                    </div>
                  ) : null}
                </>
              </div>
            )}
          </>
        ) : null}
      </div>
    </header>
  );
}
