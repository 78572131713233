import PropagateLoader from "react-spinners/PropagateLoader";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export default function ActivateForm({ type, header, text, loading }) {
  const success = type === "success";
  const error = type === "error";
  const color = "#d50000";
  return (
    <div className="blurr">
      <div className="popup">
        <div
          className={`popup_header ${
            type === "success" ? "success_text" : "error_text"
          }`}
        >
          {header}
        </div>
        <div className="popup_message">{text}</div>
        {success && (
          <CheckCircleOutlineIcon color="success" sx={{ fontSize: 50 }} />
        )}
        {error && <ErrorOutlineIcon sx={{ color: color, fontSize: 50 }} />}
        <PropagateLoader color="#1876f2" size={30} loading={loading} />
      </div>
    </div>
  );
}
