import React, { useEffect, useRef, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import LoginInput from "../../components/inputs/logininput";
import * as Yup from "yup";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "axios";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Search, WifiOff } from "@mui/icons-material";
import { images } from "../../icons/empresy-logo-red-empresas.svg";
import empresyFooter from "../../styles/icons/empresy-logo-white.svg";
import premiumImage from "../../styles/icons/empresy-icon-premium.svg";
import adsImage from "../../styles/icons/empresy-ads-icon.svg";
import phoneImage from "../../styles/icons/phone-empresy-ads.png";
import "./style.css";
import logoW from "../../styles/icons/Digital_Glyph_Green.png";
import { useTranslation } from "react-i18next";

const loginInfos = {
  email: "",
  password: "",
};

export default function LandingPage({ setVisible, setPolicy }) {
  const { t } = useTranslation();
  const [lowercaseEmail, setLowercaseEmail] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, setLogin] = useState(loginInfos);
  const { email, password } = login;

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setLogin({ ...login, [name]: value });
      setLowercaseEmail(value.toLowerCase());
    } else {
      setLogin({ ...login, [name]: value });
    }
  };

  const loginValidation = Yup.object({
    email: Yup.string()
      .required("Correo electronico es requerido.")
      .email("Debe ser un email valido")
      .max(100),
    password: Yup.string().required("Contraseña es requerida."),
  });
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const loginSubmit = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/login`,
        { email: lowercaseEmail, password }
      );

      if (data) {
        dispatch({ type: "LOGIN", payload: data });
        Cookies.set("user", JSON.stringify(data));
        navigate("/");
      } else {
        console.error("No user data received");
        setError("No user data received");
      }
    } catch (error) {
      setLoading(false);
      console.error(
        "Login error:",
        error.response ? error.response.data : error.message
      );
      setError(
        error.response ? error.response.data.message : "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    scrollToTop();
  }, []);
  const topPage = useRef();

  const scrollToTop = () => {
    topPage.current.scrollIntoView({ behavior: "smooth" });
  };

  const loginRef = useRef();

  const scrollToLogIn = () => {
    loginRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="empresy_landing_page" ref={topPage}>
      {/* <!-- Banner --> */}
      <div>
        <div className="login-navbar">
          <button
            className="explore-button-landing"
            id="btn-register-landing-bottom"
            onClick={() => {
              setVisible(true);
              window.dataLayer.push({ event: "create_account" });
            }}
          >
            {/* <!--Register Button --> */}
            {t("Join-Now")}
          </button>

          <button
            className="explore-button-landing"
            id="btn-login-landing-bottom"
            onClick={() => {
              scrollToLogIn();
              window.dataLayer.push({ event: "explore" });
            }}
          >
            {/* <!--Log In Button --> */}
            {t("Log-In")}
          </button>
        </div>
      </div>

      <section className="title-banner-landing">
        {/* <!-<section className="section-1"> --> */}
        {/* <!-- Contenido de la sección 1 aquí --> */}
        <div className="container-landing">
          <div className="row">
            <div className="col">
              <img
                src="../../images/empresy-nuevo.png"
                alt="empresy-logo"
                id="logo-empresy-landing"
              />
              <p className="tag-line sub-title" id="sub-title-landing">
                {/* <!-<Welcome Text 1> --> */}
                {t("Welcome-to-the")}
                <br />
                {t("The-Social-Business-Marketplace")}
              </p>

              <p className="tag-line sub-title" id="sub-title-landing-1">
                {/* <!-<Welcome Text 3> --> */}
                {t("Connect-with-Customers-Suppliers-Products-Services")}
              </p>

              <button
                className="btn-primary"
                id="btn-primary-landing"
                onClick={() => {
                  setVisible(true);
                  window.dataLayer.push({ event: "create_account" });
                }}
              >
                {t("Join-Now")}
              </button>
            </div>

            <div className="col">
              <img
                src="../../images/empresy-business-social-marketplace.png"
                alt="empresy-business-social-marketplace"
                id="tablet-empresy-landing"
              />
            </div>

            <div className="spacer"></div>

            <div className="row">
              <p className="tag-line sub-title" id="sub-title-landing-2">
                {/* <!-<Do-you-want-to-see Text > --> */}
                {t(
                  "Do-you-want-to-see-the-companies-that-are-already-on-Empresy?"
                )}
              </p>
              <p className="tag-line sub-title" id="sub-title-landing-1">
                {/* <!-<You-navigate-Empresy-Explore Text > --> */}
                {t("You-navigate-Empresy-Explore")}
              </p>
            </div>

            <div className="col">
              <img
                src="../../images/empresy-business-company-products-services.png"
                alt="empresy-business-social-marketplace"
                id="tablet-empresy-landing"
              />
            </div>

            <div className="col text-right">
              <Link to="/">
                <button
                  className="explore-button-landing"
                  id="btn-explorar-landing"
                  onClick={() => {
                    window.dataLayer.push({ event: "explore" });
                  }}
                >
                  {/* <!-<Explore button > --> */}
                  {t("Explore")}
                </button>
              </Link>
            </div>

            <div className="spacer" ref={loginRef}></div>

            <div className="row">
              <p className="tag-line sub-title" id="sub-title-landing-2">
                {/* <!-<Do-already-have-account > --> */}
                {t("Do-already-have-account")}
              </p>

              <section className="box-input" id="box-input-login-landing">
                <div className="container1">
                  <div className="row">
                    <div className="col">
                      <div className="form-container">
                        <Formik
                          enableReinitialize
                          initialValues={{
                            email,
                            password,
                          }}
                          validationSchema={loginValidation}
                          onSubmit={() => loginSubmit()}
                        >
                          {(formik) => (
                            <Form>
                              <LoginInput
                                type="text"
                                name="email"
                                placeholder={t("E-mail")}
                                onChange={handleLoginChange}
                              />
                              <LoginInput
                                type="password"
                                name="password"
                                placeholder="Password"
                                onChange={handleLoginChange}
                                bottom
                              />

                              <button
                                type="submit"
                                className="btn-secondary"
                                id="btn-login-landing"
                                onClick={() =>
                                  window.dataLayer.push({ event: "log_in" })
                                }
                              >
                                {t("Log-In")}
                              </button>
                            </Form>
                          )}
                        </Formik>
                        <Link to="/reset" className="forgot-password-link">
                          {t("Have-forgotten-password")}
                        </Link>
                        <ScaleLoader
                          color="#1876f2"
                          loading={loading}
                          size={20}
                        />
                        {error && <div className="error_text">{error}</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <div className="spacer"></div>

              <div className="row">
                <p className="tag-line sub-title" id="sub-title-landing-2">
                  {t("As-easy-social-media-app")}
                </p>
                <p className="tag-line sub-title" id="sub-title-landing-1">
                  {t("Easily-create-business-profile-clicks")}
                </p>
                <button
                  className="btn-primary"
                  id="btn-primary-landing"
                  onClick={() => {
                    setVisible(true);
                    window.dataLayer.push({ event: "create_account" });
                  }}
                >
                  {t("Join-Now")}
                </button>
              </div>

              <div className="spacer"></div>

              <div className="row">
                <div className="icon-container">
                  <img
                    src="../../images/empresy-bussines-marketplace (1).png"
                    alt="empresy-business-social-marketplace-ecommerce"
                    id="empresy-icon-premium"
                  />
                </div>

                <p className="tag-line sub-title" id="sub-title-landing-2">
                  {t("Create-your-e-commerce")}
                </p>

                <p className="tag-line sub-title" id="sub-title-landing-1">
                  {t("Adding-product-services-easy-post")}
                </p>
                <button
                  className="btn-primary"
                  id="btn-primary-landing"
                  onClick={() => {
                    setVisible(true);
                    window.dataLayer.push({ event: "create_account" });
                  }}
                >
                  {t("Join-Now")}
                </button>
              </div>

              <div className="spacer"></div>

              <div className="row">
                <div className="icon-container">
                  <img
                    src="../../images/explore-empresy-business-companies (1).png"
                    alt="explore-empresy-business-companies"
                    id="empresy-icon-premium"
                  />
                </div>

                <p className="tag-line sub-title" id="sub-title-landing-2">
                  {t("Explore-Empresy")}
                </p>

                <p className="tag-line sub-title" id="sub-title-landing-1">
                  {t("Find-customers-suppliers-business-opportunities")}
                </p>

                <div className="col text-right">
                  <Link to="/">
                    <button
                      className="explore-button-landing"
                      id="btn-explorar-landing"
                      onClick={() => {
                        window.dataLayer.push({ event: "explore" });
                      }}
                    >
                      {/* <!-<Explore button > --> */}
                      {t("Explore")}
                    </button>
                  </Link>
                </div>
              </div>

              <div className="spacer"></div>

              <div className="row">
                <p className="tag-line sub-title" id="sub-title-landing-2">
                  {t("Empresy-works-to-empower-local-businesses")}
                </p>
              </div>

              <div className="spacer"></div>
            </div>
          </div>
        </div>
        {/* </section> --> */}
      </section>

      <footer className="footer">
        <div className="container1">
          <div className="row">
            <div className="col">
              <div className="logo">
                <img src={empresyFooter} alt="empresy-logo-white" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ul className="footer-links">
                <li>
                  <Link to="/About">
                    <p href="pagina-acerca-de.html" style={{ color: "white" }}>
                      {t("About")}
                    </p>
                  </Link>
                </li>

                <li>
                  <Link to="/contact">
                    <p style={{ cursor: "pointer", color: "white" }}>
                      {t("Contact")}
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/employ">
                    <p style={{ color: "white" }}>{t("Jobs")}</p>
                  </Link>
                </li>
                <li>
                  <Link to="/terms">
                    <p style={{ color: "white" }}>
                      {t("Terms and Conditions")}
                    </p>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>

      <script src="assets/js/bootstrap.min.js"></script>
      <script src="assets/js/jquery-3.6.0.min.js"></script>
      <script src="assets/js/main-script.js"></script>
    </div>
  );
}
