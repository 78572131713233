import { useEffect, useReducer, useState } from "react";
import Header from "../../components/header";
import Post from "../../components/post";
import "./style.css";
import { getSavedPosts } from "../../functions/post";
import { savedPostReducer } from "../../functions/reducers";
import { useParams } from "react-router-dom";
import GridSquare from "../../components/grid/GridSquare";
import Posti from "../../components/posti";
import FadeLoader from "react-spinners/FadeLoader";

export default function SavedPosts({ user, setVisible }) {
  const [showPost, setShowPost] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  useEffect(() => {
    getSavedPost();
  }, []);

  const [{ loading, error, data }, dispatch] = useReducer(savedPostReducer, {
    loading: false,
    data: {},
    error: "",
  });

  const getSavedPost = async () => {
    dispatch({ type: "SAVEDPOSTS_REQUEST" });
    const data = await getSavedPosts(user.token);
    if (data) {
      dispatch({ type: "SAVEDPOSTS_SUCCESS", payload: data });
    } else {
      dispatch({ type: "SAVEDPOSTS_ERROR", payload: data });
    }
  };

  return (
    <div className="">
      <Header user={user} setVisible={setVisible} />
      <div className="SavedPostWrap">
        <div className="grid">
          {data?.length ? (
            data?.map((post) => (
              <GridSquare
                post={post}
                user={user}
                key={post._id}
                setShowPost={setShowPost}
                setSelectedPost={setSelectedPost}
              />
            ))
          ) : loading ? (
            <FadeLoader color="white" size={5} />
          ) : (
            <div className="no_post">No post available</div>
          )}
        </div>
      </div>
      {showPost && selectedPost !== null ? (
        <Posti post={selectedPost} user={user} setShowPost={setShowPost} />
      ) : null}
    </div>
  );
}
