import "./style.css";

export default function CreatePost({ user, setVisibleRequire }) {
  return (
    <div className="createPost">
      <div className="createPost_header">
        <img src={user?.picture} alt="" />
        <div
          className="open_post"
          onClick={() => {
            setVisibleRequire((prev) => !prev);
          }}
        >
          Publica un requerimiento...
        </div>
      </div>
      <div className="create_splitter"></div>
      <div className="createPost_body"></div>
    </div>
  );
}
