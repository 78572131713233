import axios from "axios";
import { Link } from "react-router-dom";

export default function SendEmail({
  userInfos,
  error,
  setError,
  setVisible,
  setUserInfos,
  loading,
  setLoading,
  email,
}) {
  const sendEmail = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/sendResetPasswordCode`,
        { email }
      );
      setError("");
      setVisible(2);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
    }
  };
  return (
    <div className="reset_form dynamic_height">
      <div className="reset_form_header">Recibiras un codigo en tu correo.</div>
      <div className="reset_grid">
        <div className="reset_left">
          <div className="reset_form_text">
            Como quieres recibir el codigo?:
          </div>
          <label htmlFor="email" className="hover1">
            <input type="radio" name="email" checked readOnly />
            <div className="label_col">
              <span>Enviar Codigo via e-mail </span>
              <span>{userInfos.email}</span>
            </div>
          </label>
        </div>
        <div className="reset_right">
          <img src={userInfos.picture} alt="" />
          <span>{userInfos.email}</span>
          <span>Empresy user</span>
        </div>
      </div>
      {error && (
        <div className="error_text" style={{ padding: "10px" }}>
          {error}
        </div>
      )}
      <div className="reset_form_btns">
        <Link to="/login" className="grey_btn">
          No soy
        </Link>
        <button
          onClick={() => {
            sendEmail();
          }}
          className="blue_btn"
        >
          Continuar
        </button>
      </div>
    </div>
  );
}
