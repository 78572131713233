import { useEffect, useRef, useState } from "react";
import { Return, Search } from "../../svg";
import useClickOutside from "../../helpers/clickOutside";
import {
  addToSearchHistory,
  getSearchHistory,
  removeFromSearch,
  search,
} from "../../functions/user";
import { Link } from "react-router-dom";

export default function SearchMenu({ color, setshowSearchMenu, token, t }) {
  const [iconVisible, setIconVisible] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCity, setFilterCity] = useState("");
  const [results, setResults] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [searchHistory, setSearchHistory] = useState([]);
  const menu = useRef(null);
  const input = useRef(null);
  useClickOutside(menu, () => {
    setshowSearchMenu(false);
  });

  const getHistory = async () => {
    const res = await getSearchHistory(token);
    setSearchHistory(res);
  };

  useEffect(() => {
    getHistory();
  }, []);

  // useEffect(() => {
  //   input.current.focus();
  // }, []);
  const searchHandler = async () => {
    if (searchTerm === "") {
      setResults([]);
    } else {
      const res = await search(searchTerm, token);
      if (res.length === 0) {
        setResults([{ empty: true }]); // Set a placeholder item indicating empty results
      } else {
        setResults(res);
      }
    }
  };

  const filterHandler = () => {
    if (filterCity === "") {
      // If the filterCity is empty, show the original results (res from searchHandler)
      searchHandler(); // Call searchHandler to reset results
    } else {
      // Normalize the filterCity value to be accent-insensitive and lowercase
      const normalizedFilterCity = filterCity
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      // Use the filter method to filter results based on the normalized filterCity value
      const filteredResults = results.filter((user) => {
        const normalizedCurrentCity = user?.details?.currentCity
          ? user.details.currentCity
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          : "";

        return normalizedCurrentCity.includes(normalizedFilterCity);
      });

      setResults(filteredResults);
    }
  };

  const addToSearcHistoryHandler = async (searchUser) => {
    const res = await addToSearchHistory(searchUser, token);
    getHistory();
  };

  const handleRemove = async (searchUser) => {
    removeFromSearch(searchUser, token);
    getHistory();
  };

  return (
    <div className="header_left search_area scrollbar" ref={menu}>
      <div className="search_wrap">
        <div className="search_top">
          <div className="header_logo">
            <div
              className="circle hover1"
              onClick={() => setshowSearchMenu(false)}
            >
              <Return color={color} />
            </div>
          </div>
          <div
            className="search2"
            onClick={() => {
              // input.current.focus();
            }}
          >
            {iconVisible && (
              <div>
                <Search color={color} />
              </div>
            )}

            <input
              type="text"
              placeholder={t("search")}
              ref={input}
              // onFocus={() => {
              //   setIconVisible(false);
              // }}
              value={searchTerm}
              onKeyUp={searchHandler}
              onChange={(e) => setSearchTerm(e.target.value)}
              // onBlur={() => {
              //   setIconVisible(true);
              // }}
            />
          </div>
        </div>

        {/* <div className="search2">
          {iconVisible && (
            <div>
              <Search color={color} />
            </div>
          )}
          <input
            type="text"
            placeholder="filtra por ciudad"
            value={filterCity}
            onKeyUp={filterHandler}
            onChange={(e) => {
              setFilterCity(e.target.value);
            }}
            // onBlur={() => {
            //   setIconVisible(true);
            // }}
            // onFocus={() => {
            //   setIconVisible(false);
            // }}
          />
        </div> */}
      </div>
      {results == "" && (
        <div className="search_history_header">
          <span>{t("recent-search")}</span>
          <a href="/">Editar</a>
        </div>
      )}
      <div className="search_history scrollbar">
        {searchHistory &&
          results == "" &&
          searchHistory
            .sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt);
            })
            .map((user) => (
              <div className="search_user_item hover1" key={user._id}>
                <Link
                  className="flex"
                  to={`/profile/${user?.user?.username}`}
                  onClick={() => {
                    addToSearcHistoryHandler(user.user._id);
                    setshowSearchMenu(false);
                  }}
                >
                  <img
                    src={user?.user?.picture}
                    alt="user_empresy_empresa_resultado"
                  />
                </Link>
                <span>{user?.user?.company_Name}</span>
                <i
                  className="exit_icon"
                  onClick={() => handleRemove(user.user._id)}
                ></i>
              </div>
            ))}
      </div>
      <div className="search_results scrollbar">
        {results &&
          results.map((user) =>
            user.empty ? (
              <div className="no_results" style={{ margin: "20px" }}>
                {t("no-serach-result")}
              </div>
            ) : (
              <Link
                to={`/profile/${user.username}`}
                className="search_user_item hover1"
                key={user._id}
                onClick={() => addToSearcHistoryHandler(user._id)}
              >
                <img src={user.picture} alt="user_empresy_empresa_resultado" />
                <span>{user.company_Name}</span>
              </Link>
            )
          )}
      </div>
    </div>
  );
}
