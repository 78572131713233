import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "./styles/icons/icons.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers/index";
import ReactGA from "react-ga4";
import history from "./history";
import TagManager from "react-gtm-module";
const store = createStore(rootReducer, composeWithDevTools());

const tagManagerArgs = {
  gtmId: "GTM-KLKMN29N",
};
TagManager.initialize(tagManagerArgs);
ReactGA.initialize("G-Z76EQZ3XF8");

ReactGA.send({ hitType: "pageview", page: "/login", title: "Custom Title" });

const root = createRoot(document.getElementById("root")); // Replace 'root' with your root element ID

root.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
);
