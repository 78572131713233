import { useState } from "react";
import Header from "../../components/header";
import empresyFooter from "../../styles/icons/empresy-logo-white.svg";
import { Link } from "react-router-dom";
import Politics from "../../components/login/Politics";
import Footer from "../../components/login/Fotter";

export default function Contactt() {
  const [policy, setPolicy] = useState(false);
  return (
    <div>
      <section class="premium-section">
        <div class="container">
          <div class="row">
            <div class="col">
              <h1>Contacto</h1>
              <p>
                Puedes inscribirnos al correo electronico{" "}
                <strong>team@empresy.com</strong>
              </p>
              <button class="btn-primary">¡Únete ahora!</button>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="container1">
          <div className="row">
            <div className="col">
              <Link to="/login">
                <div className="logo">
                  <img
                    src={empresyFooter}
                    alt="empresy-logo-white"
                    style={{ width: "50%" }}
                  />
                </div>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ul className="footer-links">
                <li>
                  <Link to="/About">
                    <a href="pagina-acerca-de.html">Acerca de</a>
                  </Link>
                </li>
                <li>
                  <Link to="/terms">
                    <a style={{ cursor: "pointer" }}>Términos y Condiciones</a>
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    <a style={{ cursor: "pointer" }}>Contacto</a>
                  </Link>
                </li>
                <li>
                  <Link to="/employ">
                    {" "}
                    <a>Empleos</a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
