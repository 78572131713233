import axios from "axios";

export const getMessages = async (values, token) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/messages/getMessages/${values.convo_id}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const sendMessage = async (convo_id, message, files, token) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/messages/sendMessage`,
      { convo_id, message, files },

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteMessage = async (messageId, token) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/deleteMessage/${messageId}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return { status: "ok", data };
  } catch (error) {
    return error.response.data.message;
  }
};
