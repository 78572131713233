import { getOpenConversation } from "../../../functions/conversations";
import { dateHandler } from "../../../helpers/date";
import "./style.css";
import {
  getConversationId,
  getConversationName,
  getConversationPicture,
} from "../../../helpers/chat_receiverid";
import { useDispatch, useSelector } from "react-redux";
import SocketContext from "../../../context/SocketContext";
import { useEffect, useState } from "react";

function Conversation({
  convo,
  user,
  setShowMessages,
  socket,
  online,
  receiverId,
}) {
  const [typing, setTyping] = useState(false);
  const receiver_id = getConversationId(user, convo.users);
  const { activeConversation } = useSelector((state) => state.chat);
  const dispatch = useDispatch();

  useEffect(() => {
    socket.on("typing", (conversation) => {
      setTyping(conversation);
    });
    socket.on("stop typing", () => {
      setTyping(false);
    });
  }, [user]);

  useEffect(() => {
    // Add this line for debugging
    create_conversation_from_outside(receiverId);
  }, [receiverId]);

  const create_conversation_from_outside = async (receiverId) => {
    if (receiverId) {
      dispatch({ type: "CONVERSATIONS_LOADING" });
      const data = await getOpenConversation(receiverId, user.token);

      if (data) {
        dispatch({ type: "GETACTIVECONVERSATION", payload: data });

        socket.emit("join conversation", data._id);
      } else {
        dispatch({ type: "CONVERSATIONS_REJECTED", payload: data.data });
      }
    }
  };

  const openConversation = async () => {
    dispatch({ type: "CONVERSATIONS_LOADING" });
    const data = await getOpenConversation(receiver_id, user.token);
    if (data) {
      dispatch({ type: "GETACTIVECONVERSATION", payload: data });
      socket.emit("join conversation", data._id);
    } else {
      dispatch({ type: "CONVERSATIONS_REJECTED", payload: data.data });
    }
  };

  return (
    <li
      onClick={() => {
        openConversation();
        setShowMessages(true);
      }}
      className={`conversation ${
        convo._id === activeConversation._id ? "" : ""
      }`}
    >
      {/*container*/}
      <div className="conversation_container">
        {/*left*/}
        <div className="left_conversation">
          {/*user picture*/}
          <div className={`picture_container ${online ? "online" : ""}`}>
            <img
              src={getConversationPicture(user, convo.users)}
              alt="profile-empresy"
            />
          </div>
          {/*conversation  name & message*/}
          <div className="convo_container">
            {/*conversation name*/}
            <div className="conversation_name">
              {getConversationName(user, convo.users)}
            </div>
            {/*message*/}
            <div>
              <div className="message_container">
                <div className="message">
                  {typing === convo._id ? (
                    <p>"escribiendo..."</p>
                  ) : (
                    <p>
                      {convo.latestMessage?.message.length > 26
                        ? `${convo.latestMessage?.message.substring(0, 26)}...`
                        : convo.latestMessage?.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*right*/}
        <div className="right_container">
          <span className="right_date">
            {convo.latestMessage?.createdAt
              ? dateHandler(convo.latestMessage?.createdAt)
              : ""}
          </span>
        </div>
      </div>
      {/*Line*/}
      <div className="ml-16 border-b dark:border-b-dark_border_1"></div>
    </li>
  );
}

const ConversationWithContext = (props) => (
  <SocketContext.Consumer>
    {(socket) => <Conversation {...props} socket={socket} />}
  </SocketContext.Consumer>
);
export default ConversationWithContext;
