import { useState } from "react";

import empresyFooter from "../../styles/icons/empresy-logo-white.svg";
import { Link } from "react-router-dom";
import Politics from "../../components/login/Politics";

export default function About({}) {
  const [policy, setPolicy] = useState(false);
  return (
    <div>
      <section class="premium-section">
        <div class="">
          <div class="row">
            <div class="col">
              <h1>Acerca de Empresy</h1>
              <p>
                <strong>¡Bienvenido a Empresy!</strong> La red social de las
                empresas. Conecta de una manera fácil y intuitiva con otras
                empresas, encuentra clientes y proveedores e impulsa tu negocio.
              </p>
              <h2>
                <strong>¿Que es Empresy?</strong>
              </h2>
              <p>
                En empresy creemos que hay muchas empresas con talento que solo
                necesitan mas visibilidad para crecer, y estan quedando
                escondidas detras de contenido viral, perdiendo oportunidades de
                ser visibles y conectar entre ellas.
              </p>
              <p>
                Por eso creamos Empresy, una Red social intuitiva y fácil de
                usar donde las empresas pueden crear su perfil, postear y
                ofrecer sus servicios y productos mientras conectan con clientes
                y proveedores.
              </p>
              <h2>
                <strong>Misión</strong>
              </h2>
              <p>
                Nuestra misión es generar y facilitar conexiones empresariales
                que impulsen el desarrollo económico de nuestra región.
              </p>
              <h2>
                <strong>Visión</strong>
              </h2>
              <p>
                Crear oportunidades de conexiones empresariales en latinoamerica
                y el mundo.
              </p>
              <Link to="/login">
                {" "}
                <button class="btn-primary">¡Únete ahora!</button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <div className="login_wrapper">
        {policy ? <Politics setPolicy={setPolicy} /> : null}
      </div>

      <footer className="footer">
        <div className="container1">
          <div className="row">
            <div className="col">
              <Link to="/login">
                <div className="logo">
                  <img
                    src={empresyFooter}
                    alt="empresy-logo-white"
                    style={{ width: "50%" }}
                  />
                </div>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ul className="footer-links">
                <li>
                  <Link to="/About">
                    <a href="pagina-acerca-de.html">Acerca de</a>
                  </Link>
                </li>
                <li>
                  <Link to="/terms">
                    <a style={{ cursor: "pointer" }}>Términos y Condiciones</a>
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    <a style={{ cursor: "pointer" }}>Contacto</a>
                  </Link>
                </li>
                <li>
                  <Link to="/employ">
                    {" "}
                    <a>Empleos</a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
