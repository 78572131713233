import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";

import { userReducer } from "./userReducer";
import { chatReducer } from "./chatReducer";
import { productsReducer } from "./productsReducer";

const rootReducer = combineReducers({
  user: userReducer,
  chat: chatReducer,
  products: productsReducer, // Add productsReducer to root reducer
});

export default rootReducer;
