import { useSelector } from "react-redux";
import { DotsIcon, SearchLargeIcon } from "../../../svg2";
import { capitalize } from "../../../helpers/string";
import { useMediaQuery } from "react-responsive";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import "./style.css";
import {
  getConversationName,
  getConversationPicture,
} from "../../../helpers/chat_receiverid";

export default function ChatHeader({ setShowMessages, online, user }) {
  const { activeConversation } = useSelector((state) => state.chat);

  const query570px = useMediaQuery({
    query: "(max-width:570px)",
  });

  return (
    <div className="chat_header">
      {/* container*/}
      <div className="w-full flex items-center justify-between">
        <div className="flex items-center gap-x-4">
          {/* conversation_image*/}
          {query570px ? (
            <div onClick={() => setShowMessages((prev) => !prev)}>
              <ChevronLeft fontSize="large" style={{ color: "lightgray" }} />
            </div>
          ) : (
            <div onClick={() => setShowMessages((prev) => !prev)}></div>
          )}
          <button className="btn ">
            <img
              src={getConversationPicture(user, activeConversation.users)}
              alt="empresy_img_chat"
              className="w-full h-full rounded-full object-cover"
            />
          </button>
          {/* conversation name & online status*/}
          <div className="flex flex-col">
            <h1 className="dark:text-white text-base font-bold">
              {getConversationName(user, activeConversation.users)}
            </h1>
            <span className="text-xs dark:text-dark_svg_2">
              {online ? "en linea" : ""}
            </span>
          </div>
        </div>
        {/*right*/}
        {/* <ul className="flex items-center gap-x-2.5">
          <li>
            <button className="btn">
              <SearchLargeIcon className="dark:fill-dark_svg_1" />
            </button>
          </li>
          <li>
            <button className="btn">
              <DotsIcon className="dark:fill-dark_svg_1" />
            </button>
          </li>
        </ul> */}
      </div>
    </div>
  );
}
