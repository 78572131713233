import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useState } from "react";
import SearchAccount from "./SearchAccount";
import SendEmail from "./SendEmail";
import CodeVerification from "./CodeVerification";
import Footer from "../../components/login/Fotter";
import ChangePassword from "./ChangePassword";

export default function Reset() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => ({ ...state }));
  //   traer el usuario del estado usando useSelector
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [visible, setVisible] = useState(0);
  const [password, setPassword] = useState("");
  const [conf_Password, setConf_Password] = useState("");
  const [code, setCode] = useState("");
  const [userInfos, setUserInfos] = useState("");
  const logout = () => {
    Cookies.set("user", "");
    dispatch({
      type: "LOGOUT",
    });
    navigate("/");
  };
  return (
    <div className="reset">
      <div className="reset_header">
        <Link to="/login">
          <img src="../../images/Logo-empresy-web.png" alt="empresy" />
        </Link>
        {user ? (
          <div className="right_reset">
            <Link to="/profile">
              <img src={user.picture} alt="user" />
            </Link>
            <button
              className="blue_btn"
              onClick={() => {
                logout();
              }}
            >
              Logout
            </button>
          </div>
        ) : (
          <Link to="/login" className="right_reset">
            <button className="blue_btn">Login</button>
          </Link>
        )}
      </div>
      <div className="reset_wrap">
        {visible === 0 && (
          <SearchAccount
            email={email}
            setEmail={setEmail}
            error={error}
            setLoading={setLoading}
            setError={setError}
            setUserInfos={setUserInfos}
            setVisible={setVisible}
          />
        )}
        {visible === 1 && userInfos && (
          <SendEmail
            email={email}
            userInfos={userInfos}
            error={error}
            setLoading={setLoading}
            setError={setError}
            setUserInfos={setUserInfos}
            setVisible={setVisible}
          />
        )}
        {visible === 2 && (
          <CodeVerification
            user={user}
            code={code}
            setCode={setCode}
            error={error}
            setLoading={setLoading}
            setError={setError}
            setVisible={setVisible}
            setUserInfos={setUserInfos}
            email={email}
          />
        )}
        {visible === 3 && (
          <ChangePassword
            password={password}
            conf_Password={conf_Password}
            setPassword={setPassword}
            setConf_Password={setConf_Password}
            setLoading={setLoading}
            setError={setError}
            setVisible={setVisible}
            setUserInfos={setUserInfos}
            email={email}
          />
        )}
      </div>
      <Footer />
    </div>
  );
}
