import { useState, useEffect } from "react";
import SocketContext from "../../../context/SocketContext";
import { useSelector } from "react-redux";

function Input({ message, setMessage, textRef, socket }) {
  const { activeConversation } = useSelector((state) => state.chat);

  const [typing, setTyping] = useState(false);

  const resetTyping = () => {
    if (typing) {
      socket.emit("stop typing", activeConversation._id);
      setTyping(false);
    }
  };

  useEffect(() => {
    let timer;

    if (typing) {
      timer = setTimeout(resetTyping, 3000);
    } else {
      resetTyping();
    }

    return () => clearTimeout(timer);
  }, [message, typing, activeConversation._id, socket]);

  const onChangeHandler = (e) => {
    const newMessage = e.target.value;

    if (newMessage.length < message.length) {
      // User erased characters, don't emit "typing"
      setMessage(newMessage);
    } else if (newMessage !== message) {
      // User added characters, set the message and emit "typing"
      setMessage(newMessage);

      if (!typing) {
        setTyping(true);
        socket.emit("typing", activeConversation._id);
      }
    }
  };

  return (
    <div className="input_actions_container">
      <input
        type="text"
        className="input_actions"
        placeholder="Escribe un mensaje..."
        value={message}
        onChange={onChangeHandler}
        ref={textRef}
      />
    </div>
  );
}

const InputWithSocket = (props) => (
  <SocketContext.Consumer>
    {(socket) => <Input {...props} socket={socket} />}
  </SocketContext.Consumer>
);

export default InputWithSocket;
