import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import PublicIcon from "@mui/icons-material/Public";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import ShareIcon from "@mui/icons-material/Share";
import { useEffect, useRef, useState } from "react";
import useClickOutside from "../../helpers/clickOutside";
import { getReacts, reactPost } from "../../functions/post";
import like from "../../styles/icons/empresy-icono-like-1.svg";
import { RWebShare } from "react-web-share";
import PostMenu from "../post/PostMenu";
import {
  ArrowCircleRight,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";

export default function Posti({ post, setShowPost, postss, user }) {
  const [visible, setVisible] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const postmenu = useRef(null);
  const [reacts, setReacts] = useState();
  const [check, setCheck] = useState();
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(1);
  const [checkSaved, setCheckSaved] = useState();
  const [comments, setComments] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [preloadedImages, setPreloadedImages] = useState([]);
  const postR = useRef(null);

  useEffect(() => {
    getPostReacts();
  }, [post]);

  useClickOutside(postmenu, () => {
    setShowMenu(false);
  });

  useClickOutside(postR, () => {
    setShowPost(false);
  });

  const handleNextClickRight = () => {
    if (currentIndex < post.images.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const handleNextClickLeft = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const getPostReacts = async () => {
    const res = await getReacts(post._id, user.token);
    setReacts(res.reacts);
    setCheck(res.check);
    setTotal(res.total);
    setCheckSaved(res.checkSaved);
  };

  return (
    <div className="blurr">
      <div className="postBox" ref={postR}>
        <div className="post_header">
          <ChevronLeft
            onClick={() => setShowPost(false)}
            style={{ cursor: "pointer" }}
            sx={{ fontSize: 40 }}
          />
          {post?.user ? (
            <Link
              to={`/profile/${post.user.username}`}
              className="post_header_left"
            >
              <img src={post.user.picture} alt="empresy profile" />
              <div className="header_col">
                <div className="post_profile_name">
                  {post.user.company_Name}
                  <div className="updated_p">
                    {post.type == "profilePicture" && <span></span>}
                    {post.type == "coverPicture" && <span></span>}
                  </div>
                </div>
                <div className="post_profile_privacy_date">
                  {post.user.Economic_Sector}
                </div>
                <div className="post_profile_privacy_date">
                  <Moment fromNow interval={30}>
                    {post.createdAt}
                  </Moment>
                  . <PublicIcon sx={{ fontSize: 15 }} color="action" />
                </div>
              </div>
            </Link>
          ) : (
            <div></div>
          )}
          <div className="post_header_right hover3">
            <div ref={postmenu}>
              {postss ? (
                <div>
                  <MoreHorizIcon color="action" />
                </div>
              ) : (
                <div
                  onClick={() => {
                    setShowMenu((prev) => !prev);
                  }}
                >
                  <MoreHorizIcon color="action" />
                </div>
              )}
              {showMenu && (
                <PostMenu
                  userId={user.id}
                  postUserId={post.user._id}
                  imagesLength={post?.images?.length}
                  postId={post._id}
                  token={user.token}
                  checkSaved={checkSaved}
                  setCheckSaved={setCheckSaved}
                  images={post.images}
                  postR={postR}
                />
              )}
            </div>
          </div>
        </div>
        {post.type === null ? (
          <>
            <div className="post_text">{post.text}</div>
            <></>
            {post.images && post.images.length && (
              <div
                className="image-slideshow"
                // className={
                //   post.images.length === 1
                //     ? "grid_1"
                //     : post.images.length === 2
                //     ? "grid_2"
                //     : post.images.length === 3
                //     ? "grid_3"
                //     : post.images.length === 4
                //     ? "grid_4"
                //     : post.images.length >= 5 && "grid_5"
                // }
              >
                {post.images.slice(0, 1).map((_image, i) => (
                  <img
                    src={post.images[currentIndex].url}
                    key={i}
                    className="slideshow-image"
                    alt={`Image ${currentIndex + 1}`}
                  />
                ))}
                {post.images.length > 1 ? (
                  <>
                    <button
                      className="buttonRigth circle_icon"
                      onClick={handleNextClickRight}
                      disabled={currentIndex === post.images.length - 1}
                    >
                      <ChevronRight />
                    </button>
                    <button
                      className="buttonLeft circle_icon"
                      onClick={handleNextClickLeft}
                      disabled={currentIndex === 0}
                    >
                      <ChevronLeft />
                    </button>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}
          </>
        ) : post.type === "profilePicture" ? (
          <div className="post_profile_wrap">
            <div className="post_updated_bg">
              <img src={post?.user?.cover} alt="" />
            </div>
            <img
              src={post.images[0].url}
              alt="empresy post"
              className="post_updated_picture"
            />
          </div>
        ) : (
          <div className="post_cover_wrap">
            <img
              className="image_post_cover"
              src={post.images[0].url}
              alt="empresy post images"
            />
          </div>
        )}
      </div>
    </div>
  );
}
