import React, { useState, useRef } from "react";
import "./styles.css";
import CloseIcon from "@mui/icons-material/Close";
import { getOpenConversation } from "../../functions/conversations";
import PulseLoader from "react-spinners/PulseLoader";
import { sendMessage } from "../../functions/messages";

export default function ModalMessage({
  setOpenMessage,
  product,
  user,
  setSendMessage,
  profilee,
  profile,
}) {
  const textAreaRef = useRef(null);
  const [messageText, setMessageText] = useState(""); // Use useState for state management
  const [loading, setLoading] = useState(false);
  const receiver_id = profilee ? profile._id : product?.data?.user?._id;

  const handleChange = (e) => {
    // Update messageText with the text field value
    setMessageText(e?.target?.value);
    // Automatically adjust height of the text field as the user types
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  const handleSendMessage = async () => {
    if (!messageText) return; // Ensure messageText is not empty

    try {
      setLoading(true); // Set loading state to true
      const conversationData = await getOpenConversation(
        receiver_id,
        user.token
      );

      if (conversationData?._id) {
        try {
          const sendMessageResponse = await sendMessage(
            conversationData._id,
            messageText,
            null,
            user.token
          );
          profilee
            ? setSendMessage((prev) => !prev)
            : setOpenMessage((prev) => !prev);
        } catch (sendMessageError) {
          console.error("Error sending message:", sendMessageError);
          // Handle sendMessageError
        }
      }
    } catch (fetchConversationError) {
      console.error("Error fetching conversation:", fetchConversationError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="blurr">
      <div className="message_postBox">
        <div className="marketplace_box_header">
          <div
            className="marketplace_close_icon"
            onClick={() => {
              profilee
                ? setSendMessage((prev) => !prev)
                : setOpenMessage((prev) => !prev);
            }}
          >
            <CloseIcon />
          </div>
          <span>Enviar Mensaje</span>
          <div></div>
        </div>
        <div className="message_container_product">
          <div className="message_info">
            <img
              src={profilee ? profile?.picture : product?.data?.user?.picture}
              alt=""
            />
            <div>
              <span>
                {profilee
                  ? profile?.company_Name
                  : product?.data?.user?.company_Name}
              </span>
            </div>
          </div>
          {product ? (
            <div className="message_info_product">
              <img src={product?.data?.images[0].url} alt="" />
              <div>
                <span>
                  {product?.data?.product_name
                    ? product?.data?.product_name
                    : product?.data?.description.substring(0, 30) + "..."}
                </span>
              </div>
            </div>
          ) : null}

          <div className="message_bottom">
            <textarea
              ref={textAreaRef}
              onChange={handleChange}
              value={messageText} // Bind value to messageText state
              className="message_container_textarea"
              placeholder="Write your message"
              maxLength={100}
            ></textarea>
            <button
              className={!messageText ? "grey_btn" : "blue_btn"}
              onClick={() => handleSendMessage()}
            >
              {loading ? <PulseLoader color="white" /> : "Enviar"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
