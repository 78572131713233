import { useEffect, useRef, useState } from "react";
import Picker from "emoji-picker-react";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import useClickOutside from "../../helpers/clickOutside";

export default function EmojiBackground({ text, setText, user, type2 }) {
  const [picker, setPicker] =
    useState(false); /*state to controll the visibility of the picker*/
  const [cursorPosition, setCursorPosition] = useState();
  const textRef = useRef(null);

  useEffect(() => {
    textRef.current.selectionEnd = cursorPosition;
  }, [cursorPosition]);

  const handleEmoji = ({ e, emoji }) => {
    const ref = textRef.current;
    ref.focus();
    const start = text.substring(0, ref.selectionStart);
    const end = text.substring(ref.selectionStart);
    const newText = start + emoji + end;
    setText(newText);
    setCursorPosition(start.length + emoji.length);
  };
  const popup = useRef(null);
  useClickOutside(popup, () => {
    setPicker((prev) => !prev);
  });
  return (
    <div className={type2 ? "images_input" : ""}>
      <div className={!type2 ? "flex_center" : ""}>
        <textarea
          ref={textRef}
          placeholder={`Crea una publicación ${user?.company_Name}`}
          maxLength="1000"
          value={text}
          className={`post_input ${type2 ? "input2" : ""}`}
          onChange={(e) => setText(e.target.value)}
        ></textarea>
      </div>
      <div className={!type2 ? "post_emoji_wrap" : ""}>
        {picker && (
          <div
            ref={popup}
            className={`comment_emoji_picker ${
              type2 ? "movepicker2" : "rlmove"
            }`}
          >
            <Picker
              lazyLoadEmojis={true}
              height="300px"
              width="300px"
              onEmojiClick={handleEmoji}
              searchDisabled
              emojiStyle="twitter"
              previewConfig={{
                showPreview: false,
              }}
            />
          </div>
        )}

        <i
          className={`${type2 ? "moveleft" : ""}`}
          onClick={() => {
            setPicker((prev) => !prev);
          }}
        >
          <InsertEmoticonIcon
            color="action"
            sx={{ "&:hover": { color: "black" } }}
          />
        </i>
      </div>
    </div>
  );
}
