import React, { useEffect, useMemo, useRef, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import "./style.css";
import Downshift from "downshift";
import PlaceIcon from "@mui/icons-material/Place";
import { grey } from "@mui/material/colors";

const libraries = ["places"]; // Define libraries as a static array

export default function FilterLocation({
  placeholder,
  visitorLocation,
  setLocationValue,
}) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (!isLoaded) return <div>Loading...</div>;
  return (
    <Map
      visitorLocation={visitorLocation}
      placeholder={placeholder}
      setLocationValue={setLocationValue}
    />
  );
}

function Map({ placeholder, visitorLocation, setLocationValue }) {
  const center = useMemo(() => ({ lat: 43.45, lng: -80.49 }), []);
  const [selected, setSelected] = useState(null);

  return (
    <>
      <div className="places-container-visitor">
        <PlacesAutocomplete
          placeholder={placeholder}
          visitorLocation={visitorLocation}
          setLocationValue={setLocationValue}
        />
      </div>
      {/* <GoogleMap
        zoom={10}
        center={center}
        mapContainerClassName="map-container"
      >
        {selected && <Marker position={selected} />}
      </GoogleMap> */}
    </>
  );
}

const PlacesAutocomplete = ({
  placeholder,
  visitorLocation,
  setLocationValue,
}) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  useEffect(() => {
    if (visitorLocation) {
      setValue(visitorLocation || "", false);
    }
  }, []);

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);

    setLocationValue(address);
  };

  return (
    <Downshift
      onChange={(selectedItem) => {
        if (selectedItem) {
          handleSelect(selectedItem.description);
        }
      }}
      itemToString={(item) => (item ? item.description : "")}
    >
      {({
        getInputProps,
        getMenuProps,
        getItemProps,
        isOpen,
        inputValue,
        highlightedIndex,
      }) => (
        <div>
          <input
            {...getInputProps({
              value,
              onChange: (e) => setValue(e.target.value),
              disabled: !ready,
              className: "combobox-input-visitor",
              placeholder,
            })}
          />
          <ul
            {...getMenuProps()}
            style={{
              position: "absolute",
              zIndex: "200",
              background: "white",
            }}
          >
            {isOpen &&
              status === "OK" &&
              data.map((suggestion, index) => (
                <li
                  key={suggestion.place_id}
                  {...getItemProps({
                    item: suggestion,
                    index,
                    style: {
                      backgroundColor:
                        highlightedIndex === index ? "lightgray" : "white",
                    },
                  })}
                >
                  {suggestion.description}
                </li>
              ))}
          </ul>
        </div>
      )}
    </Downshift>
  );
};
