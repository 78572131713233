import axios from "axios";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

export default function useGeoLocation(apiKey) {
  const [locationData, setLocationData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        getLocation(latitude, longitude);
      },
      (err) => {
        console.error(err);
        setError(err.message);
        // Fallback to IP-based geolocation if user denies or if there's an error
        getLocation();
      }
    );
  }, []);

  async function getLocation(lat = null, lon = null) {
    try {
      let res;
      if (lat && lon) {
        res = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
        );
      } else {
        res = await axios.post(
          `https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
        );
        const { lat, lng } = res.data.location;
        res = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
        );
      }
      if (res.status === 200) {
        const result = res.data.results[0];
        const addressComponents = result.address_components.reduce(
          (acc, component) => {
            component.types.forEach((type) => {
              acc[type] = component.long_name;
            });
            return acc;
          },
          {}
        );

        const locationInfo = {
          city: addressComponents.locality,
          country: addressComponents.country,
          countryCode: addressComponents.country,
          lat: result.geometry.location.lat,
          lon: result.geometry.location.lng,
          region: addressComponents.administrative_area_level_1,
          regionCode: addressComponents.administrative_area_level_1,
          timezone: addressComponents.time_zone, // Note: Google Geocode API does not return timezone directly.
          zip: addressComponents.postal_code,
        };

        setLocationData(locationInfo);

        // Set cookies with location data
        Cookies.set("city", locationInfo.city);
        Cookies.set("country", locationInfo.country);
        Cookies.set("countryCode", locationInfo.countryCode);
        Cookies.set("lat", locationInfo.lat);
        Cookies.set("lon", locationInfo.lon);
        Cookies.set("region", locationInfo.region);
        Cookies.set("regionCode", locationInfo.regionCode);
        Cookies.set("zip", locationInfo.zip);
      }
    } catch (err) {
      console.error("Failed to fetch location data from Google API", err);
      setError("Failed to fetch location data");
    }
  }

  return {
    city: locationData?.city,
    country: locationData?.country,
    countryCode: locationData?.countryCode,
    lat: locationData?.lat,
    lon: locationData?.lon,
    region: locationData?.region,
    regionCode: locationData?.regionCode,
    timezone: locationData?.timezone,
    zip: locationData?.zip,
    error,
  };
}
